<template>
  <div>
    <table class="printer-ticket" v-if="infoPedido && infoPedido.length > 0">
      <thead>
        <tr>
          <th class="title" colspan="3">
            <h1 class="title">{{ nome }}</h1>
          </th>
        </tr>
        <tr>
          <th colspan="3">
            {{ infoPedido[0].dia }}/{{ infoPedido[0].mes }}/{{ infoPedido[0].ano }}
          </th>
        </tr>
        <tr>
          <th colspan="3">Cliente : {{ infoPedido[0].nome_cliente }}</th>
        </tr>
        <tr>
          <th class="ttu" colspan="3">
            <b>Cupom não fiscal</b>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(i, id) in dadosPedido"
          :key="'dadosPedido' + id"
          class="top"
          style="border-bottom: 2px dashed black"
        >
          <td style="text-align: left; border-bottom: 1px dotted black">
            <label>
              <label style="font-weight: bold" class="title">
                {{ i.Quantidade }} UN - {{ i.Nome }} Valor UN -
                <label v-if="i.infoProduto[0]">{{ i.infoProduto[0].valor }}</label>
                <label v-else>00,00</label>
              </label>
              <br />
              <b>Opções: </b>
              <div v-if="i.Opcoes && i.Opcoes.length > 0">
                <div v-for="(o, id) in i.Opcoes" :key="'Opcoes' + id">
                  <label>
                    {{ o.nome_opcoes }} R${{
                      parseFloat(o.valor_opcoes).toFixed(2)
                    }};</label
                  ><br />
                </div>
              </div>
              <div v-else><label>Sem opções</label><br /></div>
              <b>Adicionais: </b>
              <div v-if="i.Adicionais && i.Adicionais.length > 0">
                <div v-for="(a, id) in i.Adicionais" :key="'Adicionais' + id">
                  <label>
                    {{ a.nome_adicional }} R${{
                      parseFloat(a.valor_adicional).toFixed(2)
                    }};</label
                  ><br />
                </div>
              </div>
              <div v-else><label>Sem adicionais</label><br /></div>
              <b>Observações: </b>
              <div v-if="i.observacao != null && i.observacao != 'null'">
                <label> {{ i.observacao }}</label
                ><br />
              </div>
              <div v-else><label>Sem observações</label><br /></div>
              <b>Retirar: <br /> </b> Aguardando backend<br />
              <b>Total deste Item R${{ parseFloat(i.Total).toFixed(2) }}</b>
            </label>
          </td>
          <td>
            <a href="#" class="text-primary">{{ i.quantidade }}</a>
          </td>
          <td></td>
        </tr>
      </tbody>

      <tfoot>
        <tr class="sup ttu p--0">
          <td colspan="3">
            <b>Total</b>
          </td>
        </tr>
        <tr class="ttu">
          <td colspan="2">Tipo Pagamento</td>
          <td align="right" style="font-weight: bold">
            <label v-if="infoPedido[0].tipo_pagamento == '0'">Dinheiro</label>
            <label v-if="infoPedido[0].tipo_pagamento == '1'">Cartão</label>
            <label v-if="infoPedido[0].tipo_pagamento == '2'">PIX</label>
          </td>
        </tr>
        <tr class="ttu">
          <td colspan="2">Cupom</td>
          <td align="right" style="font-weight: bold">
            {{ infoPedido[0].nome_cupom || "Sem cupom" }}
          </td>
        </tr>
        <tr class="ttu">
          <td colspan="2">Total</td>
          <td align="right" style="font-weight: bold">R${{ infoPedido[0].total }}</td>
        </tr>
        <tr class="ttu">
          <td colspan="2">Frete</td>
          <td align="right" style="font-weight: bold">R${{ infoPedido[0].frete }}</td>
        </tr>
        <tr class="ttu">
          <td colspan="2">Troco</td>
          <td align="right" style="font-weight: bold">R${{ infoPedido[0].troco }}</td>
        </tr>
        <tr class="sup">
          <td colspan="3" align="center">
            <b>Pedido:#{{ infoPedido[0].id }}</b>
          </td>
        </tr>
        <tr class="sup">
          <td colspan="3" align="center">delivery.edrafox.com</td>
        </tr>
      </tfoot>
    </table>
  </div>
</template>
<script>
export default {
  name: "Cupom",
  components: {},
  data() {
    return {
      nome: localStorage.getItem("nomeComercio"),
    };
  },
  props: {
    infoPedido: Array,
    dadosPedido: Array,
    nomeComercio: String,
  },
  mounted() {
    console.log(this.infoPedido);
    setTimeout(() => {
      console.log(this.infoPedido);
    }, 2000);
  },
};
</script>
<style>
@media print {
  div * {
    visibility: hidden;
  }
  #printable,
  #printable * {
    visibility: visible;
  }
  #printable {
    position: fixed;
    left: 0;
    top: 0;
  }
}
</style>
