<template>
  <div id="app">
    <Spinner v-if="showSpinner" :text="'Carregando'"></Spinner>
    <Message :msg="msg" :res="success" v-if="showModal"> </Message>
    <div v-if="!logado && !isNameStore">
      <ModalStoreName @foundStore="foundStore"></ModalStoreName>
    </div>

    <div v-if="!logado && isNameStore">
      <Login
        :dataC="dataC"
        :storeSelected="storeSelected"
        @forgetPassword="forgetPassword"
        @logar="logar"
        @spinner="spinner"
      ></Login>
    </div>
    <div v-if="logado">
      <Header
        @seeOrders="pedidos_recebidos"
        @changeCollapsed="open_close_sidebar"
        :dataC="dataC"
        :forgotPassword="forgotPassword"
        :sidebarCollapsed="sidebarCollapsed"
        :isMobile="isMobile"
      ></Header>

      <Navbar
        :dataColab="dataColab"
        :dataC="dataC"
        :forgotPassword="forgotPassword"
        :sidebarCollapsed="sidebarCollapsed"
        :isMobile="isMobile"
        @logout="logout"
        @configuracoes="configuracoes"
        @receitas="receitas"
        @blog="blog"
        @clientes="clientes"
        @fidelidade="fidelidade"
        @cadastrar_produtos="cadastrar_produtos"
        @pedidos_aceitos="pedidos_aceitos"
        @pedidos_recebidos="pedidos_recebidos"
        @dashboard="dashboard"
        @aberto_fechado="aberto_fechado"
      ></Navbar>

      <main id="main" :class="!isMobile && !forgotPassword ? 'main margin-reset' : ''">
        <Message :msg="msg" :res="success" v-if="showModal"> </Message>

        <Dash
          :pedidos="pedidos"
          :dados="dataDash"
          :token="dataColab[0].Token.original.access_token"
          v-if="logado && screen == 'dash'"
        ></Dash>
        <Clientes
          :listaClientes="clientesL"
          v-if="logado && screen == 'clientes'"
        ></Clientes>
        <Blog
          v-if="logado && screen == 'blog'"
          :token="dataColab[0].Token.original.access_token"
          :empresa="this.dataC"
        >
        </Blog>
        <Receitas
          v-if="logado && screen == 'receitas'"
          :token="dataColab[0].Token.original.access_token"
          :empresa="this.dataC"
        >
        </Receitas>
        <PedidosRecebidos
          :token="dataColab[0].Token.original.access_token"
          v-if="logado && screen == 'pedidosRecebidos'"
        ></PedidosRecebidos>
        <Pedidos
          :token="dataColab[0].Token.original.access_token"
          :tipoPedido="pedido"
          v-if="logado && screen == 'pedidos'"
        ></Pedidos>
        <Configuracoes
          :info="dataC"
          :token="dataColab[0].Token.original.access_token"
          v-if="logado && screen == 'configuracoes'"
        ></Configuracoes>
        <Fidelidade
          :token="dataColab[0].Token.original.access_token"
          v-if="logado && screen == 'fidelidade'"
        >
        </Fidelidade>
        <Cadastro
          :segmentos="segmentos"
          :id="dataC.id"
          :token="dataColab[0].Token.original.access_token"
          v-if="logado && screen == 'cadastro'"
        ></Cadastro>
        <EsqueceuSenha v-if="screen == 'forgot'" :back="backLogin"></EsqueceuSenha>
      </main>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Header from "./components/Header.vue";
import Login from "./components/Login.vue";
import ModalStoreName from "./components/ModalStoreName.vue";
import Navbar from "./components/Navbar.vue";
import Spinner from "./components/Spinner.vue";
import Blog from "./pages/Blog.vue";
import Cadastro from "./pages/Cadastro.vue";
import Clientes from "./pages/Clientes.vue";
import Configuracoes from "./pages/Configuracoes.vue";
import Dash from "./pages/Dashboard.vue";
import EsqueceuSenha from "./pages/EsqueceuSenha.vue";
import Fidelidade from "./pages/Fidelidade.vue";
import Message from "./pages/Message.vue";
import Pedidos from "./pages/PedidosAceitos.vue";
import PedidosRecebidos from "./pages/PedidosRecebidos.vue";
import Receitas from "./pages/Receitas.vue";
export default {
  name: "App",
  components: {
    Dash,
    Clientes,
    Pedidos,
    PedidosRecebidos,
    Configuracoes,
    Fidelidade,
    Cadastro,
    Message,
    Blog,
    Receitas,
    EsqueceuSenha,
    Login,
    Spinner,
    Header,
    Navbar,
    ModalStoreName,
  },
  props: {},
  data() {
    return {
      sidebarCollapsed: true,
      isMobile: false,
      showSpinner: false,
      dataC: {},
      storeSelected: false,
      dataColab: [],
      dataDash: [],
      pedidos: [],
      aberto: false,
      token: "",
      balcao: false,
      sino: new Audio("assets/audio/sino.mp3"),
      adm: true,
      clientesL: [],
      screen: "",
      segmentos: [],
      pedido: "",
      email: "",
      password: "",
      logado: false,
      forgotPassword: false,
      List_pedidos: 0,
      url: "https://api-delivery.fly.dev/api",
      active: false,
      showModal: false,
      msg: "",
      success: "",
      isNameStore: true,
      nameStore: "",
      BussinesName: "",
    };
  },
  methods: {
    forgetPassword() {
      this.logado = true;
      this.forgotPassword = true;
      this.screen = "forgot";
    },

    backLogin() {
      this.logado = false;
      this.forgotPassword = false;
      this.screen = "";
    },

    handleWindowResize() {
      this.isMobile = window.innerWidth < 1200;
    },
    logar(response) {
      this.dataColab = response;
      console.log(this.dataColab);
      this.logado = true;
      localStorage.setItem("token", this.dataColab[0].Token.original.access_token);
      this.token = this.dataColab[0].Token.original.access_token;
      if (this.dataColab[0].nivel_acesso == 1) {
        this.dashboard();
      }
      this.loop();
    },
    modalMessage(m, r) {
      this.msg = m;
      this.success = r;
      this.showModal = true;
      setTimeout(() => {
        this.showModal = false;
      }, 2000);
    },
    dashboard() {
      if (this.logado) {
        this.showSpinner = true;
        this.open_close_sidebar();
        axios
          .post(`${this.url}/adm/dashInfo/loja/${localStorage.getItem("id")}`, "", {
            headers: {
              Authorization: `Bearer${this.token}`,
            },
          })
          .then((res) => {
            console.log(res.data);
            this.screen = "dash";

            this.dataDash = res.data;
            let hoje = new Date();
            let dia = "";
            let mes = "";
            if (hoje.getDate() < 10) {
              dia = "0" + hoje.getDate();
            } else {
              dia = hoje.getDate();
            }
            if (hoje.getMonth() + 1 < 10) {
              let m = hoje.getMonth() + 1;
              mes = "0" + m;
            } else {
              mes = hoje.getMonth() + 1;
            }
            let data = `${hoje.getFullYear()}-${mes}-${dia}`;
            console.log(data);
            let params = {
              data_inicial: hoje,
              data_final: hoje,
            };
            axios
              .post(`${this.url}/adm/filtrar/datas/loja/${this.dataC.id}`, params, {
                headers: {
                  Authorization: `Bearer${this.token}`,
                },
              })
              .then((res) => {
                this.pedidos = res.data;
              })
              .catch((err) => {
                console.log(err);
              });
            this.screen = "dash";
            this.showSpinner = false;
          })
          .then((err) => {
            this.showSpinner = false;
            console.log(err);
          });
      } else {
        this.showSpinner = false;
        this.logout();
      }
    },

    open_close_sidebar() {
      this.handleWindowResize();

      setTimeout(() => {
        if (this.isMobile) {
          this.sidebarCollapsed = !this.sidebarCollapsed;

          console.log(this.sidebarCollapsed);
          console.log(this.isMobile);
        }
      }, 1000);
    },
    spinner(response) {
      this.showSpinner = response;
    },

    verifyDataStore() {
      console.log(this.dataC.nome);
  let name = this.dataC.nome
      axios
        .post(`https://api-delivery.fly.dev/api/adm/${name.replace(/\s/g, '')}`)
        .then((ret) => {
          this.dataC = ret.data;
          // console.log(this.dataC.aberto_fechado);

          if (ret["data"].aberto_fechado == 1) {
            this.aberto = true;
          } else {
            this.aberto = false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    loop() {
      let i = 0;
      setInterval(() => {
        this.verifyDataStore();

        axios
          .post(
            `https://api-delivery.fly.dev/api/adm/loja/${localStorage.getItem(
              "id"
            )}/pedidos/0`,
            "",
            {
              headers: {
                Authorization: `Bearer${localStorage.getItem("token")}`,
              },
            }
          )
          .then((res) => {
            if (res["data"].status == "O token expirou") {
              this.modalMessage(
                "Para sua segurança recomendamos que faça login novamente",
                false
              );
              this.logout();
            } else {
              localStorage.setItem("pedidos-bell", JSON.stringify(res["data"]));
              if (i < res["data"].length) {
                // console.log(res["data"]);
                const music = new Audio("./assets/audio/sino.mp3");
                music.play();
                i = res["data"].length;
              } else {
                i = res["data"].length;
              }
              if (res["data"].length == 0) {
                document.getElementById("qtPedidos").innerHTML = 0;
              } else {
                if (i < 10) {
                  document.getElementById("qtPedidos").innerHTML = i;
                } else {
                  document.getElementById("qtPedidos").innerHTML = "9+";
                }
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }, 40000);
    },

    contador(n) {
      this.List_pedidos = n;
    },

    changeCollapsed() {
      this.changeCollapsed = !this.changeCollapsed;
    },

    pedidos_recebidos() {
      this.showSpinner = true;
      this.open_close_sidebar();
      /*if (tipo == 1) {
        this.pedido = "Recebido";
      } else if (tipo == 2) {
        this.pedido = "Aceito";
      }*/
      this.screen = "pedidosRecebidos";
      this.showSpinner = false;
    },
    pedidos_aceitos() {
      this.showSpinner = true;
      this.open_close_sidebar();
      /*if (tipo == 1) {
        this.pedido = "Recebido";
      } else if (tipo == 2) {
        this.pedido = "Aceito";
      }*/
      this.screen = "pedidos";
      this.showSpinner = false;
    },
    cadastrar_produtos() {
      this.showSpinner = true;
      this.open_close_sidebar();
      axios
        .post(`${this.url}/adm/segmentos/${this.dataC.id}`, "", {
          headers: {
            Authorization: `Bearer${this.token}`,
          },
        })
        .then((res) => {
          this.screen = "cadastro";
          this.segmentos = res["data"];
          this.showSpinner = false;
        })
        .catch((err) => {
          this.showSpinner = false;
          console.log(err);
        });
    },
    fidelidade() {
      this.open_close_sidebar();
      this.screen = "fidelidade";
    },
    clientes() {
      this.showSpinner = true;
      this.open_close_sidebar();
      axios
        .post(`${this.url}/adm/clientes/${localStorage.getItem("id")}`, "", {
          headers: {
            Authorization: `Bearer${this.token}`,
          },
        })
        .then((res) => {
          this.clientesL = res["data"];
          this.screen = "clientes";
          this.showSpinner = false;
        })
        .catch((err) => {
          this.showSpinner = false;
          console.log(err);
        });
    },

    blog() {
      this.showSpinner = true;
      this.open_close_sidebar();
      this.screen = "blog";
      this.showSpinner = false;
    },

    receitas() {
      this.showSpinner = true;
      this.open_close_sidebar();
      this.screen = "receitas";
      this.showSpinner = false;
    },

    configuracoes() {
      this.showSpinner = true;
      this.open_close_sidebar();
      this.screen = "configuracoes";
      this.showSpinner = false;
    },
    aberto_fechado() {
      this.aberto = !this.aberto;
      axios
        .post(`${this.url}/adm/open/close/${this.dataC.id}`, "", {
          headers: {
            Authorization: `Bearer${this.token}`,
          },
        })
        .then((ret) => {
          if (ret.data == 1) {
            this.aberto = true;
          } else {
            this.aberto = false;
          }
          localStorage.setItem("aberto", this.aberto);
        });
    },
    logout() {
      this.logado = false;
      localStorage.removeItem("token");
      this.token = "";

      setTimeout(() => {
        location.reload();
      }, 4000);
    },

    foundStore(store) {
      this.storeSelected = true;
      this.nameStore = store;
      const url = new URL(window.location.href);
      url.searchParams.set("", store);
      window.history.replaceState({}, "", url.href);
      this.verificarLoja();
    },

    clearStorage() {
      localStorage.removeItem("pedidos-bell");
      localStorage.removeItem("aberto");
      localStorage.removeItem("id");
      localStorage.removeItem("token");
      localStorage.removeItem("nome");
      localStorage.removeItem("nomeComercio");
    },

    verificarLoja() {
      axios
        .post(`${this.url}/adm/${this.nameStore}`)
        .then((ret) => {
          // Verifique se a loja foi encontrada
          if (ret.data) {
            this.dataC = ret.data;
            this.storeSelected = true;
            localStorage.setItem("nome", this.dataC.nome);
            localStorage.setItem("nomeComercio", this.dataC.nome.toLowerCase());
            localStorage.setItem("id", this.dataC.id);
            this.isNameStore = true;
            if (ret.data.aberto_fechado == 1) {
              this.aberto = true;
            } else {
              this.aberto = false;
            }
          } else {
            // Exiba uma mensagem de erro ou realize outra ação caso a loja não seja encontrada
          }
        })
        .catch((err) => {
          this.isNameStore = false;
          const url = new URL(window.location.href);
          url.searchParams.set("", "");
          window.history.replaceState({}, "", url.href);
          console.log(err);
        });
    },
  },
  mounted() {
    this.clearStorage();
    this.showSpinner = true;
    this.handleWindowResize();
    window.addEventListener("resize", this.handleWindowResize);
    const params = new URLSearchParams(window.location.search);
    const nome = params.get("");

    console.log(nome);
    if (nome) {
      this.nameStore = nome;
      this.verificarLoja();
    } else {
      this.isNameStore = false;
    }
    this.showSpinner = false;
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 80px;
  height: calc(100vh - 84px);
  background-color: #ffefe0;
}

header {
  background-color: #e77104;
}

.image-header img {
  width: 63px;
  height: 63px;
  border-radius: 50%;
  margin-left: 11px;
}

.title-header {
  color: #fff;
  font-size: 20px;
  font-family: Poppins;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0.25px;
  margin-left: 17px;
}
</style>
