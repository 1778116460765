<template>
  <div class="custom-modal">
    <transition name="fade" appear>
      <div class="modal-overlay">
        <div class="modal-dialog" role="document">
          <div
            class="modal-content"
            :style="{ 'background-color': res ? '#aedcae' : '#ff6b6b' }"
          >
            <p>{{ msg }}</p>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  name: "Message",
  data() {
    return {};
  },
  props: {
    msg: String,
    res: Boolean,
    active: String,
  },
  methods: {
    hideModal() {
      this.active = "none";
      localStorage.setItem("modal", this.active);
    },
  },
  mounted() {},
};
</script>
<style scoped>
.custom-modal {
  width: 100%;
  height: 100%;
}
.modal-overlay {
  position: fixed;
  right: 30px;
  top: 70px;
  z-index: 9999999;
  width: 60%;
  max-width: 400px;
  height: 100px;
}

.modal-content {
  width: 100%;
  height: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

p {
  margin: 0;
  font-size: 18px;
  color: black;
}
</style>
