<template>
  <div class="password-recovery">
    <Message :msg="msg" :res="success" v-if="showModal"> </Message>
    <div class="container">
      <div id="loader" v-if="showSpinner">
        <div class="loader-content">
          <div class="loader"></div>
          <p>Carregando</p>
        </div>
      </div>
      <h2 class="title">Recuperação de Senha</h2>
      <div class="step">
        <template v-if="step === 1">
          <h3 class="step-title">Insira o número de celular</h3>
          <div>
            <input
              type="text"
              v-model="phoneNumber"
              v-mask="'(##) #####-####'"
              placeholder="Número de celular"
              :class="{ 'is-invalid': !isPhoneNumberValid }"
            />
            <div v-if="!isPhoneNumberValid" class="error-message">
              Número de celular inválido.
            </div>
          </div>
          <div>
            <button class="mb-2 button" @click="createCode">Proximo</button>
            <button class="btn button-outline" @click="confirmExit()">Cancelar</button>
          </div>
        </template>
        <template v-else-if="step === 2">
          <h3 class="step-title">Insira o código de verificação</h3>
          <div>
            <input
              type="text"
              v-model="verificationCode"
              maxlength="6"
              placeholder="Código de verificação"
              :class="{ 'is-invalid': !isVerificationCodeValid }"
            />
            <div v-if="!isVerificationCodeValid" class="error-message">
              Código de verificação inválido.
            </div>
          </div>
          <div>
            <button class="mb-2 button" @click="verifyCode">Proximo</button>
            <button class="btn button-outline" @click="backStep">Voltar</button>
          </div>
        </template>
        <template v-else-if="step === 3">
          <h3 class="step-title">Crie uma nova senha</h3>
          <div>
            <input
              type="password"
              v-model="password"
              minlength="6"
              maxlength="8"
              placeholder="Nova senha"
              :class="{ 'is-invalid': !isPasswordValid }"
            />
            <div v-if="!isPasswordValid" class="error-message">Senha inválida.</div>
          </div>
          <div>
            <input
              type="password"
              v-model="confirmPassword"
              minlength="6"
              maxlength="8"
              placeholder="Confirmar nova senha"
              :class="{ 'is-invalid': !isConfirmPasswordValid }"
            />
            <div v-if="!isConfirmPasswordValid" class="error-message">
              Confirmação de senha inválida.
            </div>
          </div>
          <div>
            <button class="mb-2 button" @click="changePassword">Concluir</button>
            <button class="btn button-outline" @click="backStep">Voltar</button>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Message from "./Message.vue";

export default {
  components: {
    Message,
  },
  props: ["back"],

  data() {
    return {
      url: "https://api-delivery.fly.dev",
      step: 1,
      phoneNumber: "",
      verificationCode: "",
      password: "",
      confirmPassword: "",
      token: "",
      isPhoneNumberValid: true,
      isVerificationCodeValid: true,
      isPasswordValid: true,
      isConfirmPasswordValid: true,
      showSpinner: false,
      showModal: false,
    };
  },
  methods: {
    nextStep() {
      this.step++;
    },
    backStep() {
      if (this.step > 1) {
        this.step--;
      }
    },
    createCode() {
      if (this.phoneNumber.length < 15) {
        this.isPhoneNumberValid = false;
        return;
      } else {
        this.isPhoneNumberValid = true;
        this.showSpinner = true;
        const phoneNumberFormatted = this.phoneNumber.replace(/[^\d]+/g, "");
        const data = {
          phone_number: phoneNumberFormatted,
          // id_empresas: 5,
          id_empresas: localStorage.getItem("id"),
        };
        axios
          .post(`${this.url}/api/password/create/token`, data)
          .then((response) => {
            console.log(response.data);
            this.showSpinner = false;
            if (response.data.success === "Número aleatório gerado com sucesso")
              this.nextStep();
          })
          .catch((err) => {
            this.modalMessage("Houve um erro ao enviar o telefone!", false);
            console.log(err);
            this.showSpinner = false;
          });
      }
    },

    verifyCode() {
      if (this.verificationCode.length < 6) {
        this.isVerificationCodeValid = false;
        return;
      } else {
        this.isVerificationCodeValid = true;
        this.showSpinner = true;
        const phoneNumberFormatted = this.phoneNumber.replace(/[^\d]+/g, "");
        const data = {
          phone_number: phoneNumberFormatted,
          reset_token: this.verificationCode,
        };
        axios
          .post(`${this.url}/api/password/reset/verify`, data)
          .then((response) => {
            console.log(response.data);
            this.token = response.data.token;
            this.nextStep();
            this.showSpinner = false;
          })
          .catch((err) => {
            this.modalMessage("Houve um erro ao enviar o código!", false);
            console.log(err);
            this.showSpinner = false;
          });
      }
    },
    changePassword() {
      if (this.password.length < 6) {
        this.isPasswordValid = false;
        return;
      } else this.isPasswordValid = true;

      if (this.confirmPassword.length < 6 || this.confirmPassword !== this.password) {
        this.isConfirmPasswordValid = false;
        return;
      } else {
        this.showSpinner = true;
        this.isConfirmPasswordValid = true;
        const data = {
          token: this.token,
          password: this.password,
        };
        axios
          .post(`${this.url}/api/password/reset/sms`, data)
          .then((response) => {
            console.log(response.data);
            if (response.data.message === "Senha alterada com sucesso") {
              this.modalMessage("Senha alterada com sucesso!", true);

              setTimeout(() => {
                this.showSpinner = false;
                this.back();
              }, 3000);
            }
          })
          .catch((err) => {
            this.modalMessage("Houve um erro ao alterar a senha!", false);
            console.log(err);
            this.showSpinner = false;
          });
      }
    },
    modalMessage(m, r) {
      this.msg = m;
      this.success = r;
      this.showModal = true;
      setTimeout(() => {
        this.showModal = false;
      }, 5000);
    },
    confirmExit() {
      if (window.confirm("Tem certeza que deseja sair?")) {
        this.back();
      }
    },
  },
};
</script>
<style scoped>
.is-invalid {
  border-color: red;
}

.error-message {
  color: red;
  margin-top: 5px;
}
.password-recovery {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  width: 100%;
  margin: 0;
  padding: 0;
}

.container {
  width: 80%;
  height: 400px;
  max-width: 600px;
  margin: auto;
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.title {
  text-align: center;
  margin-bottom: 20px;
}

.step {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 80%;
}
.step-title {
  font-size: 20px;
  margin-bottom: 10px;
}

input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

button {
  display: block;
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: none;
  background-color: #007bff;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
}

.btn-outline {
  border: 1px solid #007bff;
  color: #007bff;
  background-color: transparent;
  padding: 0.375rem 0.75rem;
  border-radius: 0.25rem;
  transition: background-color 0.3s ease;
}

.btn-outline:hover {
  background-color: #007bff;
  color: #fff;
}

@media (max-width: 480px) {
  .container {
    width: 90%;
  }
}
</style>
