

<template>
  <nav aria-label="Page navigation example">
    <ul class="pagination">
      <!--<li class="page-item">
        <a class="page-link" href="#" aria-label="Previous">
          <span aria-hidden="true">&laquo;</span>
        </a>
      </li>-->
      <li v-for="page in pages" :key="page" :class="{active: source.current_page == page}" ><a class="page-link" href="#" @click="navigate($event, page)">{{page}}</a></li>
      <!--<li class="page-item">
        <a class="page-link" href="#" aria-label="Next">
          <span aria-hidden="true">&raquo;</span>
        </a>
      </li>-->
    </ul>
  </nav>
</template>
<script>
import {range} from 'lodash'
export default {
    props: ["source"],
    data(){
        return {
            pages : []
        }
    },
    methods: {
        navigate(event,page){
            event.preventDefault()
            this.$emit('navigate',page)
        }
    },
    watch:{
        source(){
            this.pages = range(1,this.source.last_page+1)
        }
    }
}
</script>