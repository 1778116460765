<template>
  <div>
    <Spinner v-if="showSpinner" :text="'Carregando'"></Spinner>
    <div class="container">
      <div class="row justify-content-center">
        <div
          class="col-lg-6 col-md-8 col-sm-10 d-flex flex-column align-items-center justify-content-center"
        >
          <div class="card-login mb-3">
            <div class="card-body">
              <div class="pt-4 pb-2">
                <h5 class="card-title text-center pb-0 fs-4">
                  Insira o nome da sua loja!
                </h5>
              </div>

              <div class="row g-3 needs-validation" novalidate>
                <div class="col-12 mt-4">
                  <label
                    style="text-align: left"
                    for="credencial"
                    class="form-label w-100 px-2"
                    >Loja</label
                  >
                  <div class="input-group has-validation">
                    <input
                      type="text"
                      name="store"
                      class="form-control"
                      v-model="store"
                      @input="showErro = false"
                      @keyup.enter="verify"
                      required
                    />
                  </div>
                  <div class="mt-2" style="color: red" v-if="showErro">
                    Não encontramos sua loja.
                  </div>
                  <div
                    class="mt-2"
                    style="color: red"
                    v-if="showErroTried && store.length == 0"
                  >
                    Por favor insira sua loja.
                  </div>
                </div>
                <div class="col-12">
                  <button class="button w-100" v-on:click="verify">Verificar</button>
                </div>
              </div>
            </div>
          </div>

          <div class="credits">
            Criado por
            <a href="https://edrafox.com" style="font-weight: bolder" target="_blank"
              >EDRAFOX</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Spinner from "./Spinner.vue";

export default {
  name: "ModalStoreName",
  components: {
    Spinner,
  },
  props: {},
  data() {
    return {
      store: "",
      showSpinner: false,
      showErro: false,
      showErroTried: false,
      url: "https://api-delivery.fly.dev/api",
    };
  },
  methods: {
    verify() {
      this.showSpinner = true;
      this.showErroTried = true;
      axios
        .post(`${this.url}/adm/${this.store}`)
        .then(() => {
          this.$emit("foundStore", this.store);
          this.showSpinner = false;
        })
        .catch((err) => {
          this.showSpinner = false;
          this.showErro = true;
          console.log(err);
        });
    },
  },
  mounted() {},
};
</script>
<style>
.card-login {
  border-radius: 19px;
  background: #f5f5f5;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
</style>
