<template>
  <div>
    <Message :msg="msg" :res="success" v-if="showModal"> </Message>
    <Spinner v-if="showSpinner" :text="'Alterando'"></Spinner>
    <div>
      <div class="row mb-3">
        <label for="fullName" class="col-md-4 col-lg-3 col-form-label"
          >Nome da Empresa</label
        >
        <div class="col-md-8 col-lg-9">
          <input
            name="fullName"
            type="text"
            class="form-control"
            id="fullName"
            v-model="infoConfig.nome"
          />
        </div>
      </div>
      <div class="row mb-3">
        <label for="about" class="col-md-4 col-lg-3 col-form-label"
          >Sobre sua empresa</label
        >
        <div class="col-md-8 col-lg-9">
          <textarea
            name="about"
            class="form-control"
            v-model="infoConfig.descricao"
            style="height: 100px"
          ></textarea>
        </div>
      </div>

      <div class="row mb-3">
        <label for="Facebook" class="col-md-4 col-lg-3 col-form-label">Facebook</label>
        <div class="col-md-8 col-lg-9">
          <input name="facebook" type="text" class="form-control" v-model="face" />
        </div>
      </div>

      <div class="row mb-3">
        <label for="Instagram" class="col-md-4 col-lg-3 col-form-label">Instagram</label>
        <div class="col-md-8 col-lg-9">
          <input name="instagram" type="text" class="form-control" v-model="insta" />
        </div>
      </div>

      <div class="row mb-3">
        <label for="Linkedin" class="col-md-4 col-lg-3 col-form-label">Whatsapp</label>
        <div class="col-md-8 col-lg-9">
          <input
            type="text"
            class="form-control"
            id="Linmargin:1%;kedin"
            v-mask="'(##) #####-####'"
            v-model="infoConfig.contato"
          />
        </div>
      </div>

      <div class="text-center">
        <button v-on:click="atualiza_Perfil" class="button">Salvar</button>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Message from "../pages/Message.vue";
import Spinner from "./Spinner.vue";

export default {
  name: "ConfigOverview",
  components: {
    Message,
    Spinner,
  },
  props: {},
  data() {
    return {
      url: "https://api-delivery.fly.dev/api",
      infoConfig: {},
      insta: "",
      face: "",
      showSpinner: false,
      showModal: false,
      msg: "",
      success: null,
    };
  },
  methods: {
    overviewData() {
     let name = localStorage.getItem("nomeComercio")
      axios
        .post(`${this.url}/adm/${name.replace(/\s/g, '')}`)
        .then((ret) => {
          localStorage.setItem("id", ret.data.id);
          this.infoConfig = ret.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    socialMedia() {
      axios
        .post(`${this.url}/adm/redes_sociais/${localStorage.getItem("id")}`, "", {
          headers: {
            Authorization: `Bearer${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          this.insta = res["data"][0].instagram;
          this.face = res["data"][0].facebook;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    atualiza_Perfil() {
      this.showSpinner = true;
      axios
        .post(`${this.url}/comercio/${this.infoConfig.id}/atualiza`, this.infoConfig, {
          headers: {
            Authorization: `Bearer${localStorage.getItem("token")}`,
          },
        })
        .then(() => {
          const social = new FormData();
          social.append("instagram", this.insta);
          social.append("facebook", this.face);
          axios
            .post(
              `${this.url}/adm/atualizar/redes_sociais/${this.infoConfig.id}`,
              social,
              {
                headers: {
                  Authorization: `Bearer${localStorage.getItem("token")}`,
                },
              }
            )
            .then((resp) => {
              this.insta = resp.data.instagram;
              this.face = resp.data.facebook;
              this.overviewData();
              localStorage.setItem("nome", this.nomeEmpresa);
            })
            .catch((err) => {
              console.log(err);
              this.modalMessage("Erro ao atualizar!", false);
            });
          this.showSpinner = false;
          this.modalMessage("Alterado com sucesso!", true);
        })
        .catch((err) => {
          this.showSpinner = false;
          this.modalMessage("Erro ao atualizar!", false);
          console.log(err);
        });
    },
    modalMessage(m, r) {
      this.msg = m;
      this.success = r;
      this.showModal = true;
      setTimeout(() => {
        this.showModal = false;
      }, 2000);
    },
  },
  mounted() {
    this.overviewData();
    this.socialMedia();
  },
};
</script>
<style></style>
