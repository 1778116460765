<template>
  <div>
    <Message :msg="msg" :res="success" v-if="showModal"> </Message>

    <section class="section profile">
      <div class="row">
        <div class="col-12">
          <div class="card-config">
            <div class="card-body pt-3">
              <ul class="nav nav-tabs nav-tabs-bordered">
                <li class="nav-item">
                  <button
                    class="nav-link active"
                    data-bs-toggle="tab"
                    data-bs-target="#profile-overview"
                  >
                    Overview
                  </button>
                </li>

                <li class="nav-item">
                  <button
                    class="nav-link"
                    data-bs-toggle="tab"
                    data-bs-target="#profile-edit"
                  >
                    Editar Perfil
                  </button>
                </li>

                <li class="nav-item">
                  <button
                    class="nav-link"
                    data-bs-toggle="tab"
                    data-bs-target="#profile-settings"
                  >
                    Configurações
                  </button>
                </li>
                <li class="nav-item">
                  <button
                    class="nav-link"
                    data-bs-toggle="tab"
                    data-bs-target="#profile-colaboradores"
                  >
                    Colaboradores
                  </button>
                </li>
              </ul>
              <div class="tab-content pt-2">
                <div
                  class="tab-pane fade show active profile-overview"
                  id="profile-overview"
                >
                  <ConfigOverview></ConfigOverview>
                </div>

                <div class="tab-pane fade profile-edit pt-3" id="profile-edit">
                  <ConfigEditProfile></ConfigEditProfile>
                </div>

                <div class="tab-pane fade pt-3" id="profile-settings">
                  <ConfigProfileSettings></ConfigProfileSettings>
                </div>

                <div class="tab-pane fade profile-edit pt-3" id="profile-colaboradores">
                  <ConfigCollaborator></ConfigCollaborator>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import ConfigCollaborator from "../components/ConfigCollaborator.vue";
import ConfigEditProfile from "../components/ConfigEditProfile.vue";
import ConfigOverview from "../components/ConfigOverview.vue";
import ConfigProfileSettings from "../components/ConfigProfileSettings.vue";
import Message from "./Message.vue";
export default {
  name: "ConfiguracoesComercio",
  components: {
    Message,
    ConfigCollaborator,
    ConfigOverview,
    ConfigEditProfile,
    ConfigProfileSettings,
  },
  directives: {
    currency: {
      bind(el, binding, vnode) {
        el.addEventListener("input", function (event) {
          const value = event.target.value;
          if (value) {
            const formattedValue = vnode.context.$options.filters.currency(value);
            event.target.value = formattedValue;
          }
        });
      },
    },
  },
  props: {
    token: String,
  },
  data() {
    return {
      list_colaboradores: [],
      credencial_colaborador: "",
      nivel_colaborador: "",
      nome_colaborador: "",
      senha_colaborador: "",
      // QtMesas: this.infoConfig.quantidade_mesas,
      nomeEmpresa: "",
      sobre: "",
      tipoFrete: "",
      local: "",
      contato: "",
      infoConfig: {},
      insta: "",
      face: "",
      mode_km: 2,
      listaBairros: [],
      deliveryAtendimento: false,
      pix_mode: false,
      dinheiro_mode: false,
      card_mode: false,
      onlinePayment: 0,
      mercadoPago_mode: false,
      time: [],
      url: "https://api-delivery.fly.dev/api",
      valorFreteSaida: 0,
      valorKM: 0,
      maxDistancia: 0,
      valorPorKM: 0,
      bairroNome: "",
      bairroCep: "",
      bairroValor: "",
      showModal: false,
      searchPaymentType: null,
      firstOptionPayment: false,
      secondOptionPayment: false,
      profileImage: null,
      selectedOption: "",
      configVmoney: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
      },
    };
  },
  methods: {
    // modalMessage(m, r) {
    //   this.msg = m;
    //   this.success = r;
    //   this.showModal = true;
    //   setTimeout(() => {
    //     this.showModal = false;
    //   }, 2000);
    // },
    // atualizaInfos() {
    //   axios
    //     .post(`${this.url}/adm/${localStorage.getItem("nomeComercio")}`)
    //     .then((ret) => {
    //       this.dataC = ret.data;
    //       localStorage.setItem("id", this.dataC.id);
    //       this.info = ret.data;
    //       this.infoConfig = ret.data;
    //       this.nomeEmpresa = this.infoConfig.nome;
    //       this.sobre = this.infoConfig.descricao;
    //       this.contato = this.infoConfig.contato;
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },
    // atualiza_Perfil() {
    //   const info = new FormData();
    //   info.append("nomeComercio", this.nomeEmpresa);
    //   info.append("contato", this.contato);
    //   info.append("descricao", this.sobre);
    //   axios
    //     .post(`${this.url}/comercio/${this.infoConfig.id}/atualiza`, info, {
    //       headers: {
    //         Authorization: `Bearer${this.token}`,
    //       },
    //     })
    //     .then((resp) => {
    //       console.log(resp.data);
    //       const social = new FormData();
    //       social.append("instagram", this.insta);
    //       social.append("facebook", this.face);
    //       axios
    //         .post(
    //           `${this.url}/adm/atualizar/redes_sociais/${this.infoConfig.id}`,
    //           social,
    //           {
    //             headers: {
    //               Authorization: `Bearer${this.token}`,
    //             },
    //           }
    //         )
    //         .then((resp) => {
    //           this.insta = resp.data.instagram;
    //           this.face = resp.data.facebook;
    //           this.modalMessage("Atualizado com sucesso!", true);
    //           this.atualizaInfos();
    //           localStorage.setItem("nome", this.nomeEmpresa);
    //         })
    //         .catch((err) => {
    //           console.log(err);
    //         });
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },
    // atualiza_Endereco() {},
  },
  mounted() {
    // this.atualizaInfos();
    // setTimeout(() => {
    //   console.log(this.info);
    // }, 2000);
  },
};
</script>
<style>
.nav-tabs {
  border: none;
}

.card-config {
  border-radius: 19px;
  background: #f5f5f5;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.image-container {
  position: relative;
  cursor: pointer;
}

.image-container img {
  width: 120px;
  height: 120px;
}

.image-container .pen-container {
  display: none;
}

.image-container:hover .pen-container {
  display: flex;
}

.image-container:hover img {
  opacity: 0.5;
}

.image-container:hover .pen-container .pen-icon {
  display: inline-block;
}

.image-container .pen-container {
  top: 0;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  position: absolute;
  background-color: white;
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s ease;
}

.image-container .pen-container .pen-icon {
  display: none;
  font-size: 50px;
  color: #e77104;
}

.image-container:hover .pen-container {
  opacity: 0.5;
}

.filter {
  text-align: left;
  color: var(--grayscale-body, #4e4b66);
  font-size: 13px;
  font-family: Poppins;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.25px;
  padding: 5px;
}
</style>
