<template>
  <div>
    <Spinner v-if="showSpinner" :text="''"></Spinner>
    <Message :msg="msg" :res="success" v-if="showModal"> </Message>
    <div class="card p-2 col-12 card-products" style="text-align: left">
      <div class="p-1" style="display: none">
        <h5>Cadastrar Pizzas</h5>
        <div class="row">
          <button class="button col-lg-3 m-1">
            <i class="bi bi-plus-lg"></i> Tamanho da pizza
          </button>
          <button class="button col-lg-2 m-1">
            <i class="bi bi-plus-lg"></i> Sabores
          </button>
          <button class="button col-lg-2 m-1">
            <i class="bi bi-plus-lg"></i> Pizzas
          </button>
          <button class="button col-lg-2 m-1">
            <i class="bi bi-plus-lg"></i> Bordas
          </button>
          <button class="button col-lg-2 m-1">
            <i class="bi bi-plus-lg"></i> Acréscimos
          </button>
        </div>
      </div>
      <div class="p-1">
        <h4>Cadastrar Produtos</h4>
        <div class="row col-12 m-0">
          <button
            data-bs-toggle="modal"
            data-bs-target="#segmentoModal"
            class="button col-12 col-lg-2 my-2 margin"
            @click="clean_cache"
          >
            <i class="bi bi-plus-lg"></i> Segmento
          </button>
          <button
            data-bs-toggle="modal"
            data-bs-target="#produtoModal"
            class="button col-12 col-lg-2 my-2 margin"
            @click="clean_cache"
          >
            <i class="bi bi-plus-lg"></i> Produto
          </button>
          <button
            data-bs-toggle="modal"
            data-bs-target="#adicionalModal"
            class="button col-12 col-lg-2 my-2 margin"
            @click="clean_cache"
          >
            <i class="bi bi-plus-lg"></i> Adicionais
          </button>
          <button
            data-bs-toggle="modal"
            data-bs-target="#opcaoModal"
            class="button col-12 col-lg-2 my-2 margin"
            @click="clean_cache"
          >
            <i class="bi bi-plus-lg"></i> Opções
          </button>
        </div>
      </div>
      <h4 style="float: left">Segmentos</h4>

      <div class="row" v-if="listSeg.length > 0">
        <SegmentsProducts
          class="mb-3 col-12 col-lg-4 col-md-6 col-sm-12"
          v-for="item in listSeg"
          :key="item.id"
          :segment="item"
          @seeSegment="ver_produtos"
          @editSegment="edit_segment"
          @deleteSegment="delete_segment"
        ></SegmentsProducts>

        <button
          data-bs-toggle="modal"
          data-bs-target="#largeModal"
          type="button"
          class="button col-4 d-none"
          id="openModalSeeSegmentProducts"
        ></button>

        <button
          data-bs-toggle="modal"
          data-bs-target="#segmentoModalEdit"
          type="button"
          class="button col-4 d-none"
          id="openModalEditSegmentProducts"
        ></button>
      </div>
      <div v-else>
        <h5>Ainda não há segmentos cadastrados! 😋</h5>
      </div>
      <!--modal segmentos-->
      <div class="modal fade" id="largeModal" tabindex="-1">
        <div class="modal-dialog modal-lg modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">{{ nome_seg }}</h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body" style="height: 72vh; overflow-y: scroll; width: 100%">
              <div style="overflow-x: auto" v-if="listaP.length > 0">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">Imagem</th>
                      <th scope="col">Nome</th>
                      <th scope="col">Valor</th>
                      <th scope="col">Desativado</th>
                      <th scope="col">Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(i, id) in listaP" :key="id">
                      <th scope="row">
                        <div
                          class="card m-2"
                          :style="{
                            backgroundImage:
                              'url(https://edrafox.com.br/assets/edralivery-img/' +
                              i.imagem_produto +
                              ')',
                          }"
                          style="
                            width: 70px;
                            height: 70px;
                            background-position: center;
                            background-repeat: no-repeat;
                            background-size: cover;
                            background-image: url(https://d2r9epyceweg5n.cloudfront.net/stores/297/496/products/refrigerante-2-litros-1024x102411-51b149dde96f0d74c516341472262340-1024-1024.png);
                          "
                        ></div>
                      </th>
                      <td style="text-align: left">{{ i.nome_produto }}</td>
                      <td>R${{ parseFloat(i.valor).toFixed(2) }}</td>
                      <td align="center">
                        <input
                          @click="desativa_produto(i)"
                          :id="'check' + i.created_at"
                          v-bind:checked="!i.ativo"
                          type="checkbox"
                          class="form-check-input"
                        />
                      </td>
                      <td>
                        <div class="btn-group" role="group">
                          <button
                            type="button"
                            v-on:click="detail_prod(i.id)"
                            data-bs-toggle="modal"
                            data-bs-target="#produtoModaledit"
                            class="btn mx-1"
                            style="background-color: #fa5d04; width: 40px; color: white"
                          >
                            <i class="bi bi-pen"></i>
                          </button>
                          <button
                            type="button"
                            v-on:click="exclui_produto(i.id)"
                            class="btn mx-1"
                            style="background-color: #fa5d04; width: 40px; color: white"
                          >
                            <i style="cursor: pointer" class="bi bi-trash"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div v-else align="center">
                <h4>Não há produtos cadastrados! 🙃</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--modal novo segmento-->
      <div class="modal fade" id="segmentoModal" tabindex="-1">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Adicione um novo segmento</h5>
              <button
                type="button"
                id="closeModalSegments"
                class="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>
            <div class="modal-body" align="center">
              <button class="button-outline m-2" @click="openFileInputSegments">
                Adicionar foto
              </button>
              <input
                style="display: none"
                @change="previewImage"
                accept="image/*"
                type="file"
                ref="addPhotoInputSegments"
              />
              <div
                align="center"
                style="margin-left: 30%"
                class="d-flex align-items-center"
              >
                <img
                  :src="imageData"
                  alt="Profile"
                  style="width: 180px; height: 180px"
                  class="rounded-circle"
                />
              </div>
              <input
                class="form-control mt-3 col-lg-6 col-6"
                v-model="nome_segmento"
                placeholder="Nome do segmento"
              />
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="button-outline"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                Cancelar
              </button>
              <button type="button" class="button" v-on:click="novo_segmento">
                Salvar
              </button>
            </div>
          </div>
        </div>
      </div>
      <!--modal editar segmento-->
      <div class="modal fade" id="segmentoModalEdit" tabindex="-1">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Atualizar segmento</h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="closeModalEditSegments"
              ></button>
            </div>
            <div class="modal-body" align="center">
              <div class="d-flex justify-content-center">
                <label
                  for="btn-up"
                  style="cursor: pointer; width: 150px"
                  class="button mb-3 d-flex align-items-center justify-content-center"
                  >Nova foto</label
                >
              </div>

              <input
                style="display: none"
                @change="previewImage"
                accept="image/*"
                type="file"
                id="btn-up"
              />
              <div
                align="center"
                style="margin-left: 30%"
                class="d-flex align-items-center"
              >
                <img
                  :src="imageData"
                  alt="Profile"
                  style="width: 180px; height: 180px"
                  class="rounded-circle"
                />
              </div>
              <input
                class="form-control mt-3 col-lg-6 col-6"
                v-model="nome_atualiza_segmento"
                placeholder="Novo nome do segmento"
              />
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="button-outline"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                Cancelar
              </button>
              <button type="button" class="button" v-on:click="edit_segment_save()">
                Atualizar
              </button>
            </div>
          </div>
        </div>
      </div>
      <!--modal novo produto-->
      <div class="modal fade" id="produtoModal" tabindex="-1">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Adicione um novo produto</h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="closeModalProduct"
              ></button>
            </div>
            <div
              class="modal-body"
              align="center"
              style="height: 69vh; overflow-y: scroll"
            >
              <button class="button-outline m-2" @click="openFileInputProduct">
                Adicionar foto
              </button>
              <input
                style="display: none"
                type="file"
                id="btn-prod"
                @change="previewImageProdutos"
                accept="image/*"
                ref="addPhotoInputProduct"
              />
              <div
                align="center"
                style="margin-left: 30%"
                class="d-flex align-items-center"
              >
                <img
                  :src="imageDataProdutos"
                  alt="Profile"
                  style="width: 170px; height: 170px"
                  class="rounded-circle"
                />
              </div>
              <div style="text-align: left">
                <label class="mb-2 mt-1">Segmento</label>
                <select
                  v-model="segmento_produto"
                  class="mb-2 form-select col-lg-6 col-6"
                >
                  <option value="" disabled>Selecione um segmento</option>
                  <option v-for="(i, id) in listSeg" :key="id" v-bind:value="i.id">
                    {{ i.nome_segmento }}
                  </option>
                </select>
                <label class="mb-2 mt-1">Nome do produto</label>
                <input
                  v-model="nome_produto"
                  class="form-control col-lg-6 col-6"
                  placeholder="Nome do produto"
                />
                <label class="mb-2 mt-1">Ingredientes</label>
                <div style="margin-left: 0.5%" class="col-lg-12 col-12 row">
                  <input
                    v-model="novo_ingredient"
                    class="form-control"
                    style="width: 50%; margin-right: 1%"
                    placeholder="Ingrediente"
                    v-on:keyup.enter="acidionar_ingredient"
                  />
                  <button
                    type="button"
                    style="width: 20%"
                    class="button"
                    v-on:click="acidionar_ingredient"
                  >
                    Salvar
                  </button>
                </div>
                <label class="mb-2 mt-1">Valor R$</label>
                <input
                  v-on:keyup.enter="novo_produto(0)"
                  v-model="valor_produto"
                  class="form-control col-lg-6 col-6"
                  placeholder="Valor do produto"
                  v-money="configVmoney"
                />
                <label class="mb-2 mt-1">Como é vendido?</label>
                <div style="margin-left: 0.5%" class="col-lg-12 col-12 row">
                  <select
                    v-model="tipo_produto"
                    class="form-select"
                    style="width: 50%; margin-right: 1%"
                    placeholder="Ingrediente"
                  >
                    <option value="0">Unidade</option>
                    <option value="1">KG</option>
                  </select>
                </div>
                <div class="card mt-2" v-if="ingredientes.length > 0">
                  <div class="card-body">
                    <span
                      v-for="(i, id) in ingredientes"
                      :key="id"
                      class="badge rounded-pill m-1 bg-primary"
                      >{{ i
                      }}<i
                        v-on:click="remove_ingredient(i)"
                        style="cursor: pointer"
                        class="m-1 bg-danger rounded-circle bi bi-x"
                        id="btLat"
                      ></i
                    ></span>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button-outline" class="button-outline">Cancelar</button>
              <button type="button" class="button" v-on:click="novo_produto(0)">
                Salvar
              </button>
            </div>
          </div>
        </div>
      </div>
      <!--editar produto-->
      <div class="modal fade" id="produtoModaledit" tabindex="-1">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Produto</h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="closeModalProductEdit"
              ></button>
            </div>
            <div
              class="modal-body"
              align="center"
              style="height: 69vh; overflow-y: scroll"
            >
              <button class="button-outline m-2" @click="openFileInputProduct">
                Adicionar foto
              </button>
              <input
                style="display: none"
                type="file"
                id="btn-prod"
                @change="previewImageProdutos"
                accept="image/*"
                ref="addPhotoInputProduct"
              />
              <div
                align="center"
                style="margin-left: 30%"
                class="d-flex align-items-center"
              >
                <img
                  :src="imageDataProdutos"
                  alt="Profile"
                  style="width: 170px; height: 170px"
                  class="rounded-circle"
                />
              </div>
              <div style="text-align: left">
                <label class="mb-2 mt-1">Segmento</label>
                <select
                  v-model="segmento_produto"
                  class="mb-2 form-select col-lg-6 col-6"
                >
                  <option>Escolha um segmento</option>
                  <option v-for="(i, id) in listSeg" :key="id" v-bind:value="i.id">
                    {{ i.nome_segmento }}
                  </option>
                </select>
                <label class="mb-2 mt-1">Nome do produto</label>
                <input
                  v-model="nome_produto"
                  class="form-control col-lg-6 col-6"
                  placeholder="Nome do produto"
                />
                <label class="mb-2 mt-1">Valor R$</label>
                <input
                  v-model="valor_produto"
                  class="form-control col-lg-6 col-6"
                  placeholder="Valor do produto"
                  v-money="configVmoney"
                />
                <label class="mb-2 mt-1">Como é vendido?</label>
                <div style="margin-left: 0.5%" class="col-lg-12 col-12 row">
                  <select
                    v-model="tipo_produto"
                    class="form-select"
                    style="width: 50%; margin-right: 1%"
                    placeholder="Ingrediente"
                  >
                    <option value="0">Unidade</option>
                    <option value="1">KG</option>
                  </select>
                </div>
                <label class="mb-2 mt-1">Ingredientes</label>
                <div style="margin-left: 0.5%" class="col-lg-12 col-12 row">
                  <input
                    v-model="novo_ingredient"
                    class="form-control"
                    style="width: 50%; margin-right: 1%"
                    placeholder="Ingrediente"
                    v-on:keyup.enter="acidionar_ingredient"
                  />
                  <button
                    type="button"
                    style="width: 20%"
                    class="button"
                    v-on:click="acidionar_ingredient"
                  >
                    Salvar
                  </button>
                </div>
                <div class="card mt-2" v-if="ingredientes.length > 0">
                  <div class="card-body">
                    <span
                      v-for="(i, id) in ingredientes"
                      :key="id"
                      style="background-color: #fa5d04"
                      class="badge rounded-pill m-1 p-2"
                      >{{ i
                      }}<i
                        v-on:click="remove_ingredient(i)"
                        style="cursor: pointer"
                        class="m-1 bg-danger rounded-circle bi bi-x"
                        id="btLat"
                      ></i
                    ></span>
                  </div>
                </div>

                <label class="mt-3">Ingredientes</label>
                <ul class="list-group">
                  <li
                    v-for="(i, id) in prod_Edit['Ingredientes']"
                    :key="id"
                    class="list-group-item d-flex justify-content-between align-items-center"
                  >
                    {{ i.nome_ingrediente }}
                    <span
                      v-on:click="exclui_ingrediente(i.id, i.id_produto)"
                      class="badge rounded-pill"
                      style="background-color: #fa5d04"
                      ><i style="cursor: pointer" class="bi bi-trash"></i
                    ></span>
                  </li>
                </ul>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button-outline"
                data-bs-dismiss="modal"
                aria-label="Close"
                class="btn btn-danger"
              >
                Cancelar
              </button>
              <button type="button" class="button" v-on:click="novo_produto(1)">
                Atualizar
              </button>
            </div>
          </div>
        </div>
      </div>
      <!--modal novo adicional-->
      <div class="modal fade" ref="modal" id="adicionalModal" tabindex="-1">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Adicionais</h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <label class="mb-2 mt-1">Segmento</label>
              <select
                v-model="segmento_adicional"
                class="mb-2 form-select col-lg-6 col-6"
                @change="list_adicionais(segmento_adicional)"
              >
                <option disabled value="">Selecione um segmento</option>
                <option v-for="(i, id) in listSeg" :key="id" v-bind:value="i.id">
                  {{ i.nome_segmento }}
                </option>
              </select>
              <label class="mb-2 mt-1">Nome do adicional</label>
              <input
                class="form-control col-lg-6 col-6"
                placeholder="Ex: Chedda"
                v-model="novo_adicional"
              />
              <label class="mb-2 mt-1">Valor R$</label>
              <input
                v-model="valor_adicional"
                step="0.01"
                class="form-control col-lg-6 col-6"
                v-money="configVmoney"
                placeholder="Valor do produto"
              />
              <div style="height: 20vh; overflow-y: scroll" class="mt-4">
                <ol class="list-group list-group-numbered">
                  <li
                    v-for="(i, id) in lista_adicional"
                    :key="id"
                    class="list-group-item d-flex justify-content-between align-items-start"
                  >
                    {{ i.nome_adicional }} - R${{
                      parseFloat(i.valor_adicional).toFixed(2)
                    }}
                    <span
                      style="cursor: pointer; background-color: #fa5d04"
                      v-on:click="exclui_adicionais(i.id)"
                      class="badge rounded-pill p-2"
                      ><i class="bi bi-trash"></i
                    ></span>
                  </li>
                </ol>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="button" v-on:click="novo_adicional_f">
                Salvar
              </button>
            </div>
          </div>
        </div>
      </div>
      <!--modal nova opcoes-->
      <div class="modal fade" ref="modal" id="opcaoModal" tabindex="-1">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Opções</h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <label class="mb-2 mt-1">Segmento</label>
              <select
                v-model="segmento_opcao"
                class="mb-2 form-select col-lg-6 col-6"
                @change="list_opcoes(segmento_opcao)"
              >
                <option disabled value="">Selecione um segmento</option>
                <option v-for="(i, id) in listSeg" :key="id" v-bind:value="i.id">
                  {{ i.nome_segmento }}
                </option>
              </select>
              <label class="mb-2 mt-1">Nome da opção</label>
              <input
                class="form-control col-lg-6 col-6"
                placeholder="Ex: Chedda"
                v-model="novo_opcao"
              />
              <label class="mb-2 mt-1">Valor R$</label>
              <input
                v-model="valor_opcao"
                step="0.01"
                class="form-control col-lg-6 col-6"
                placeholder="Valor do produto"
                v-money="configVmoney"
              />
              <div style="height: 20vh; overflow-y: scroll" class="mt-4">
                <ol class="list-group list-group-numbered">
                  <li
                    v-for="(i, id) in lista_opcao"
                    :key="id"
                    class="list-group-item d-flex justify-content-between align-items-start"
                  >
                    {{ i.nome_opcao }} R${{ parseFloat(i.valor).toFixed(2) }}
                    <span
                      v-on:click="exclui_opcao(i.id)"
                      class="badge rounded-pill"
                      style="cursor: pointer; background-color: #fa5d04"
                      ><i class="bi bi-trash"></i
                    ></span>
                  </li>
                </ol>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="button" v-on:click="novo_opcao_f">
                Salvar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import SegmentsProducts from "../components/SegmentsProducts.vue";
import Spinner from "../components/Spinner.vue";
import Message from "./Message.vue";
export default {
  name: "CadastroProdutos",
  components: {
    Message,
    SegmentsProducts,
    Spinner,
  },
  props: {
    id: Number,
    token: String,
    segmentos: Array,
  },
  data() {
    return {
      isWindowWidthGreaterThan1200: false,
      listSeg: this.segmentos,
      nome_segmento: "",
      nome_atualiza_segmento: "",
      nome_produto: "",
      id_atualiza: 0,
      valor_produto: "",
      segmento_produto: "",
      novo_ingredient: "",
      novo_adicional: "",
      valor_adicional: "",
      novo_opcao: "",
      valor_opcao: "",
      segmento_adicional: "",
      editingItem: {},
      listaP: [],
      ingredientes: [],
      nome_seg: "",
      tipo_produto: 0,
      imgNewSegment: "",
      seg: "",
      id_edit: "",
      prod_Edit: [],
      segmento_opcao: null,
      showModal: false,
      msg: "",
      success: false,
      lista_opcao: [],
      lista_adicional: [],
      url: "https://api-delivery.fly.dev/api",
      imageData: "https://static.thenounproject.com/png/104062-200.png",
      imageDataProdutos: "https://static.thenounproject.com/png/104062-200.png",
      configVmoney: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
      },
      configVmoneyClear: {
        decimal: ",",
        thousands: ".",
        prefix: "",
      },
      showSpinner: false,
    };
  },
  methods: {
    checkWindowWidth() {
      this.isWindowWidthGreaterThan1200 = window.innerWidth > 1200;
    },
    clean_cache() {
      this.nome_segmento = "";
      this.imageData = "";
      this.imageData = "https://static.thenounproject.com/png/104062-200.png";
      this.imageDataProdutos = "https://static.thenounproject.com/png/104062-200.png";
      this.valor_produto = "";
      this.ingredientes = [];
      this.segmento_produto = "";
      this.novo_ingredient = "";
      this.novo_adicional = "";
      this.valor_adicional = "";
      this.novo_opcao = "";
      this.valor_opcao = "";
      this.segmento_adicional = "";
      this.nome_produto = "";
      this.segmento_opcao = "";
      this.lista_opcao = [];
      this.novo_opcao = "";
      this.valor_opcao = "";
      this.segmento_adicional = "";
      this.lista_adicional = [];
      this.novo_adicional = "";
      this.valor_adicional = "";
    },
    previewImage: function (event) {
      var input = event.target;
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.imageData = e.target.result;
        };
        this.imgNewSegment = input.files[0];
        reader.readAsDataURL(input.files[0]);
      }
    },
    previewImageProdutos: function (event) {
      var input = event.target;
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.imageDataProdutos = e.target.result;
        };
        this.imgNewSegment = input.files[0];
        reader.readAsDataURL(input.files[0]);
      }
    },
    novo_adicional_f() {
      this.showSpinner = true;
      const adicional = new FormData();
      adicional.append("nome_adicional", this.novo_adicional);
      adicional.append("valor_adicional", this.formatMoneyClear(this.valor_adicional));
      adicional.append("id_segmento", this.segmento_adicional);
      axios
        .post(`${this.url}/adm/novo/adicional`, adicional, {
          headers: {
            Authorization: `Bearer${this.token}`,
          },
        })
        .then(() => {
          this.message("Novo adicional inserido!", true);
          this.list_adicionais(this.segmento_adicional);
          this.novo_adicional = "";
          this.valor_adicional = "";
          this.segmento_adicional = "";
          this.showSpinner = false;
        })
        .catch((err) => {
          console.log(err);
          this.showSpinner = false;
        });
    },
    novo_opcao_f() {
      this.showSpinner = true;
      const opcao = new FormData();
      opcao.append("nome_opcao", this.novo_opcao);
      opcao.append("valor", this.formatMoneyClear(this.valor_opcao));
      opcao.append("id_segmento", this.segmento_opcao);
      axios
        .post(`${this.url}/adm/nova/opcao`, opcao, {
          headers: {
            Authorization: `Bearer${this.token}`,
          },
        })
        .then(() => {
          this.showSpinner = false;
          this.list_opcoes(this.segmento_opcao);
          this.message("Nova adicionada com sucesso!", true);
        })
        .catch((err) => {
          console.log(err);
          this.showSpinner = false;
          this.message("Erro ao adicionar nova opção!", false);
        });
    },
    novo_segmento() {
      this.showSpinner = true;
      const formData = new FormData();
      const img = this.imgNewSegment;
      formData.append("imagem", img);
      formData.append("nome_segmento", this.nome_segmento);
      formData.append("id_empresa", this.id);
      axios
        .post(`${this.url}/adm/novo/segmento`, formData, {
          headers: {
            Authorization: `Bearer${this.token}`,
          },
        })
        .then(() => {
          this.message("Novo segmento cadastrado com sucesso!", true);
          this.nome_segmento = "";
          this.imageData = "https://static.thenounproject.com/png/104062-200.png";
          this.imgNewSegment = "";
          this.refresh_prod();
          this.showSpinner = false;
          const closeModalSegments = document.getElementById("closeModalSegments");
          closeModalSegments.click();
        })
        .catch((err) => {
          console.log(err);
          this.message("Erro ao cadastrar segmento!", false);
          this.showSpinner = false;
        });
    },
    formatMoneyClear(valor) {
      const cleanedValue = valor
        .replace(/[^\d,-]/g, "")
        .replace(/,/g, ".")
        .replace(/-/g, "");
      const numero = parseFloat(cleanedValue);
      console.log(numero);
      return numero;
    },

    novo_produto(i) {
      this.showSpinner = true;

      const formData = new FormData();
      const img = this.imgNewSegment;
      const valueProd = this.formatMoneyClear(this.valor_produto);
      console.log(valueProd);
      console.log(this.valor_produto);
      formData.append("nome_produto", this.nome_produto);
      formData.append("imagem_produto", img);
      formData.append("segmento", this.segmento_produto);
      formData.append("descricao", "produto");
      formData.append("ativo", 1);
      formData.append("valor", valueProd);
      formData.append("tipo_produto", this.tipo_produto);

      const axiosConfig = {
        headers: {
          Authorization: `Bearer${this.token}`,
        },
      };

      axios
        .post(
          `${this.url}/adm/${i !== 1 ? "novo" : "atualiza"}/produto${
            i !== 1 ? "" : `/${this.id_edit}`
          }`,
          formData,
          axiosConfig
        )
        .then((res) => {
          this.message(
            `${i !== 1 ? "Novo produto cadastrado" : "Produto atualizado"}  com sucesso!`,
            true
          );

          const ingredientsPromises = [];

          for (const ingredient of this.ingredientes) {
            const ingred = new FormData();
            ingred.append("nome_ingrediente", ingredient);
            ingred.append("id_produto", res.data.id);

            const ingredientPromise = axios.post(
              `${this.url}/adm/novo/ingrediente`,
              ingred,
              axiosConfig
            );
            ingredientsPromises.push(ingredientPromise);
          }

          Promise.all(ingredientsPromises)
            .then(() => {
              if (i == 1) {
                const closeModalProductEdit = document.getElementById(
                  "closeModalProductEdit"
                );
                closeModalProductEdit.click();
              } else {
                const closeModalProduct = document.getElementById("closeModalProduct");
                closeModalProduct.click();
              }

              this.resetFormAndHideSpinner();
            })
            .catch(() => {
              this.message("Erro ao cadastrar ingredientes", false);
              this.resetFormAndHideSpinner();
            });
        })
        .catch((err) => {
          console.log("erro:", err.response.data);
          this.message("Erro ao cadastrar produto", false);
          this.showSpinner = false;
        });
    },

    resetFormAndHideSpinner() {
      this.nome_produto = "";
      this.imgNewSegment = "";
      this.imageDataProdutos = "https://static.thenounproject.com/png/104062-200.png";
      this.valor_produto = "";
      this.tipo_produto = "";
      this.ingredientes = [];
      this.showSpinner = false;
    },

    remove_ingredient(i) {
      this.ingredientes.splice(this.ingredientes.indexOf(i), 1);
    },
    acidionar_ingredient() {
      if (this.novo_ingredient != "") {
        this.ingredientes.push(this.novo_ingredient);
      }
      this.novo_ingredient = "";
    },
    delete_segment(i) {
      this.showSpinner = true;
      axios
        .post(`${this.url}/adm/deletar/segmento/${i}`, "", {
          headers: {
            Authorization: `Bearer${this.token}`,
          },
        })
        .then(() => {
          this.message("Segmento Deletado com sucesso!", true);
          this.refresh_prod();
          this.showSpinner = false;
        })
        .catch((err) => {
          this.message("Erro ao deletar o segmento!", false);
          this.showSpinner = false;
          console.log(err.response.data);
        });
    },
    edit_segment(i) {
      this.showSpinner = true;
      axios
        .post(`${this.url}/adm/segmento/${i}/comercio/${this.id}`, "", {
          headers: {
            Authorization: `Bearer${this.token}`,
          },
        })
        .then((res) => {
          this.editingItem = res["data"][0];
          this.imgNewSegment = ''
          this.imageData = `https://edrafox.com.br/assets/edralivery-img/${res["data"][0].imagem_segmento}`;
          this.nome_atualiza_segmento = res["data"][0].nome_segmento;
          this.id_atualiza = res["data"][0].id;

          const button = document.getElementById("openModalEditSegmentProducts");
          if (button) {
            button.click();
          }
          this.showSpinner = false;
        })
        .catch((err) => {
          console.log(err.response.data);
          this.showSpinner = false;
        });
    },
    edit_segment_save() {
      this.showSpinner = true;
      const formData = new FormData();
      formData.append("nome_segmento", this.nome_atualiza_segmento);
      if (this.imgNewSegment) {
        formData.append("imagem", this.imgNewSegment);
      }
      axios
        .post(`${this.url}/adm/atualiza/segmento/${this.id_atualiza}`, formData, {
          headers: {
            Authorization: `Bearer${this.token}`,
          },
        })
        .then(() => {
          this.message("Segmento atualizado com sucesso!", true);
          this.nome_atualiza_segmento = "";
          this.imgNewSegment = "";
          this.imageData = "https://static.thenounproject.com/png/104062-200.png";
          this.refresh_prod();
          const closeModal = document.getElementById("closeModalEditSegments");
          closeModal.click();
          this.showSpinner = false;
        })
        .catch((err) => {
          console.log("erro:", err.response.data);
          this.nome_atualiza_segmento = "";
          this.imgNewSegment = "";
          this.imageData = "https://static.thenounproject.com/png/104062-200.png";
          this.showSpinner = false;
        });
    },
    ver_produtos(i, n) {
      this.showSpinner = true;
      console.log(i, n);
      this.nome_seg = n;
      this.seg = i;
      axios
        .post(`${this.url}/adm/produtos/${i}`, "", {
          headers: {
            Authorization: `Bearer${this.token}`,
          },
        })
        .then((res) => {
          if (res["data"] == "Não há produtos cadastrados!") {
            this.listaP = [];
          } else {
            this.listaP = res["data"];
          }
          const button = document.getElementById("openModalSeeSegmentProducts");
          if (button) {
            button.click();
          }
          this.showSpinner = false;
        })
        .catch((err) => {
          this.showSpinner = false;

          console.log(err);
        });
    },
    detail_prod(i) {
      this.showSpinner = true;
      axios
        .post(`${this.url}/adm/produto/${i}`, "", {
          headers: {
            Authorization: `Bearer${this.token}`,
          },
        })
        .then((res) => {
          this.novo_ingredient = [];
          this.prod_Edit = res["data"];
          this.id_edit = this.prod_Edit["Produto"].id;
          this.imageDataProdutos = `https://edrafox.com.br/assets/edralivery-img/${this.prod_Edit["Produto"].imagem_produto}`;
          this.nome_produto = this.prod_Edit["Produto"].nome_produto;
          this.segmento_produto = this.prod_Edit["Produto"].segmento;
          this.valor_produto = this.prod_Edit["Produto"].valor;
          this.tipo_produto = this.prod_Edit["Produto"].tipo_produto;
          this.showSpinner = false;
        })
        .catch((err) => {
          this.showSpinner = false;
          console.log(err);
        });
    },
    exclui_ingrediente(i, id) {
      this.showSpinner = true;
      axios
        .post(`${this.url}/adm/deletar/ingrediente/${i}`, "", {
          headers: {
            Authorization: `Bearer${this.token}`,
          },
        })
        .then(() => {
          this.detail_prod(id);
          this.showSpinner = false;
        })
        .catch((err) => {
          console.log(err);
          this.showSpinner = false;
        });
    },
    exclui_opcao(i) {
      this.showSpinner = true;
      axios
        .post(`${this.url}/adm/deletar/opcao/${i}`, "", {
          headers: {
            Authorization: `Bearer${this.token}`,
          },
        })
        .then(() => {
          this.message("Opção apagada com sucesso!", true);
          this.showSpinner = false;
          this.list_opcoes(this.segmento_opcao);
        })
        .catch((err) => {
          console.log(err);
          this.showSpinner = false;
        });
    },
    exclui_adicionais(i) {
      this.showSpinner = true;
      axios
        .post(`${this.url}/adm/deletar/adicional/${i}`, "", {
          headers: {
            Authorization: `Bearer${this.token}`,
          },
        })
        .then(() => {
          this.message("Adicional apagado com sucesso!", true);
          this.showSpinner = false;
          this.list_adicionais(this.segmento_adicional);
        })
        .catch((err) => {
          console.log(err);
          this.message("Erro ao apagar adicional.", false);
          this.showSpinner = false;
        });
    },
    exclui_produto(i) {
      this.showSpinner = true;
      try {
        axios
          .post(`${this.url}/adm/deletar/produto/${i}`, "", {
            headers: {
              Authorization: `Bearer${this.token}`,
            },
          })
          .then(() => {
            this.showSpinner = false;
            this.ver_produtos(this.seg, this.nome_seg);
            this.message("Produto apagado com sucesso.", true);
          })
          .catch((err) => {
            console.log(err);
            this.message("Erro ao apagar produto", false);
            this.showSpinner = false;
          });
      } catch (error) {
        console.log(error);
        this.message("Erro ao apagar produto", false);
        this.showSpinner = false;
      }
    },
    desativa_produto(i) {
      this.showSpinner = true;
      let check = document.getElementById(`check${i.created_at}`).checked;
      let stats = 0;
      if (check == true) {
        stats = 0;
      } else {
        stats = 1;
      }
      const formData = new FormData();
      formData.append("ativo", stats);
      axios
        .post(`${this.url}/adm/desativa/produto/${i.id}`, formData, {
          headers: {
            Authorization: `Bearer${this.token}`,
          },
        })
        .then((res) => {
          if (res.data.ativo == 1) {
            this.message("Produto ativado com sucesso.", true);
          } else {
            this.message("Produto desativado com sucesso.", true);
          }
          this.showSpinner = false;
          this.ver_produtos(this.seg, this.nome_seg);
        })
        .catch((err) => {
          this.showSpinner = false;
          if (stats == 1) {
            this.message("Erro ao desativar o produto", false);
          } else {
            this.message("Erro ao ativar o produto", false);
          }
          console.log(err.response.data);
        });
    },
    refresh_prod() {
      axios
        .post(`${this.url}/adm/segmentos/${this.id}`, "", {
          headers: {
            Authorization: `Bearer${this.token}`,
          },
        })
        .then((res) => {
          this.listSeg = res["data"];
          console.log(this.listSeg);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    list_adicionais(i) {
      this.showSpinner = true;
      axios
        .post(`${this.url}/adm/adicionais/${i}`, "", {
          headers: {
            Authorization: `Bearer${this.token}`,
          },
        })
        .then((res) => {
          this.lista_adicional = res["data"];
          this.showSpinner = false;
        })
        .catch((err) => {
          console.log(err);
          this.showSpinner = false;
        });
    },
    list_opcoes(i) {
      this.showSpinner = true;
      axios
        .post(`${this.url}/adm/opcoes/${i}`, "", {
          headers: {
            Authorization: `Bearer${this.token}`,
          },
        })
        .then((res) => {
          this.lista_opcao = res["data"];
          this.showSpinner = false;
        })
        .catch((err) => {
          console.log(err);
          this.showSpinner = false;
        });
    },

    openFileInputSegments() {
      this.$refs.addPhotoInputSegments.click();
    },
    openFileInputProduct() {
      this.$refs.addPhotoInputProduct.click();
    },
    clearModal() {},
    message(m, r) {
      this.msg = m;
      this.success = r;
      this.showModal = true;
      setTimeout(() => {
        this.showModal = false;
      }, 5000);
    },
  },
  mounted() {
    this.checkWindowWidth();
    window.addEventListener("resize", this.checkWindowWidth);
  },
  destroyed() {
    window.removeEventListener("resize", this.checkWindowWidth);
  },
};
</script>
<style>
.card-products {
  background-color: #ffefe0;
  box-shadow: none;
}
.modal button {
  width: 150px;
}
@media screen and (min-width: 989px) {
  .margin {
    margin: 10px;
  }
}
</style>
