<template>
  <div class="px-2">
    <div class="card">
      <div class="order-header d-flex justify-content-between">
        <div class="order-title">Pedido #{{ pedido.id }}</div>
        <i
          v-if="!showItensDetails"
          v-on:click="showItensDetails = true"
          class="bi bi-eye-fill h5"
          style="cursor: pointer"
        ></i>
        <i
          v-if="showItensDetails"
          v-on:click="showItensDetails = false"
          class="bi bi-eye-slash-fill h5"
          style="cursor: pointer"
        ></i>
      </div>
      <div class="order-infos">
        <div class="">Total: R${{ pedido.total }}</div>
        <div class="">Cliente: {{ pedido.cliente.nome }}</div>
        <div class="">
          Cupom:
          {{
            pedido.nome_cupom
              ? pedido.nome_cupom +
                " de " +
                (pedido.tipo_cupom === "0"
                  ? "R$" + parseFloat(pedido.valor_cupom).toFixed(2)
                  : parseInt(pedido.valor_cupom).toFixed(0) + "%")
              : "N/A"
          }}
        </div>
        <div class="">
          Troco: R$
          {{
            pedido.troco === "0.00" || pedido.troco === "0,00"
              ? pedido.troco
              : (pedido.troco - pedido.total).toFixed(2)
          }}
        </div>
        <div class="">
          Pagamento:
          {{
            pedido.tipo_pagamento == "0"
              ? "Dinheiro"
              : pedido.tipo_pagamento == "1"
              ? "Cartão"
              : pedido.tipo_pagamento == "2"
              ? "PIX"
              : pedido.tipo_pagamento == "3"
              ? "Mercado Pago"
              : ""
          }}
        </div>
        <div>
          <strong>Produtos</strong>
        </div>
        <div v-for="item in pedido.itens" :key="item.id" class="px-2">
          {{ item.quantidade + "x " + item.nome_produto + " R$" + item.total }}
        </div>
      </div>

      <transition name="fade">
        <div class="order-itens" v-if="showItensDetails">
          <div class="order-title">Descrição do pedido</div>

          <div
            v-for="item in pedido.itens"
            :key="item.id + 'fade'"
            class="px-2 order-description"
          >
            <div class="mt-3">
              <strong>Produto</strong>
              {{ item.quantidade + "x " + item.nome_produto + " R$" + item.total }}
            </div>
            <div>
              <strong>Itens</strong> {{ getStringItem(item?.informacoes) || "Sem itens" }}
            </div>
            <div v-if="item.informacoesEvitar">
              <strong>Retirar</strong> {{ item.informacoesEvitar }}
            </div>
            <div v-if="getStringOptions(item.adicional)">
              <strong>Adicionais</strong> {{ getStringOptions(item.adicional) }}
            </div>
          </div>
          <div class="mt-2 px-2 order-description">
            <strong>Observação</strong>
            {{
              pedido.observacao != null && pedido.observacao != "null"
                ? pedido.observacao
                : "Sem observação"
            }}
          </div>
        </div>
      </transition>

      <div class="order-buttons">
        <button class="button-outline mx-1" v-on:click="openModalCancel(pedido.id)">
          CANCELAR
        </button>
        <button class="button mx-1" v-on:click="openModalConfirm(pedido.id)">
          CONFIRMAR
        </button>
      </div>
    </div>
    <ModalPersonalized
      v-if="showModalCancel || showModalConfirm"
      @response="handleModalResponse"
      :buttonTexts="buttonsNameModal"
      :titleModal="titleModal"
      :idModal="idModal"
    ></ModalPersonalized>
  </div>
</template>
<script>
import ModalPersonalized from "./ModalPersonalized.vue";

export default {
  name: "ReceivedOrderCard",
  components: {
    ModalPersonalized,
  },
  props: {
    pedido: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showItensDetails: false,
      showModalCancel: false,
      showModalConfirm: false,
      buttonsNameModal: [],
      titleModal: "",
      idModal: "",
    };
  },
  methods: {
    openModalCancel(id) {
      this.showModalCancel = true;
      this.buttonsNameModal = ["CANCELAR", "CONFIRMAR"];
      this.titleModal = "Deseja cancelar o Pedido #" + id + " ?";
      this.idModal = id;
    },
    openModalConfirm(id) {
      this.showModalConfirm = true;
      this.buttonsNameModal = ["NÃO", "SIM"];
      this.titleModal = "Deseja imprimir o pedido?";
      this.idModal = id;
    },

    handleModalResponse(response) {
      if (this.showModalCancel) {
        this.showModalCancel = false;
        if (response[0]) {
          this.cancelOrder(response[1]);
        } else {
          // fecharModal
        }
      } else if (this.showModalConfirm) {
        this.showModalConfirm = false;
        if (response[0]) {
          this.acceptOrder({ imprimir: true, id: response[1] });
        } else {
          this.acceptOrder({ imprimir: false, id: response[1] });
        }
      }
    },

    getStringOptions(item) {
      let options = "";
      item.forEach((element, index) => {
        if (index == item?.length - 1) {
          options += element.nome_adicional;
        } else {
          options += element.nome_adicional + ", ";
        }
      });
      return options;
    },

    getStringItem(item) {
      return item?.replace(/;/g, ", ");
    },

    cancelOrder(response) {
      this.$emit("cancelOrder", response);
    },
    acceptOrder(response) {
      this.$emit("acceptOrder", response);
    },
  },
  computed: {
    // Propriedades calculadas do componente
  },
};
</script>

<style scoped>
div {
  padding: 0;
  margin: 0;
}
.card {
  padding: 33px 19px;
  background: #ffffff;
  border-radius: 20px;
  width: 100%;
}
.order-header {
}
.order-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.25px;
  color: #14142b;
  display: flex;
  align-items: center;
  background-color: transparent !important;
}
.order-header i {
  font-size: 30px;
  color: #6e7191;
  display: flex;
  align-items: center;
  margin-right: 50px;
}
.order-infos {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 22px;
  letter-spacing: 0.25px;
  color: #14142b;
  text-align: left;
  margin: 20px 0;
}

.order-itens {
  margin-bottom: 20px;
}
.order-itens table {
  width: 100%;
  margin-top: 7px;
}
.order-description {
  text-align: start;
}
.order-buttons {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.25px;
  color: #fcfcfc;
  padding: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
