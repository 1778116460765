<template>
  <div>
    <Spinner v-if="showSpinner" :text="''"></Spinner>
    <Message :msg="msg" :res="success" v-if="showModal"> </Message>

    <div class="card">
      <!-- <div class="d-flex justify-content-start mb-4">
        <button
          data-bs-toggle="modal"
          data-bs-target="#modalClient"
          class="button"
          @click="prepareAddClient()"
        >
          Adicionar cliente
        </button>
      </div> -->
      <div class="filter">
        <div class="col-12 col-lg-3 col-md-4 col-sm-12">
          <label>Buscar Clientes</label>
          <div>
            <input
              @input="findClients($event)"
              class="form-control"
              placeholder="Pesquisar"
            />
          </div>
        </div>
      </div>

      <h1 class="card-title">
        <label>Total de Clientes: {{ newClients.length }}</label>
      </h1>
      <div class="custom-table" v-if="newClients.length > 0">
        <b-table
          id="my-table-client"
          :items="newClients"
          :fields="fields"
          label-sort-asc=""
          label-sort-desc=""
          label-sort-clear=""
          :per-page="perPage"
          :current-page="currentPage"
        >
          <!-- eslint-disable-next-line vue/no-unused-vars -->
          <template #cell(created_at)="row">
            {{ formatDate(row.item.created_at) }}
          </template>
          <template #cell(phone_number)="row">
            {{ formatPhone(row.item.phone_number) }}
          </template>
          <template #cell(edit)="row">
            <i
              class="bi bi-gear"
              data-bs-toggle="modal"
              data-bs-target="#modalClient"
              @click="prepareEditClient(row.item)"
              style="cursor: pointer"
            ></i>
          </template>
        </b-table>

        <div class="custom-pagination">
          <h4>Mostrando 1 até 10 de 134 registros</h4>
          <b-pagination
            v-model="currentPage"
            :total-rows="newClients.length"
            :per-page="perPage"
            aria-controls="my-table"
          ></b-pagination>
        </div>
      </div>
      <div v-else>
        <h5>Ainda não há clientes cadastrados! 👥</h5>
      </div>
    </div>
    <!--Modal promoções-->
    <div class="modal fade" ref="modalDialogScrollable" id="modalClient" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 v-if="!editingItem" class="modal-title">Adicione um cliente</h5>
            <h5 v-if="editingItem" class="modal-title">Edite um cliente</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              id="closeModal"
            ></button>
          </div>
          <div class="modal-body">
            <div
              class="modal-body"
              align="center"
              style="height: 69vh; overflow-y: scroll"
            >
              <div style="text-align: left">
                <label class="mt-2">Nome da cliente</label>
                <input
                  v-model="modalClient.name"
                  class="form-control col-lg-6 col-6"
                  placeholder="João Silva"
                />
                <div
                  v-if="showErrosInput && modalClient.name.length < 3"
                  class="error-message"
                >
                  Campo inválido
                </div>
                <label class="mt-2">Telefone</label>
                <input
                  v-model="modalClient.phone"
                  class="form-control col-lg-6 col-6"
                  placeholder="(31) 99348-8874"
                  v-mask="'(##) #####-####'"
                  ref="phoneInput"
                />
                <div
                  v-if="showErrosInput && modalClient.phone.length < 3"
                  class="error-message"
                >
                  Campo inválido
                </div>
                <label class="mt-2">E-mail</label>
                <input
                  v-model="modalClient.email"
                  class="form-control col-lg-6 col-6"
                  placeholder="email@gmail.com"
                />
                <div
                  v-if="showErrosInput && modalClient.email.length < 3"
                  class="error-message"
                >
                  Campo inválido
                </div>
                <label class="mt-2">Pontos</label>
                <input
                  v-model="modalClient.pontos"
                  class="form-control col-lg-6 col-6"
                  placeholder="12"
                />
                <div v-if="showErrosInput && modalClient.pontos" class="error-message">
                  Campo inválido
                </div>
                <div v-if="!editingItem">
                  <label class="mt-2">Senha</label>
                  <input
                    v-model="modalClient.password"
                    class="form-control"
                    placeholder="030520"
                    maxlength="6"
                  />
                  <div
                    v-if="showErrosInput && modalClient.password.length < 6"
                    class="error-message"
                  >
                    Campo inválido
                  </div>
                  <label class="mt-2">Confirmar senha</label>
                  <input
                    v-model="modalClient.confirmPassword"
                    class="form-control"
                    placeholder="030520"
                    maxlength="6"
                  />
                  <div
                    v-if="showErrosInput && modalClient.confirmPassword.length < 6"
                    class="error-message"
                  >
                    Campo inválido
                  </div>
                  <div
                    v-if="
                      showErrosInput &&
                      modalClient.confirmPassword.length == 6 &&
                      modalClient.confirmPassword !== modalClient.password
                    "
                    class="error-message"
                  >
                    Senhas diferentes
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              v-if="!editingItem"
              type="button"
              v-on:click="createClient()"
              class="button"
            >
              Salvar
            </button>
            <button
              v-if="editingItem"
              type="button"
              v-on:click="editClient()"
              class="button"
            >
              Atualizar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { BPagination, BTable } from "bootstrap-vue";
import Spinner from "../components/Spinner.vue";
import Message from "../pages/Message.vue";

export default {
  name: "Clientes",
  components: {
    BTable,
    BPagination,
    Spinner,
    Message,
  },
  props: {
    listaClientes: Array,
  },
  data() {
    return {
      perPage: 10,
      currentPage: 9,
      newClients: [],
      fields: [
        {
          label: "Nome do Cliente",
          key: "nome",
          sortable: true,
        },
        {
          label: "Data de Início",
          key: "created_at",
          sortable: true,
        },
        {
          label: "Pontos",
          key: "pontos",
          sortable: true,
        },
        {
          label: "Contato",
          key: "phone_number",
          sortable: false,
        },
        {
          label: "E-mail",
          key: "email",
          sortable: true,
        },
        {
          label: "",
          key: "edit",
          sortable: false,
        },
      ],
      modalClient: {
        name: "",
        phone: "",
        password: "",
        confirmPassword: "",
      },
      itemEditing: {},
      editingItem: false,
      showErrosInput: false,
      showModal: false,
      msg: "",
      success: false,
      showSpinner: false,
      url: "https://api-delivery.fly.dev/api",
    };
  },
  methods: {
    formatDate(data) {
      const dataObj = new Date(data);
      const dia = dataObj.getDate().toString().padStart(2, "0");
      const mes = (dataObj.getMonth() + 1).toString().padStart(2, "0");
      const ano = dataObj.getFullYear().toString();
      // const hora = dataObj.getHours().toString().padStart(2, "0");
      // const minuto = dataObj.getMinutes().toString().padStart(2, "0");
      // const segundo = dataObj.getSeconds().toString().padStart(2, "0");
      // return `${dia}/${mes}/${ano} ${hora}:${minuto}:${segundo}`;
      return `${dia}/${mes}/${ano}`;
    },

    formatPhone(numero) {
      const ddd = numero.substring(0, 2);
      const primeiraParte = numero.substring(2, 7);
      const segundaParte = numero.substring(7, 11);
      return `(${ddd}) 9 ${primeiraParte}-${segundaParte}`;
    },

    clearPhoneNumber(phoneNumber) {
      const numericPhoneNumber = phoneNumber.replace(/\D/g, "");
      return numericPhoneNumber;
    },

    findClients(event) {
      const value = event.target.value;
      let newArr = [];
      if (value !== "") {
        this.listaClientes.forEach((element) => {
          if (element.nome.toLowerCase().includes(value.toLowerCase())) {
            newArr.push(element);
          }
        });
        this.newClients = newArr;
      } else {
        this.newClients = this.listaClientes;
      }
    },

    listingClients() {
      this.showSpinner = true;
      console.log("cliensts list");
      axios
        .post(`${this.url}/adm/clientes/${localStorage.getItem("id")}`, "", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          this.newClients = res["data"];
          this.showSpinner = false;
        })
        .catch((err) => {
          this.showSpinner = false;
          console.log(err);
        });
    },

    createClient() {
      console.log(this.modalClient);
      console.log(this.modalClient.name.length < 3);
      console.log(this.modalClient.phone.length < 15);
      console.log(this.modalClient.password.length < 6);
      console.log(this.modalClient.password != this.modalClient.confirmPassword);

      if (
        this.modalClient.name.length < 3 ||
        this.modalClient.phone.length < 15 ||
        this.modalClient.password.length < 6 ||
        this.modalClient.password != this.modalClient.confirmPassword
      ) {
        console.log("erro data");
        this.showErrosInput = true;
        return;
      }
      this.showErrosInput = false;
      this.showSpinner = true;
      const data = {
        name: this.modalClient.name,
        telefone: this.clearPhoneNumber(this.modalClient.phone),
        senha: this.modalClient.password,
        pontos: "0",
        id_empresa: localStorage.getItem("id"),
      };

      axios
        .post(`${this.url}/app/cadastrar/cliente`, data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          console.log(response.data);
          this.showSpinner = false;
          if (response.data == "Usuario já cadastrado.") {
            this.modalMessage("Usuário já cadastrado.!", false);
          } else {
            this.modalMessage("Cadastrado com sucesso!", true);
            this.listingClients();
            const close = document.getElementById("closeModal");
            close.click();
          }
        })
        .catch((err) => {
          this.showSpinner = false;
          this.modalMessage("Houve um erro ao cadastrar!", false);
          console.log(err);
        });
    },
    editClient() {
      if (this.modalClient.name.length < 3 || this.modalClient.phone.length < 15) {
        console.log("erro data");
        this.showErrosInput = true;
        return;
      }
      this.showErrosInput = false;
      this.showSpinner = true;
      const data = {
        nome: this.modalClient.name,
        telefone: this.clearPhoneNumber(this.modalClient.phone),
        email: this.modalClient.email,
        pontos: this.modalClient.pontos,
        id_empresa: localStorage.getItem("id"),
      };

      axios
        .post(`${this.url}/app/atualiza/cliente/${this.itemEditing.id}`, data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          console.log(response.data);
          this.showSpinner = false;
          this.listingClients();
          if (response.data == "Usuario já cadastrado.") {
            this.modalMessage("Usuário já cadastrado.!", false);
          } else {
            this.modalMessage("Atualizado com sucesso!", true);
            const close = document.getElementById("closeModal");
            close.click();
          }
        })
        .catch((err) => {
          this.showSpinner = false;
          this.modalMessage("Houve um erro ao atualizar!", false);
          console.log(err);
        });
    },

    prepareAddClient() {
      this.editingItem = false;
      this.modalClient = {
        name: "",
        phone: "",
        password: "",
        confirmPassword: "",
      };
    },

    prepareEditClient(item) {
      this.editingItem = true;
      this.itemEditing = item;
      this.modalClient = {
        name: item.nome,
        phone: item.phone_number,
        email: item.email,
        pontos: item.pontos,
        password: "",
      };

      if (this.$refs.phoneInput) {
        this.modalClient.phone = "";
        this.$nextTick(() => {
          this.modalClient.phone = item.phone_number;
        });
      }
      console.log(item);
      console.log(this.modalClient);
    },

    modalMessage(m, r) {
      this.msg = m;
      this.success = r;
      this.showModal = true;
      setTimeout(() => {
        this.showModal = false;
      }, 2000);
    },
  },
  mounted() {
    this.listingClients();
  },
  // this.newClients = this.listaClientes;
};
</script>
<style scoped>
.card {
  background-color: #ffefe0;
  box-shadow: none;
}

.filter {
  text-align: left;
  color: var(--grayscale-body, #4e4b66);
  font-size: 13px;
  font-family: Poppins;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.25px;
  padding: 5px;
}
.button {
  width: auto;
  padding: 0 20px;
}
.buttonsToAdd {
}
.searchItens {
  /* margin-top: 30px; */
  padding: 20px 0;
}
.searchItens h5 {
  color: var(--grayscale-body, #4e4b66);
  font-size: 13px;
  font-family: Poppins;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.25px;
}

.custom-table {
  background-color: white;
  border-radius: 9px;
  border: none;
  overflow-x: auto;
  width: 100%;
}

#my-table-client {
  border-radius: 9px;
  width: 100%;
}
.custom-table .thead th {
  white-space: nowrap;
}

.custom-table .thead th {
  white-space: nowrap;
}

thead tr {
  border-radius: 9px 9px 0px 0px;
  background: #e5e5ea;
}
.table > :not(:first-child) {
  border: none;
}

th {
  cursor: pointer;
}

td {
  vertical-align: middle;
  max-width: 100%;
  overflow: visible !important;
  text-overflow: normal;
  white-space: nowrap;
}

.bi-gear-fill {
  font-size: 20px;
  color: #0041ac;
  cursor: pointer;
}

.custom-pagination {
  padding: 0 20px;
  display: flex;
  align-items: center;
}

.custom-pagination .page-item,
.custom-pagination .page-link,
.page-item.disabled .page-link {
  color: var(--grayscale-label, #6e7191);
  border-color: white;
  margin: 0 5px;
}

.page-item.active .page-link {
  color: white;
  background-color: #d9dbe9;
  border-color: #d9dbe9;
}

.custom-pagination h4 {
  color: var(--grayscale-label, #6e7191);
  font-size: 13px;
  font-family: Poppins;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.25px;
}

li .page-item {
  margin: 0 20px;
}

.card-title {
  color: #000;
  text-align: center;
  font-size: 18px;
  font-family: Poppins;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.25px;
  margin-bottom: 0;
}
.error-message {
  color: red;
}
</style>
