<template>
  <div>
    <div id="loader">
      <div class="loader-content">
        <div class="loader"></div>
        <p>{{ text }}</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Spinner",
  components: {},
  props: {
    typeSpinner: {
      type: String,
      required: false,
    },
    text: {
      type: String,
      required: false,
    },
  },
  data() {
    return {};
  },
  methods: {},
  mounted() {},
};
</script>
