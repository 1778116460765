<template>
  <div>
    <Spinner v-if="showSpinner" :text="''"></Spinner>
    <div class="dashboard_screen">
      <div class="row">
        <div class="col col-12 col-lg-8 col-md-12 col-sm-12">
          <div class="row">
            <div class="card-money-details col col-12 col-lg-6 col-md-12 col-sm-12">
              <div class="card-style">
                <h4>Faturamento<span> | mês atual</span></h4>
                <div class="card-money-icon">
                  <div class="card-icon-blue">
                    <i class="bi bi-calendar"></i>
                  </div>
                  <div class="card-money">
                    <h6>R${{ dados["Faturamento Mês"] }}</h6>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-money-details col col-12 col-lg-6 col-md-12 col-sm-12">
              <div class="card-style">
                <h4>Faturamento<span> | Anual</span></h4>
                <div class="card-money-icon">
                  <div class="card-icon-green">
                    <i class="bi bi-currency-dollar"></i>
                  </div>
                  <div class="card-money">
                    <h6>R${{ dados["Faturamento Anual"] }}</h6>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-money-details col col-12 col-lg-6 col-md-12 col-sm-12">
              <div class="card-style">
                <h4>Vendas realizadas<span> | Hoje</span></h4>
                <div class="card-money-icon">
                  <div class="card-icon-green">
                    <i class="bi bi-cart-check-fill"></i>
                  </div>
                  <div class="card-money">
                    <h6>{{ dados["Quantidade Pedidos Aceitos no Dia"] }}</h6>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-money-details col col-12 col-lg-6 col-md-12 col-sm-12">
              <div class="card-style">
                <h4>Pedidos Rejeitados<span> | Hoje</span></h4>
                <div class="card-money-icon">
                  <div class="card-icon-green">
                    <i class="bi bi-cart-x-fill" style="color: red"></i>
                  </div>
                  <div class="card-money">
                    <h6>{{ dados["Quantidade Pedidos Rejeitados no Dia"] }}</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="col col-12 col-lg-4 col-md-12 col-sm-12 card-product-seller custom-col-product-seller"
        >
          <div class="row">
            <div
              class="col col-12 card-style justify-content-between"
              :style="{
                'background-image':
                  'url(' +
                  'https://edrafox.com.br/assets/edralivery-img/' +
                  dados['Produto mais vendido'].imagem_produto +
                  ')',
              }"
            >
              <div>
                <h3 class="order-title">Produto mais vendido!</h3>
              </div>
              <div>
                <h3 class="order-title">
                  {{ dados["Produto mais vendido"].nome_produto }}
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col col-12 col-lg-8 col-md-12 col-sm-12">
          <div class="row">
            <div class="card-money-details">
              <div class="card-style">
                <h4>Relatório de vendas</h4>
                <div class="col-12">
                  <div class="recent-sales">
                    <div class="filter d-flex justify-content-end">
                      <button class="filter-button mb-2" data-bs-toggle="dropdown">
                        Filtros <i class="bi bi-filter"></i>
                      </button>
                      <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                        <li class="dropdown-header text-start">
                          <h6>Filtros</h6>
                        </li>
                        <li>
                          <a
                            class="dropdown-item"
                            style="cursor: pointer"
                            @click="
                              hoje(new Date(), 1);
                              filter_in_dates = false;
                            "
                            >Hoje</a
                          >
                        </li>
                        <li>
                          <a
                            class="dropdown-item"
                            style="cursor: pointer"
                            @click="
                              hoje(new Date(), 2);
                              filter_in_dates = false;
                            "
                            >Mês</a
                          >
                        </li>
                        <li>
                          <a
                            class="dropdown-item"
                            style="cursor: pointer"
                            @click="filter_in_dates = true"
                            >Datas</a
                          >
                        </li>
                      </ul>
                    </div>

                    <div class="">
                      <div
                        v-if="filter_in_dates"
                        class="row mb-3 col-lg-12 col-12 d-flex justify-content-center align-items-center"
                        align="center"
                      >
                        <input
                          type="date"
                          v-model="dataIn"
                          style="width: 40%"
                          class="form-control col-6 px-2 mx-2"
                        />
                        á
                        <input
                          type="date"
                          @change="hoje(new Date(), 3)"
                          v-model="dataFim"
                          style="width: 40%"
                          class="form-control col-6 px-2 mx-2"
                        />
                      </div>
                      <div class="row my-3" align="center">
                        <div class="col col-12 col-lg-6 col-md-12 col-sm-12 mb-2">
                          <input
                            v-model="search"
                            placeholder="Pesquise"
                            class="form-control px-2"
                            type="text"
                          />
                        </div>
                        <div class="col col-12 col-lg-6 col-md-12 col-sm-12 mb-2">
                          <select v-model="selected" class="form-select px-2">
                            <option value="">Origem da venda</option>
                            <option value="Delivery">Delivery</option>
                            <option value="Balcao">Balcão</option>
                          </select>
                        </div>
                      </div>
                      <table
                        v-if="relatorio_lista.length > 0"
                        class="table table-borderless datatable col-lg-12 col-12"
                      >
                        <thead>
                          <tr>
                            <th scope="col">#</th>
                            <th scope="col">Cliente</th>
                            <th scope="col">Atendimento</th>
                            <th scope="col">Preço</th>
                            <th scope="col">Ações</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(i, id) in itensFilter" :key="'filtered' + id">
                            <th scope="row">
                              <a>#{{ i.id }}</a>
                            </th>
                            <td>{{ i.nome_cliente }}</td>
                            <td>
                              <a class="text-primary">{{ i.origem }}</a>
                            </td>
                            <td>R${{ i.total }}</td>
                            <td>
                              <div
                                class="btn-group"
                                role="group"
                                aria-label="Basic example"
                              >
                                <button
                                  type="button"
                                  v-on:click="filterPedido(i.id, i)"
                                  data-bs-toggle="modal"
                                  data-bs-target="#modalDialogScrollable"
                                  class="btn btn-primary"
                                >
                                  <i class="bi bi-eye"></i>
                                </button>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div v-else style="height: 120px">
                        <h5>Não há pedidos nesta data!☹️</h5>
                      </div>
                      <div class="text-center">
                        <Vpagination
                          :source="pagination"
                          @navigate="filtrar_relatorios"
                        ></Vpagination>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col col-12 col-lg-4 col-md-12 col-sm-12 card-product-seller">
          <div class="row">
            <div class="col col-12 card-style justify-content-around">
              <div>
                <h4>Meios de pagamentos mais usados</h4>
              </div>
              <div v-if="dados_pg">
                <apexchart
                  id="pagamentosCharts"
                  type="pie"
                  :options="chartOption"
                  :series="seriesChart"
                ></apexchart>
              </div>
              <div v-else>
                <h3>Aguardando a primeira venda!</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      ref="modalDialogScrollable"
      id="modalDialogScrollable"
      tabindex="-1"
    >
      <div class="modal-dialog modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Informações</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div>
              <div class="card-body">
                <h5 class="card-title">
                  Total R${{ parseFloat(dataToShow.total).toFixed(2) }}<br />
                  <label v-if="cupomUsado != null"
                    >Cupom usado : {{ cupomUsado || "N/A" }}</label
                  >
                </h5>
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">Item / Informaçoes</th>
                      <th scope="col">Quantidade</th>
                      <th scope="col">Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(i, id) in pedidos_info"
                      :key="'pedidos_info' + id"
                      style="width: 100%"
                    >
                      <td style="text-align: left">
                        <label style="display: block">
                          <h6 style="font-weight: bolder; color: blue; font-size: 17px">
                            {{ i.Nome }}
                          </h6>
                          <b>Opções: </b><br />
                          <div
                            v-if="i.Opcoes.length > 0"
                            style="overflow-wrap: break-word; white-space: break-spaces"
                          >
                            <div v-for="(o, id) in i.Opcoes" :key="'Opcoes' + id">
                              <span
                                style="
                                  overflow-wrap: break-word;
                                  white-space: break-spaces;
                                "
                              >
                                {{ o.nome_opcoes }} R${{
                                  parseFloat(o.valor_opcoes).toFixed(2)
                                }}
                              </span>
                              <br />
                            </div>
                          </div>
                          <div v-else>Sem opções</div>
                          <b>Adicionais: </b>
                          <div
                            v-if="i.Adicionais.length > 0"
                            style="overflow-wrap: break-word; white-space: break-spaces"
                          >
                            <div v-for="(a, id) in i.Adicionais" :key="'Adicionais' + id">
                              <span
                                style="
                                  overflow-wrap: break-word;
                                  white-space: break-spaces;
                                "
                                >{{ a.nome_adicional }} R${{ a.valor_adicional }};</span
                              ><br />
                            </div>
                          </div>
                          <div v-else>Sem adicionais</div>
                          <b>Evitar: </b>
                          <div
                            v-if="
                              i.informacoesEvitar && i.informacoesEvitar.trim() !== ''
                            "
                            style="overflow-wrap: break-word; white-space: break-spaces"
                          >
                            {{ i.informacoesEvitar }}
                          </div>
                          <div v-else>Sem informações a evitar</div>
                        </label>
                      </td>

                      <td>
                        <a class="text-primary">{{ i.Quantidade }} </a>
                      </td>
                      <td>R${{ parseFloat(i.Total).toFixed(2) }}</td>
                    </tr>

                    <tr>
                      <td style="text-align: left">
                        <label>
                          <b>Frete: </b> R${{ parseFloat(dataToShow.frete).toFixed(2)
                          }}<br />
                          <label
                            ><b>Cupom: </b> R${{
                              parseFloat(dataToShow.valor_cupom) > 0
                                ? parseFloat(dataToShow.valor_cupom).toFixed(2)
                                : "0,00"
                            }}</label
                          >
                          <br />
                        </label>
                          <br />
                        <span style="word-wrap: break-word; white-space: pre-wrap">
                          <b>Observação: </b> {{ dataToShow.observacao }}<br />
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button @click="printerPedido()" type="button" class="button">
              Imprimir <i class="bi bi-printer"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div id="cupom" style="display: none">
      <Cupom
        :infoPedido="[pedidos_info_t]"
        :dadosPedido="pedidos_tt"
        :nomeComercio="'nomeComercio'"
      ></Cupom>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Spinner from "../components/Spinner.vue";
import Cupom from "./Cupom.vue";
import Vpagination from "./Pagination.vue";
export default {
  name: "Dash",

  components: {
    Cupom,
    Vpagination,
    Spinner,
  },
  data() {
    return {
      dataToShow: {},
      pedidos_info: null,
      cupomUsado: null,
      version: "0.2.9",
      dados_pg: false,
      modalShow: false,
      showSpinner: false,
      filter_in_dates: false,
      options: {
        chart: {
          toolbar: {
            show: false,
          },
          id: "vuechart-example",
        },
        markers: {
          size: 4,
        },
        colors: ["#4154f1", "red"],
        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.3,
            opacityTo: 0.4,
            stops: [0, 90, 100],
          },
        },
        stroke: {
          curve: "smooth",
          width: 2,
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          type: "datetime",
          categories: [
            "2018-09-19T00:00:00.000Z",
            "2018-09-19T01:30:00.000Z",
            "2018-09-19T02:30:00.000Z",
            "2018-09-19T03:30:00.000Z",
            "2018-09-19T04:30:00.000Z",
            "2018-09-19T05:30:00.000Z",
            "2018-09-19T06:30:00.000Z",
          ],
        },
        tooltip: {
          x: {
            format: "dd/MM/yy HH:mm",
          },
        },
      },
      series: [
        {
          name: "Aceitos",
          data: [31, 40, 28, 51, 42, 82, 56],
        },
        {
          name: "Recusados",
          data: [11, 32, 45, 32, 34, 52, 41],
        },
      ],
      tooltipPG: {
        trigger: "item",
      },
      seriesChart: [
        this.dados["Meios de pagamento"][0].PIX,
        this.dados["Meios de pagamento"][0].Dinheiro,
        this.dados["Meios de pagamento"][0].Cartão,
      ],
      chartOption: {
        chart: {
          type: "pie",
        },
        tooltip: {
          trigger: "item",
        },
        labels: ["PIX", "Dinheiro", "Cartão"],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
      url: "https://api-delivery.fly.dev/api",
      relatorio_lista: [],
      dataIn: "",
      dataFim: "",
      totalPedido: 0,
      dataPedido: [],
      pedidos_tt: [],
      pedidos_info_t: [],
      pedido_filter: 0,
      pagination: {},
      search: "",
      selected: "",
    };
  },
  props: {
    dados: {},
    pedidos: {},
    token: String,
  },
  computed: {
    itensFilter() {
      let valores = [];
      //return valores
      valores = this.relatorio_lista.filter((item) => {
        if (item.nome_cliente != undefined) {
          return (
            item.nome_cliente.toLowerCase().indexOf(this.search.toLowerCase()) > -1 //|| item.id.indexOf(this.search) > -1
          );
        }
      });
      valores = valores.filter((item) => {
        if (this.selected == "") {
          return item;
        } else {
          return item.origem == this.selected;
        }
      });
      return valores;
      // return this.relatorio_lista
    },
  },
  methods: {
    filtrar_relatorios(page) {
      const params = new FormData();
      params.append("data_inicial", this.dataIn);
      params.append("data_final", this.dataFim);
      axios
        .post(
          `${this.url}/adm/filtrar/datas/loja/${localStorage.getItem("id")}?page=${page}`,
          params,
          {
            headers: {
              Authorization: `Bearer${this.token}`,
            },
          }
        )
        .then((res) => {
          this.relatorio_lista = res.data.data;
          this.pagination = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    hoje(i, t) {
      let now = i;
      let day = 0;
      let month = 0;
      let nextDay = 0;
      if (now.getMonth() < 10) {
        day = `0${now.getDate()}`;
      } else {
        day = now.getDate();
      }
      if (now.getMonth() < 10) {
        month = `0${now.getMonth() + 1}`;
      } else {
        month = now.getMonth() + 1;
      }
      let next = parseInt(day) + 1;
      if (next < 10) {
        nextDay = `0${next}`;
      } else {
        nextDay = next;
      }

      if (t == 1) {
        this.dataIn = `${now.getFullYear()}-${month}-${day}`;
        this.dataFim = `${now.getFullYear()}-${month}-${nextDay}`;
        this.filtrar_relatorios(1);
      }
      if (t == 2) {
        this.dataIn = `${now.getFullYear()}-${now.getMonth() + 1}-01`;
        this.dataFim = `${now.getFullYear()}-${now.getMonth() + 1}-31`;
        this.filtrar_relatorios(1);
      }
      if (t == 3) {
        this.filtrar_relatorios();
      }
    },
    filterPedido(i, data) {
      this.showSpinner = true;
      this.dataToShow = data;
      this.pedido_filter = i;

      axios
        .post(`${this.url}/adm/pedido/${i}`, "", {
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        })
        .then((ret) => {
          console.log(ret.data);
          this.cupomUsado = ret.data.tipo_cupom;
          this.dataPedido = ret.data;
          this.pedidos_info_t = ret.data;

          return axios.post(`${this.url}/adm/relatorio/pedido/${i}`, "", {
            headers: {
              Authorization: `Bearer ${this.token}`,
            },
          });
        })
        .then((res) => {
          if (res.data.status === "O token expirou") {
            alert("Para sua segurança, a sessão foi expirada. Faça login novamente!");
          } else {
            console.log(res.data);
            this.pedidos_info = res.data;
            this.pedidos_tt = res.data;
            let t = 0;
            for (const total of this.pedidos_info) {
              t += parseFloat(total.Total);
              this.totalPedido = t;
            }
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.showSpinner = false;
        });
    },
    transformObservation(item) {
      return item.replace(/;/g, ", ");
    },
    printerPedido() {
      this.showSpinner = true;
      axios
        .post(`${this.url}/adm/pedido/${this.pedido_filter}`, "", {
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        })
        .then((ret) => {
          this.pedidos_info_t = ret.data;

          return axios.post(
            `${this.url}/adm/relatorio/pedido/${this.pedido_filter}`,
            "",
            {
              headers: {
                Authorization: `Bearer ${this.token}`,
              },
            }
          );
        })
        .then((res) => {
          if (res.data.length > 0) {
            this.pedidos_tt = res.data;
          }
          setTimeout(() => {
            console.log([this.pedidos_tt, this.pedidos_info_t]);
            this.imprimir_notinha();
          }, 500);
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.showSpinner = false;
        });
    },
    imprimir_notinha() {
      var conteudo = document.getElementById("cupom").innerHTML;
      try {
        var iframe = document.createElement("iframe");
        document.body.appendChild(iframe);
        iframe.contentDocument.open();
        iframe.contentDocument.write(conteudo);
        iframe.contentDocument.close();
        iframe.contentWindow.print();
        document.body.removeChild(iframe);
      } catch (e) {
        console.error("Erro ao imprimir:", e);
      }
    },
  },

  mounted() {
    if (
      this.dados["Meios de pagamento"][0].PIX > 0 ||
      this.dados["Meios de pagamento"][0].Dinheiro > 0 ||
      this.dados["Meios de pagamento"][0].Cartão > 0
    ) {
      this.dados_pg = true;
    } else {
      this.dados_pg = false;
    }
    this.hoje(new Date(), 1);
  },
};
</script>
<style>
.dashboard_screen {
}

.card-style {
  border-radius: 14px;
  background: #fafafa;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 20px;
}

.card-money-details,
.card-product-seller {
  padding: 10px;
  margin: 0;
}
.card-money-details h4,
.card-product-seller h4 {
  font-weight: 500;
  color: black;
  font-size: calc(1.275rem + 0.1vw);
  margin: 0;
  margin-bottom: 10px;
}
.card-money-details span,
.card-product-seller span {
  font-weight: 300;
  color: gray;
  font-size: 18px;
  margin: 0;
}

.card-product-seller .row {
  height: 100%;
}

.card-product-seller .card-style {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  font-weight: 500;
  color: black;
  display: flex;
  flex-direction: column;
}
.card-money-icon {
  display: flex;
  align-items: center;
}
.card-icon-blue {
  font-size: 30px;
  padding: 5px 12px;
  border-radius: 50%;
  color: #4154f1;
  background: #dadaf7;
}
.card-icon-green {
  font-size: 30px;
  padding: 5px 12px;
  border-radius: 50%;
  color: #2eca6a;
  background: #e0f8e9;
}
.card-money h6 {
  font-size: 30px;
  font-weight: 600;
  color: #e77104;
  margin: 0;
  margin-left: 20px;
}

.filter-button {
  gap: 12px;
  height: 40px;
  background: none;
  border-radius: 8px;
  color: #fa5d04;
  border: 2px solid #fa5d04;
  box-shadow: none;
}

.order-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 28px;
  display: inline-block;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.25px;
  color: #fcfcff;
  background: #303030;
  border-radius: 7px;
  margin: 0;
  padding: 5px 10px;
  text-align: center;
}

@media (max-width: 991px) {
  .custom-col-product-seller {
    height: 350px;
  }
}
</style>
