import { render, staticRenderFns } from "./SegmentsProducts.vue?vue&type=template&id=35c35281&scoped=true"
import script from "./SegmentsProducts.vue?vue&type=script&lang=js"
export * from "./SegmentsProducts.vue?vue&type=script&lang=js"
import style0 from "./SegmentsProducts.vue?vue&type=style&index=0&id=35c35281&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35c35281",
  null
  
)

export default component.exports