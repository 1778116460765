<template>
  <div>
    <div class="row">
      <div class="col-12 col-lg-6 col-md-6 col-sm-12">
        <div>
          <div class="">
            <div
              class="card-body profile-card pt-4 d-flex flex-column align-items-center"
            >
              <div class="image-container" style="position: relative; cursor: pointer">
                <img
                  v-if="!profileImage && infoConfig.logo"
                  style="width: 120px; height: 120px"
                  v-bind:src="
                    'https://edrafox.com.br/assets/edralivery-img/' + infoConfig.logo
                  "
                  alt="Profile"
                  class="rounded-circle"
                />
                <img
                  v-if="profileImage"
                  style="width: 120px; height: 120px"
                  v-bind:src="profileImage"
                  alt="Profile"
                  class="rounded-circle"
                />
                <input
                  style="display: none"
                  @change="previewImage($event)"
                  accept="image/*"
                  type="file"
                  id="btn-profile-image"
                />

                <div class="pen-container" for="btn-profile-image" @click="openGallery()">
                  <i
                    class="bi bi-pen-fill pen-icon"
                    style="
                      font-size: 50px;

                      color: #e77104;
                    "
                  ></i>
                </div>
              </div>

              <h2>{{ infoConfig.nome }}</h2>
              <div class="social-links mt-2">
                <a v-if="face" :href="`https://facebook.com/${face}`" class="facebook"
                  ><i class="bi bi-facebook"></i
                ></a>
                <a v-if="insta" :href="`https://instagram.com/${insta}`" class="instagram"
                  ><i class="bi bi-instagram"></i
                ></a>
                <a
                  v-if="infoConfig.contato"
                  :href="`https://api.whatsapp.com/send?phone=55${infoConfig.contato}&text=`"
                  class="linkedin"
                  ><i class="bi bi-whatsapp"></i
                ></a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-lg-6 col-md-6 col-sm-12">
        <h5 class="card-title">Sobre</h5>
        <p class="small fst-italic" v-if="infoConfig.descricao != null">
          {{ infoConfig.descricao }}
        </p>
        <p class="small fst-italic" v-else>Adicione informações sobre seu comércio!😆</p>

        <h5 class="card-title">Detalhes</h5>

        <div class="d-flex justify-content-center">
          <div class="label">Nome:</div>
          <div class="">{{ infoConfig.nome }}</div>
        </div>

        <div class="d-flex justify-content-center">
          <div class="label">Telefone:</div>
          <div class="">{{ infoConfig.contato }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "ConfigOverview",
  components: {},
  props: {},
  data() {
    return {
      url: "https://api-delivery.fly.dev/api",
      infoConfig: {},
      profileImage: null,
      insta: "",
      face: "",
    };
  },
  methods: {
    overviewData() {
     let name = localStorage.getItem("nomeComercio")
      axios
        .post(`${this.url}/adm/${name.replace(/\s/g, '')}`)
        .then((ret) => {
          localStorage.setItem("id", ret.data.id);
          this.infoConfig = ret.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    socialMedia() {
      axios
        .post(`${this.url}/adm/redes_sociais/${localStorage.getItem("id")}`, "", {
          headers: {
            Authorization: `Bearer${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          this.insta = res["data"][0].instagram;
          this.face = res["data"][0].facebook;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    openGallery() {
      const input = document.getElementById("btn-profile-image");
      input.click();
    },

    async previewImage(event) {
      var input = event.target;
      console.log(input.files[0]);
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = (e) => {
          // console.log(e.target.result);
          this.profileImage = e.target.result;

          const info = new FormData();
          info.append("logo", this.profileImage);
          axios
            .post(`${this.url}/comercio/${this.infoConfig.id}/atualiza`, info, {
              headers: {
                Authorization: `Bearer${this.token}`,
              },
            })
            .then((res) => {
              console.log(res.data);
              this.modalMessage("Atualizado com sucesso", true);
            })
            .catch((err) => {
              this.profileImage = null;
              console.log(err);
            });
        };
        reader.readAsDataURL(input.files[0]);
      }
    },
  },
  mounted() {
    this.overviewData();
    this.socialMedia();
  },
};
</script>
<style></style>
