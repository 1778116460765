var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.forgotPassword)?_c('aside',{staticClass:"sidebar collapse show",class:{ 'd-none': _vm.isMobile && _vm.sidebarCollapsed },attrs:{"id":"sidebar"}},[_c('button',{staticClass:"openStore",attrs:{"disabled":_vm.buttonDisabled},on:{"click":_vm.aberto_fechado}},[(_vm.isLoading)?_c('span',[_c('i',{staticClass:"spinner-border custom-spinner",attrs:{"role":"status"}})]):_c('span',[_vm._v(" "+_vm._s(_vm.dataC.aberto_fechado == 0 ? "Abrir Loja" : _vm.dataC.aberto_fechado == 1 ? "Fechar Loja" : "")+" ")])]),_c('ul',{staticClass:"sidebar-nav",attrs:{"id":"sidebar-nav"}},[(_vm.dataColab[0] && _vm.dataColab[0].nivel_acesso == 1)?_c('li',{staticClass:"nav-item",on:{"click":_vm.dashboard}},[_vm._m(0)]):_vm._e(),_c('li',{staticClass:"nav-item",on:{"click":_vm.pedidos_recebidos}},[_vm._m(1)]),_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",staticStyle:{"cursor":"pointer"},on:{"click":_vm.pedidos_aceitos}},[_c('i',{staticClass:"bi bi-bag-check-fill"}),_c('span',[_vm._v("Pedidos aceitos")])])]),_c('li',{staticClass:"nav-item",on:{"click":_vm.cadastrar_produtos}},[_vm._m(2)]),_c('li',{staticClass:"nav-item",on:{"click":_vm.fidelidade}},[_vm._m(3)]),_c('li',{staticClass:"nav-item",on:{"click":_vm.clientes}},[_vm._m(4)]),_c('li',{staticClass:"nav-item",staticStyle:{"display":"none"},on:{"click":_vm.blog}},[_vm._m(5)]),_c('li',{staticClass:"nav-item",staticStyle:{"display":"none"},on:{"click":_vm.receitas}},[_vm._m(6)]),(_vm.dataColab[0].nivel_acesso == 1)?_c('li',{staticClass:"nav-item",on:{"click":_vm.configuracoes}},[_vm._m(7)]):_vm._e(),_c('li',{staticClass:"nav-item",on:{"click":_vm.logout}},[_vm._m(8)])])]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"nav-link",staticStyle:{"cursor":"pointer"}},[_c('i',{staticClass:"bi bi-bar-chart"}),_c('span',[_vm._v("Dashboard")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"nav-link",staticStyle:{"cursor":"pointer"}},[_c('i',{staticClass:"bi bi-hourglass-split"}),_c('span',[_vm._v("Pedidos recebidos")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"nav-link",staticStyle:{"cursor":"pointer"}},[_c('i',{staticClass:"bi bi-plus-lg"}),_c('span',[_vm._v("Cadastrar produtos")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"nav-link",staticStyle:{"cursor":"pointer"}},[_c('i',{staticClass:"bi bi-heart"}),_c('span',[_vm._v("Fidelize seus clientes")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"nav-link",staticStyle:{"cursor":"pointer"}},[_c('i',{staticClass:"bi bi-people"}),_c('span',[_vm._v("Clientes")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"nav-link",staticStyle:{"cursor":"pointer"}},[_c('i',{staticClass:"bi bi-pencil"}),_c('span',[_vm._v("Matérias")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"nav-link",staticStyle:{"cursor":"pointer"}},[_c('i',{staticClass:"bi bi-book"}),_c('span',[_vm._v("Receitas")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"nav-link",staticStyle:{"cursor":"pointer"}},[_c('i',{staticClass:"bi bi-gear-fill"}),_c('span',[_vm._v("Configurações")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"nav-link",staticStyle:{"cursor":"pointer"}},[_c('i',{staticClass:"bi bi-door-open-fill"}),_c('span',[_vm._v("Sair")])])
}]

export { render, staticRenderFns }