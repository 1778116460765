<template>
  <div>
    <header
      id="header"
      class="header fixed-top d-flex align-items-center"
      v-if="!forgotPassword"
    >
      <button
        class="btn btn-link toggle-sidebar-btn"
        type="button"
        @click="changeCollapsed()"
        v-if="isMobile"
        style="color: #ffcba5"
      >
        <i class="bi bi-list"></i>
      </button>
      <!-- <button
        class="btn btn-link toggle-sidebar-btn"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#sidebar"
        aria-expanded="false"
        aria-controls="sidebar"
        @click="changeCollapsed()"
        v-if="isMobile"
        style="color: #ffcba5"
      >
        <i class="bi bi-list"></i>
      </button> -->
      <div class="d-flex align-items-center justify-content-between">
        <div class="image-header">
          <img
            v-bind:src="'https://edrafox.com.br/assets/edralivery-img/' + dataC.logo"
            alt=""
          />
        </div>
        <div v-if="!isMobile" class="title-header">Bem vindo! Loja {{ dataC.nome }}</div>
        <div v-if="isMobile" class="title-header">Loja {{ dataC.nome }}</div>
      </div>
      <div class="pt-2 d-flex align-items-center justify-content-center">
        <div id="liveAlertPlaceholder"></div>
      </div>
      <nav class="header-nav ms-auto">
        <ul class="d-flex align-items-center">
          <li style="cursor: pointer" class="nav-item dropdown" v-on:click="seeOrders()">
            <a class="nav-link nav-icon">
              <i class="bi bi-bell-fill"></i>
              <span id="qtPedidos" class="badge bg-danger badge-number">{{ 0 }}</span>
            </a>
          </li>
        </ul>
      </nav>
    </header>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "Header",
  components: {},
  props: {
    dataC: {
      type: Object,
      required: true,
    },
    forgotPassword: {
      type: Boolean,
      required: true,
    },
    sidebarCollapsed: {
      type: Boolean,
      required: true,
    },
    isMobile: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      email: "",
      password: "",
      showModal: false,
      msg: "",
      success: "",
      dataColab: [],
      url: "https://api-delivery.fly.dev/api",
    };
  },
  methods: {
    logar() {
      this.$emit("spinner", true);

      let params = {
        email: this.email,
        password: this.password,
        id_empresa: this.dataC.id,
      };
      axios
        .post(`${this.url}/adm/login/colab`, params)
        .then((response) => {
          this.dataColab = response.data;
          console.log(this.dataColab);
          this.$emit("spinner", false);
          localStorage.setItem("token", this.dataColab[0].Token.original.access_token);
          this.$emit("logar", this.dataColab);
        })
        .catch((err) => {
          this.modalMessage("Credencial ou senha erradas!", false);
          this.$emit("spinner", false);
          console.log(err);
        });
    },

    changeCollapsed() {
      this.$emit("changeCollapsed", !this.sidebarCollapsed);
    },

    modalMessage(m, r) {
      this.msg = m;
      this.success = r;
      this.showModal = true;
      setTimeout(() => {
        this.showModal = false;
      }, 2000);
    },

    seeOrders() {
      this.$emit("seeOrders");
    },

    forgetPassword() {
      this.$emit("forgetPassword", true);
    },
  },
  mounted() {
    console.log(this.dataC);
  },
};
</script>
<style>
.nav-link i {
  font-size: 30px;
  color: white;
}
</style>
