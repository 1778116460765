<template>
  <div>
    <Spinner v-if="showSpinner" :text="'Carregando'"></Spinner>
    <Message :msg="msg" :res="success" v-if="showModal"> </Message>
    <div class="card">
      <div class="d-flex buttonsToAdd">
        <button
          data-bs-toggle="modal"
          data-bs-target="#modalDialogPremio"
          class="button col-12 col-lg-12 col-sm-10 m-2"
          v-on:click="clearDataToEdit()"
        >
          Novo prêmio Fidelidade
        </button>

        <button
          data-bs-toggle="modal"
          v-on:click="clearDataToEdit()"
          data-bs-target="#modalDialogCupom"
          class="button col-12 col-lg-12 col-sm-10 m-2"
        >
          Novo Cupom Desconto
        </button>

        <button
          data-bs-toggle="modal"
          v-on:click="clearDataToEdit()"
          data-bs-target="#modalDialogPromocao"
          class="button col-lg-12 col-12 m-2"
        >
          Nova Promoção
        </button>
      </div>

      <div class="row searchItens">
        <div class="col-12 col-lg-5 col-md-5 col-sm-12 mt-4">
          <h5>Pesquise aqui cupom, prêmio, promoção:</h5>
          <input
            v-model="inputFidelity"
            class="form-control"
            type="text"
            placeholder="Pesquisar"
          />
        </div>
        <div class="col-12 col-lg-5 col-md-5 col-sm-12 mt-4">
          <h5>Tipo de item</h5>
          <select
            class="form-control"
            name="itensType"
            id="itensType"
            v-model="selectFidelityType"
          >
            <option value="">Todos</option>
            <option value="Promoções">Promoção</option>
            <option value="Pontos Fidelidade">Fidelidade</option>
            <option value="Cupom">Cupom</option>
          </select>
        </div>
        <div
          class="col-12 col-lg-2 col-md-2 col-sm-12 d-flex flex-column justify-content-end mt-4"
        >
          <button @click="filter()" class="button">Pesquisar</button>
          <button
            v-if="filterSearched"
            @click="clearFilter()"
            class="button-outline mt-2"
          >
            Limpar
          </button>
        </div>
      </div>

      <div class="custom-table">
        <b-table
          id="my-table"
          :items="newItems"
          :fields="fields"
          label-sort-asc=""
          label-sort-desc=""
          label-sort-clear=""
          :per-page="perPage"
          :current-page="currentPage"
        >
          <!-- eslint-disable-next-line vue/no-unused-vars -->
          <template #cell(acoes)="row">
            <i
              v-if="row.item.tipoDeFidelidade == 'Cupom'"
              data-bs-toggle="modal"
              data-bs-target="#modalDialogCupom"
              v-on:click="openModalCupom(row.item.id)"
              class="bi bi-gear-fill"
            ></i>
            <i
              data-bs-toggle="modal"
              data-bs-target="#modalDialogPremio"
              v-if="row.item.tipoDeFidelidade == 'Pontos Fidelidade'"
              class="bi bi-gear-fill"
              v-on:click="openModalPremio(row.item.id)"
            ></i>
            <i
              data-bs-toggle="modal"
              data-bs-target="#modalDialogPromocao"
              v-on:click="openModalPromocao(row.item.id)"
              v-if="row.item.tipoDeFidelidade == 'Promoções'"
              class="bi bi-gear-fill"
            ></i>
          </template>
          <template #cell(tipoDeFidelidade)="row">
            <button class="btn btn-primary" v-if="row.item.tipoDeFidelidade == 'Cupom'">
              {{ row.item.tipoDeFidelidade }}
            </button>
            <button
              class="btn btn-warning"
              v-if="row.item.tipoDeFidelidade == 'Pontos Fidelidade'"
            >
              {{ row.item.tipoDeFidelidade }}
            </button>
            <button
              class="btn btn-success"
              v-if="row.item.tipoDeFidelidade == 'Promoções'"
            >
              {{ row.item.tipoDeFidelidade }}
            </button>
          </template>
          <template #cell(created_at)="row">
            {{ formatDate(row.item.created_at) }}
          </template>
          <template #cell(updated_at)="row">
            {{ formatDate(row.item.updated_at) }}
          </template>
          <template #cell(pontos)="row">
            {{ row.item.pontos || "-" }}
          </template>
          <template #cell(desconto)="row">
            {{
              row.item.desconto !== null && row.item.desconto !== undefined
                ? "R$" + row.item.desconto
                : "-"
            }}
          </template>

          <template #cell(qtUtilizacao)="row">
            {{ row.item.quantidade_max_utilizacao || "-" }}
          </template>
        </b-table>

        <div class="custom-pagination">
          <h4>Mostrando 1 até 10 de 134 registros</h4>
          <b-pagination
            v-model="currentPage"
            :total-rows="newItems.length"
            :per-page="perPage"
            aria-controls="my-table"
          ></b-pagination>
        </div>
      </div>

      <div
        class="modal fade"
        ref="modalDialogScrollable"
        id="modalDialogPremio"
        tabindex="-1"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 v-if="!editingItem" class="modal-title">Adicione um novo prêmio</h5>
              <h5 v-if="editingItem" class="modal-title">Editando prêmio</h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="closeModalPremio"
              ></button>
            </div>
            <div class="modal-body">
              <div class="card-body">
                <div class="col-md-12 col-12">
                  <label for="validationDefault01" style="float: left" class="form-label"
                    >Nome do prêmio</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    value=""
                    required
                    v-model="nome_premio_f"
                  />
                </div>
                <div class="col-md-12 col-12">
                  <label for="validationDefault01" style="float: left" class="form-label"
                    >Quantidade de pontos</label
                  >
                  <input
                    v-model="pontos_premio"
                    type="number"
                    class="form-control"
                    required
                  />
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                v-if="!editingItem"
                type="button"
                v-on:click="criar_premio"
                class="button"
              >
                Salvar
              </button>
              <button
                v-if="editingItem"
                type="button"
                v-on:click="atualizarPremio()"
                class="button"
              >
                Atualizar
              </button>
            </div>
          </div>
        </div>
      </div>
      <!--Modal cupons-->
      <div
        class="modal fade"
        ref="modalDialogScrollable"
        id="modalDialogCupom"
        tabindex="-1"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 v-if="!editingItem" class="modal-title">Adicione um novo cupom</h5>
              <h5 v-if="editingItem" class="modal-title">Editando cupom</h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="closeModalCupom"
              ></button>
            </div>
            <div class="modal-body">
              <div class="card-body">
                <div class="col-md-12 col-12">
                  <label for="validationDefault01" style="float: left" class="form-label"
                    >Nome cupom</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    value=""
                    required
                    v-model="nome_cupom"
                  />
                </div>
                <div class="col-md-12 col-12">
                  <label for="validationDefault01" style="float: left" class="form-label"
                    >Tipo</label
                  >
                  <select
                    type="number"
                    class="form-control"
                    required
                    v-model="tipo_cupom"
                  >
                    <option disabled value="">Selecione o tipo</option>
                    <option value="0">Dinheiro</option>
                    <option value="1">Porcentagem</option>
                  </select>
                </div>
                <div class="col-md-12 col-12">
                  <label style="float: left" class="form-label">Desconto</label>

                  <div v-if="tipo_cupom == 1">
                    <input
                      type="text"
                      class="form-control"
                      v-model="desconto_cupom"
                      v-money="configVmoneyClear"
                      required
                    />
                  </div>
                  <div v-if="tipo_cupom == 0">
                    <input
                      type="text"
                      class="form-control"
                      v-model="desconto_cupom"
                      required
                      v-money="configVmoney"
                    />
                  </div>
                </div>

                <div class="col-md-12 col-12">
                  <label for="validationDefault01" style="float: left" class="form-label"
                    >Quantidade maxima de utilização</label
                  >
                  <input
                    type="number"
                    class="form-control"
                    id="validationDefault01"
                    value="0"
                    v-model="max_cupom"
                    required
                  />
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                v-if="!editingItem"
                type="button"
                v-on:click="criar_cupom"
                class="button"
              >
                Salvar
              </button>
              <button
                v-if="editingItem"
                type="button"
                v-on:click="atualizar_cupom(id_cupom)"
                class="button"
              >
                Atualizar
              </button>
            </div>
          </div>
        </div>
      </div>
      <!--Modal promoções-->
      <div
        class="modal fade"
        ref="modalDialogScrollable"
        id="modalDialogPromocao"
        tabindex="-1"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 v-if="!editingItem" class="modal-title">Adicione uma nova promoção</h5>
              <h5 v-if="editingItem" class="modal-title">Editando promoção</h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="closeModalPromotion"
              ></button>
            </div>
            <div class="modal-body">
              <div
                class="modal-body"
                align="center"
                style="height: 69vh; overflow-y: scroll"
              >
                <label class="button-outline m-2" for="btn-prod">Adicionar foto</label>
                <input
                  style="display: none"
                  type="file"
                  id="btn-prod"
                  @change="previewImageProdutos"
                  accept="image/*"
                />
                <div
                  align="center"
                  style="margin-left: 30%"
                  class="d-flex align-items-center"
                >
                  <label for="btn-prod" v-if="imageDataProdutos">
                    <img
                      :src="imageDataProdutos"
                      alt="Profile"
                      style="width: 170px; height: 170px"
                      class="rounded-circle"
                    />
                  </label>
                </div>
                <div style="text-align: left">
                  <label class="mb-2 mt-1">Nome da promoção</label>
                  <input
                    v-model="nome_promocao"
                    class="form-control col-lg-6 col-6"
                    placeholder="Nome do produto"
                  />
                  <label class="mb-2 mt-1">Valor R$</label>
                  <input
                    v-model="valor_promocao"
                    class="form-control col-lg-6 col-6"
                    placeholder="Valor do produto"
                    v-money="configVmoney"
                  />
                  <label class="mb-2 mt-1">Informações</label>
                  <div style="margin-left: 0.5%" class="col-lg-12 col-12 row">
                    <textarea
                      v-model="info_promocao"
                      class="form-control"
                      placeholder="Informações sobre a promoção/produto"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                v-if="!editingItem"
                type="button"
                v-on:click="criar_promocao"
                class="button"
              >
                Salvar
              </button>
              <button
                v-if="editingItem"
                type="button"
                v-on:click="atualizar_promo(id_edit)"
                class="button"
              >
                Atualizar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { BPagination, BTable } from "bootstrap-vue";
import Spinner from "../components/Spinner.vue";
import Message from "../pages/Message.vue";

export default {
  name: "FidelidadeTela",
  components: {
    Message,
    BTable,
    BPagination,
    Spinner,
  },
  props: {
    token: String,
  },
  data() {
    return {
      nome_premio_f: "",
      id_cupom: "",
      pontos_premio: "",
      nome_cupom: "",
      tipo_cupom: "",
      desconto_cupom: "",
      max_cupom: "",
      lista_premio: [],
      lista_cupom: [],
      lista_promocao: [],
      url: "https://api-delivery.fly.dev/api",
      imageDataProdutos: "https://static.thenounproject.com/png/104062-200.png",
      nome_promocao: "",
      valor_promocao: "",
      info_promocao: "",
      img: "",
      id_edit: 0,
      showModal: false,
      msg: "",
      success: false,
      showSpinner: false,
      filterSearched: false,
      editingItem: false,
      photoChanged: false,
      allItems: {},
      configVmoney: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
      },
      configVmoneyClear: {
        decimal: ",",
        thousands: ".",
        prefix: "",
      },
      perPage: 9,
      currentPage: 1,
      inputFidelity: "",
      selectFidelityType: "",
      fields: [
        {
          label: "Ações",
          key: "acoes",
          sortable: false,
        },
        {
          label: "Tipo de Fidelidade",
          key: "tipoDeFidelidade",
          sortable: true,
        },
        {
          label: "Criado",
          key: "created_at",
          sortable: true,
        },
        {
          label: "Atualizado",
          key: "updated_at",
          sortable: true,
        },
        {
          label: "Nome",
          key: "nome",
          sortable: true,
        },
        {
          label: "Pontos",
          key: "pontos",
          sortable: true,
        },
        {
          label: "Desconto",
          key: "desconto",
          sortable: true,
        },
        {
          label: "QT utilização",
          key: "qtUtilizacao",
          sortable: true,
        },
      ],
      newItems: [],
      items: [],
    };
  },
  methods: {
    previewImageProdutos: function (event) {
      var input = event.target;
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.imageDataProdutos = e.target.result;
        };
        this.img = input.files[0];
        this.photoChanged = true;
        reader.readAsDataURL(input.files[0]);
      }
    },
    criar_premio() {
      this.showSpinner = true;

      const formData = new FormData();
      formData.append("nome_premio", this.nome_premio_f);
      formData.append("pontos", this.pontos_premio);
      formData.append("id_empresa", localStorage.getItem("id"));
      axios
        .post(`${this.url}/adm/novo/fidelidade`, formData, {
          headers: {
            Authorization: `Bearer${this.token}`,
          },
        })
        .then(() => {
          this.pontos_premio = "";
          this.nome_premio_f = "";
          this.showSpinner = false;
          this.modalMessage("Prêmio adicionado com sucesso!", true);
          const closeModal = document.getElementById("closeModalPremio");
          closeModal.click();
          this.listingAllItemsFidelity();
        })
        .catch((err) => {
          console.log(err);
          this.modalMessage("Erro ao criar premio!", false);
          this.showSpinner = false;
        });
    },

    openModalPremio(i) {
      this.photoChanged = false;
      this.editingItem = true;
      this.showSpinner = true;
      axios
        .post(
          `${this.url}/adm/empresa/${localStorage.getItem("id")}/fidelidade/${i}`,
          "",
          {
            headers: {
              Authorization: `Bearer${this.token}`,
            },
          }
        )
        .then((resp) => {
          console.log(resp.data);
          this.pontos_premio = resp.data[0].pontos;
          this.nome_premio_f = resp.data[0].nome_premio;
          this.tempId = resp.data[0].id;
          this.showSpinner = false;
        })
        .catch((err) => {
          console.log(err);
          this.showSpinner = false;
        });
    },

    atualizarPremio() {
      this.showSpinner = true;

      const formData = new FormData();
      formData.append("nome_premio", this.nome_premio_f);
      formData.append("pontos", this.pontos_premio);
      axios
        .post(`${this.url}/adm/atualiza/fidelidade/${this.tempId}`, formData, {
          headers: {
            Authorization: `Bearer${this.token}`,
          },
        })
        .then(() => {
          this.modalMessage("Atualizado com sucesso!", true);
          this.showSpinner = false;
          const closeModal = document.getElementById("closeModalPremio");
          closeModal.click();
          this.listingAllItemsFidelity();
        })
        .catch((err) => {
          console.log(err);
          this.modalMessage("Erro ao atualizar!", false);
          this.showSpinner = false;
        });
    },
    excluir_premio(i) {
      this.showSpinner = true;

      axios
        .post(`${this.url}/adm/deletar/fidelidade/${i}`, "", {
          headers: {
            Authorization: `Bearer${this.token}`,
          },
        })
        .then(() => {
          this.modalMessage("Excluído com sucesso!", true);
          this.showSpinner = false;
        })
        .catch((err) => {
          console.log(err);
          this.modalMessage("Erro ao excluir!", false);
          this.showSpinner = false;
        });
    },
    criar_cupom() {
      this.showSpinner = true;
      const formData = new FormData();
      formData.append("nome_cupom", this.nome_cupom);
      formData.append("desconto", this.formatMoneyClear(this.desconto_cupom));
      formData.append("tipo_desconto", this.tipo_cupom);
      formData.append("quantidade_max_utilizacao", this.max_cupom);
      formData.append("id_empresa", localStorage.getItem("id"));
      axios
        .post(`${this.url}/adm/novo/cupom`, formData, {
          headers: {
            Authorization: `Bearer${this.token}`,
          },
        })
        .then(() => {
          this.modalMessage("Cupom adicionado com sucesso!", true);
          this.nome_cupom = "";
          this.tipo_cupom = "";
          this.desconto_cupom = "";
          this.max_cupom = "";
          this.showSpinner = false;
          const closeModal = document.getElementById("closeModalCupom");
          closeModal.click();
          this.listingAllItemsFidelity();
        })
        .catch((err) => {
          console.log(err);
          this.modalMessage("Erro ao criar cupom!", false);
          this.showSpinner = false;
        });
    },
    atualizar_cupom(i) {
      this.showSpinner = true;
      const formData = new FormData();
      formData.append("nome_cupom", this.nome_cupom);
      formData.append("desconto", this.formatMoneyClear(this.desconto_cupom));
      formData.append("tipo_desconto", this.tipo_cupom);
      formData.append("quantidade_max_utilizacao", this.max_cupom);
      formData.append("id_empresa", localStorage.getItem("id"));
      axios
        .post(`${this.url}/adm/atualiza/cupom/${i}`, formData, {
          headers: {
            Authorization: `Bearer${this.token}`,
          },
        })
        .then(() => {
          this.modalMessage("Cupom atualizado com sucesso!", true);
          this.showSpinner = false;
          const closeModal = document.getElementById("closeModalCupom");
          closeModal.click();
          this.listingAllItemsFidelity();
        })
        .catch((err) => {
          console.log(err);
          this.modalMessage("Erro ao atualizar cupom!", false);
          this.showSpinner = false;
        });
    },
    openModalCupom(i) {
      this.photoChanged = false;
      this.editingItem = true;
      this.showSpinner = true;
      axios
        .post(`${this.url}/adm/empresa/${localStorage.getItem("id")}/cupom/${i}`, "", {
          headers: {
            Authorization: `Bearer${this.token}`,
          },
        })
        .then((res) => {
          console.log(res.data);
          let r = res["data"][0];
          this.nome_cupom = r.nome_cupom;
          this.id_cupom = r.id;
          this.desconto_cupom = r.desconto;
          this.tipo_cupom = r.tipo_desconto;
          this.max_cupom = r.quantidade_max_utilizacao;
          this.showSpinner = false;
        })
        .catch((err) => {
          console.log(err);
          this.showSpinner = false;
        });
    },
    excluir_cupom(i) {
      this.showSpinner = true;
      axios
        .post(`${this.url}/adm/deletar/cupom/${i}`, "", {
          headers: {
            Authorization: `Bearer${this.token}`,
          },
        })
        .then(() => {
          this.modalMessage("Excluído com sucesso!", true);
          this.showSpinner = false;
        })
        .catch((err) => {
          console.log(err);
          this.modalMessage("Erro ao excluir!", true);
          this.showSpinner = false;
        });
    },
    listingAllItemsFidelity() {
      this.showSpinner = true;
      axios
        .post(`${this.url}/adm/showAllData/${localStorage.getItem("id")}`, "", {
          headers: {
            Authorization: `Bearer${this.token}`,
          },
        })
        .then((res) => {
          this.allItems = res.data;
          console.log(res.data);
          this.lista_premio = this.allItems["Premiofidelidade"];
          this.lista_cupom = this.allItems["cupons"];
          this.lista_promocao = this.allItems["promocoes"];

          this.lista_premio.forEach((element) => {
            element.tipoDeFidelidade = "Pontos Fidelidade";
            element.nome = element.nome_premio;
            this.items.push(element);
          });
          this.lista_cupom.forEach((element) => {
            element.tipoDeFidelidade = "Cupom";
            element.nome = element.nome_cupom;
            this.items.push(element);
          });
          this.lista_promocao.forEach((element) => {
            element.tipoDeFidelidade = "Promoções";
            element.nome = element.nome_promocao;
            this.items.push(element);
          });
          this.showSpinner = false;
        })
        .catch((err) => {
          console.log(err);
          this.showSpinner = false;
        });
    },

    criar_promocao() {
      this.showSpinner = true;
      const formData = new FormData();
      const img = this.img;
      let valuePromo = this.formatMoneyClear(this.valor_promocao);
      formData.append("nome_promocao", this.nome_promocao);
      formData.append("img", img);
      formData.append("valor", valuePromo);
      formData.append("informacao", this.info_promocao);
      formData.append("id_empresa", localStorage.getItem("id"));
      axios
        .post(`${this.url}/adm/novo/promocao`, formData, {
          headers: {
            Authorization: `Bearer${this.token}`,
          },
        })
        .then(() => {
          this.modalMessage("Promoção adicionada com sucesso!", true);
          this.img = null;
          this.nome_promocao = "";
          this.valor_promocao = "";
          this.info_promocao = "";
          this.imageDataProdutos = "https://static.thenounproject.com/png/104062-200.png";

          const closeModal = document.getElementById("closeModalPromotion");
          closeModal.click();
          this.showSpinner = false;
          this.listingAllItemsFidelity();
        })
        .catch((err) => {
          console.log(err);
          this.modalMessage("Erro ao criar promoção!", false);
          this.showSpinner = false;
        });
    },
    openModalPromocao(i) {
      this.photoChanged = false;
      this.editingItem = true;
      this.showSpinner = true;
      axios
        .post(`${this.url}/adm/promocao/${i}`, "", {
          headers: {
            Authorization: `Bearer${this.token}`,
          },
        })
        .then((res) => {
          this.img = null;
          console.log(res["data"][0]);
          this.imageDataProdutos = `https://edrafox.com.br/assets/edralivery-img/${res["data"][0].img}`;
          this.nome_promocao = res["data"][0].nome_promocao;
          this.img = res["data"][0].img;
          this.valor_promocao = res["data"][0].valor * 100;
          this.info_promocao = res["data"][0].informacao;
          this.id_edit = res["data"][0].id;
          this.showSpinner = false;
        })
        .catch((err) => {
          console.log(err);
          this.showSpinner = false;
        });
    },

    clearDataToEdit() {
      this.editingItem = false;
      this.nome_premio_f = "";
      this.pontos_premio = "";
      this.nome_cupom = "";
      this.tipo_cupom = "";
      this.desconto_cupom = "";
      this.max_cupom = "";
      this.nome_promocao = "";
      this.valor_promocao = "";
      this.info_promocao = "";
      this.imageDataProdutos = "";
    },

    formatMoneyClear(valor) {
      const valorNumerico = valor.toString().replace(/[^0-9,.]/g, "");
      const numero = parseFloat(valorNumerico.replace(",", "."));
      console.log(numero);
      return numero;
    },
    atualizar_promo(i) {
      this.showSpinner = true;
      const formData = new FormData();
      formData.append("img", this.img);
      console.log(this.img);
      formData.append("nome_promocao", this.nome_promocao);
      formData.append("valor", this.formatMoneyClear(this.valor_promocao));
      formData.append("informacao", this.info_promocao);
      formData.append("id_empresa", localStorage.getItem("id"));
      axios
        .post(`${this.url}/adm/atualiza/promocao/${i}`, formData, {
          headers: {
            Authorization: `Bearer${this.token}`,
          },
        })
        .then(() => {
          this.modalMessage("Atualizado com sucesso!", true);
          this.showSpinner = false;
          const closeModal = document.getElementById("closeModalPromotion");
          closeModal.click();
          this.listingAllItemsFidelity();
        })
        .catch((err) => {
          console.log(err);
          this.modalMessage("Erro ao atualizar!", false);
          this.showSpinner = false;
        });
    },
    exclui_promocao(i) {
      this.showSpinner = true;

      axios
        .post(`${this.url}/adm/deletar/promocao/${i}`, "", {
          headers: {
            Authorization: `Bearer${this.token}`,
          },
        })
        .then(() => {
          this.modalMessage("Excluído com sucesso!", true);
          this.showSpinner = false;
        })
        .catch((err) => {
          console.log(err);
          this.modalMessage("Erro ao excluir!", true);
          this.showSpinner = false;
        });
    },

    filter() {
      let newArr = [];
      console.log(this.inputFidelity);
      console.log(this.items);
      console.log(this.selectFidelityType);
      console.log();
      this.filterSearched = true;

      if (this.inputFidelity !== "" && this.selectFidelityType !== "") {
        newArr = this.items.filter((element) => {
          return (
            element.nome.toLowerCase().includes(this.inputFidelity.toLowerCase()) &&
            element.tipoDeFidelidade === this.selectFidelityType
          );
        });
      } else if (this.inputFidelity !== "") {
        newArr = this.items.filter((element) => {
          return element.nome.toLowerCase().includes(this.inputFidelity.toLowerCase());
        });
      } else if (this.selectFidelityType !== "") {
        newArr = this.items.filter((element) => {
          return element.tipoDeFidelidade === this.selectFidelityType;
        });
      } else {
        newArr = this.items;
        this.filterSearched = false;
      }

      this.newItems = newArr;
    },

    clearFilter() {
      this.selectFidelityType = "";
      this.inputFidelity = "";
      this.filter();
    },

    formatDate(data) {
      const dataObj = new Date(data);
      const dia = dataObj.getDate().toString().padStart(2, "0");
      const mes = (dataObj.getMonth() + 1).toString().padStart(2, "0");
      const ano = dataObj.getFullYear().toString();
      return `${dia}/${mes}/${ano}`;
    },

    modalMessage(m, r) {
      this.msg = m;
      this.success = r;
      this.showModal = true;
      setTimeout(() => {
        this.showModal = false;
      }, 2000);
    },
  },
  mounted() {
    this.listingAllItemsFidelity();
    this.newItems = this.items;
  },
};
</script>
<style>
.card {
  background-color: #ffefe0;
  box-shadow: none;
}
.button,
.button-outline {
  width: auto;
  padding: 0 20px;
}
.buttonsToAdd {
}
.searchItens {
  /* margin-top: 30px; */
  padding: 20px 0;
}
.searchItens h5 {
  color: var(--grayscale-body, #4e4b66);
  font-size: 13px;
  font-family: Poppins;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.25px;
}

.custom-table {
  background-color: white;
  border-radius: 9px;
  border: none;
}

thead tr {
  border-radius: 9px 9px 0px 0px;
  background: #e5e5ea;
}
.table > :not(:first-child) {
  border: none;
}

th {
  cursor: pointer;
}
td {
  vertical-align: middle;
}

.bi-gear-fill {
  font-size: 20px;
  color: #0041ac;
  cursor: pointer;
}

.custom-pagination {
  padding: 0 20px;
  display: flex;
  align-items: center;
}

.custom-pagination .page-item,
.custom-pagination .page-link,
.page-item.disabled .page-link {
  color: var(--grayscale-label, #6e7191);
  border-color: white;
  margin: 0 5px;
}

.page-item.active .page-link {
  color: white;
  background-color: #d9dbe9;
  border-color: #d9dbe9;
}

.custom-pagination h4 {
  color: var(--grayscale-label, #6e7191);
  font-size: 13px;
  font-family: Poppins;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.25px;
}

li .page-item {
  margin: 0 20px;
}
</style>
