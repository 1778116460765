import "@/assets/css/style.css"
import '@/plugins/apexcharts'
import '@vue/cli-plugin-babel/preset'
import VueMask from 'v-mask'
import money from 'v-money'
import Vue from 'vue'
import Vuelidate from 'vuelidate'
import App from './App.vue'
import './registerServiceWorker'


Vue.use(Vuelidate)
Vue.use(VueMask)

Vue.use(money, { precision: 2 });

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
