<template>
  <div>
    <Message :msg="msg" :res="success" v-if="showModal"> </Message>

    <div tabindex="-9">
      <header class="d-flex justify-content-start">
        <button class="btn btn-primary" data-bs-toggle="modal" @click="clearInput()" data-bs-target="#newRecipe">Nova
          receita</button>
      </header>

      <div class="mt-3">
        <div class="card">
          <div class="card-body pt-3 bg-transparent">
            <!-- Bordered Tabs -->
            <ul class="nav nav-tabs nav-tabs-bordered">
              <li class="nav-item">
                <button class="nav-link active" data-bs-toggle="tab" data-bs-target="#atuais">
                  Atuais
                </button>
              </li>

              <li class="nav-item">
                <button class="nav-link" data-bs-toggle="tab" data-bs-target="#arquivadas">
                  Arquivadas
                </button>
              </li>
            </ul>


            <div class="tab-content pt-2">
              <div class="tab-pane fade show active" id="atuais">

                <body class="mt-4">
                  <h3>Atuais</h3>
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">Título</th>
                        <th scope="col">Subtítulo</th>
                        <th scope="col">Data Publicação</th>
                        <th scope="col">Ações</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="align-middle" v-for="(item, id) in currentRecipe" :key="id">
                        <td>{{ item.title }}</td>
                        <td>{{ item.caption }}</td>
                        <td>{{ formatDate(item.created_at) }}</td>
                        <td>
                          <button class="btn btn-primary mx-1" data-bs-toggle="modal" data-bs-target="#seeRecipe"
                            @click="getDataToSeeRecipe(item)">
                            <i class="bi bi-eye-fill text-white h5"></i>
                          </button>
                          <button class="btn btn-success mx-1" data-bs-toggle="modal" data-bs-target="#editRecipe"
                            @click="getDataToEdit(item)">
                            <i class="bi bi-pen-fill text-white h5"></i>
                          </button>
                          <button class="btn btn-danger mx-1" @click="deleteRecipe(item.id)">
                            <i class="bi bi-trash text-white h5"></i>
                          </button>
                        </td>
                        <td>
                          <button class="btn btn-success mx-1" @click="changeStatusRecipe(item.id)">
                            <i class="bi bi-folder-plus"></i>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </body>
              </div>

              <div class="tab-pane fade" id="arquivadas">

                <body class="mt-4">
                  <h3>Arquivadas</h3>
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">Título</th>
                        <th scope="col">Subtítulo</th>
                        <th scope="col">Data Publicação</th>
                        <th scope="col">Ações</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="align-middle" v-for="(item, id) in fileRecipe" :key="id">
                        <td>{{ item.title }}</td>
                        <td>{{ item.caption }}</td>
                        <td>{{ formatDate(item.created_at) }}</td>
                        <td>
                          <button class="btn btn-primary mx-1" data-bs-toggle="modal" data-bs-target="#seeRecipe"
                            @click="getDataToSeeRecipe(item)">
                            <i class="bi bi-eye-fill text-white h5"></i>
                          </button>
                          <button class="btn btn-success mx-1" data-bs-toggle="modal" data-bs-target="#editRecipe"
                            @click="getDataToEdit(item)">
                            <i class="bi bi-pen-fill text-white h5"></i>
                          </button>
                          <button class="btn btn-danger mx-1" @click="deleteRecipe(item.id)">
                            <i class="bi bi-trash text-white h5"></i>
                          </button>
                        </td>
                        <td>
                          <button class="btn btn-success mx-1" @click="changeStatusRecipe(item.id)">
                            <i class="bi bi-folder-minus"></i>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </body>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="newRecipe" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Adicione uma nova receita</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body d-flex flex-column" align="center" style="height: 69vh; overflow-y: scroll">
            <label for="button-outline" class="btn btn-primary m-2">Adicionar foto</label>
            <input @change="sendImage($event, 'create')" class="form-control col-lg-6 col-6" accept="image/*"
              type="file" id="btn-create" style="display: none" />

            <div>
              <img class="card-img mt-2" style="border-radius: 10px; width: 80%; height: 200px;" v-bind:src="imageData">
            </div>
            <label class="mb-2 mt-4">Título</label>
            <input v-model="$v.dataCard.title.$model" type="text" placeholder="Insira o Título"
              v-bind:class="$v.dataCard.title.$error ? 'form-control col-lg-6 col-6 is-invalid mb-2' : 'form-control col-lg-6 col-6 mb-2'" />
            <span class="text-danger" v-if="$v.dataCard.title.$model === '' && $v.dataCard.title.$touch">Campo
              obrigatório!</span>
            <span class="text-danger" v-if="$v.dataCard.title.$error">Minímo 5 caracteres!</span>

            <label class="mb-2 mt-1">Subtítulo</label>
            <input v-model="dataCard.subtitle" placeholder="Insira o Subtítulo" class="form-control col-lg-6 col-6" />

            <label class="mb-2 mt-1">Descrição</label>
            <textarea v-model="$v.dataCard.info.$model" placeholder="Insira a Descrição"
              v-bind:class="$v.dataCard.info.$error ? 'form-control col-lg-6 col-6 is-invalid' : 'form-control col-lg-6 col-6'"
              rows="5"></textarea>
            <span class="text-danger" v-if="$v.dataCard.info.$model === '' && $v.dataCard.info.$touch">Campo
              obrigatório!</span>
            <span class="text-danger" v-if="$v.dataCard.info.$error">Minímo 5 caracteres!</span>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Cancelar</button>
            <button type="button" class="btn btn-primary" data-bs-dismiss="modal" @click="createRecipe()">
              Salvar
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="seeRecipe" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body" align="center" style="height: 69vh; overflow-y: scroll">
            <div class="row d-flex justify-content-center">
              <div class="card col-10">
                <div
                  v-if="seeCard.imgMaterias !== null && seeCard.imgMaterias !== undefined && seeCard.imgMaterias !== ''">
                  <img class="card-img mt-2" style="border-radius: 10px; width: 80%; height: 200px;" v-bind:src="
                  'https://edrafox.com.br/assets/edralivery-img/' + seeCard.imgMaterias">
                </div>
                <div v-else>
                  <img class="card-img mt-2" style="border-radius: 10px; width: 80%; height: 200px;"
                    v-bind:src="imageEmpty">
                </div>
                <h3 class="card-title">{{ seeCard.title }}</h3>
                <p class="card-text">{{ seeCard.caption }}</p>
                <p class="card-text mb-2">{{ seeCard.text }}</p>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" data-bs-dismiss="modal">Fechar</button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="editRecipe" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Editar receita</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body d-flex flex-column" align="center" style="height: 69vh; overflow-y: scroll">
            <label for="btn-edit" class="btn btn-primary m-2">Editar foto</label>
            <input @change="sendImage($event, 'edit')" class="form-control col-lg-6 col-6" accept="image/*" type="file"
              id="btn-edit" style="display: none" />

            <div>
              <img class="card-img mt-2" style="border-radius: 10px; width: 80%; height: 200px;"
                v-bind:src="editCard.imageData">
            </div>
            <label class="mb-2 mt-4">Título</label>
            <input v-model="$v.editCard.title.$model" placeholder="Insira o Título"
              v-bind:class="$v.editCard.title.$error ? 'form-control col-lg-6 col-6 is-invalid  mb-2' : 'form-control col-lg-6 col-6  mb-2'" />
            <span class="text-danger" v-if="$v.editCard.title.$model === '' && $v.editCard.title.$touch">Campo
              obrigatório!</span>
            <span class="text-danger" v-if="$v.editCard.title.$error">Minímo 5 caracteres!</span>

            <label class="mb-2 mt-1">Subtítulo</label>
            <input v-model="editCard.subtitle" placeholder="Insira o Subtítulo" class="form-control col-lg-6 col-6" />

            <label class="mb-2 mt-1">Descrição</label>
            <textarea v-model="$v.editCard.info.$model" placeholder="Insira a Descrição" rows="5"
              v-bind:class="$v.editCard.info.$error ? 'form-control col-lg-6 col-6 is-invalid  mb-2' : 'form-control col-lg-6 col-6  mb-2'"></textarea>
            <span class="text-danger" v-if="$v.editCard.info.$model === '' && $v.editCard.info.$touch">Campo
              obrigatório!</span>
            <span class="text-danger" v-if="$v.editCard.info.$error">Minímo 5 caracteres!</span>

          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Cancelar</button>
            <button type="button" class="btn btn-primary" data-bs-dismiss="modal" @click="editRecipe()">
              Salvar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import { minLength, required } from 'vuelidate/lib/validators';
import Message from "./Message.vue";

export default {
  name: "Receitas",
  components: {
    Message
  },
  props: {
    empresa: {},
    token: String,
  },
  data() {
    return {
      url: 'https://api-delivery.fly.dev/api/',
      imageData: "https://static.thenounproject.com/png/104062-200.png",
      imageEmpty: "https://static.thenounproject.com/png/104062-200.png",
      dataCard: {
        title: '',
        subtitle: '',
        info: '',
        image: null
      },
      editCard: {
        title: '',
        subtitle: '',
        info: '',
        image: null,
        id: null,
      },
      listRecipe: [],
      fileRecipe: [],
      currentRecipe: [],
      seeCard: {},
      showModal: false,
    };
  },
  validations: {
    dataCard: {
      title: {
        required,
        minLength: minLength(5)
      },
      info: {
        required,
        minLength: minLength(5)
      }
    },
    editCard: {
      title: {
        required,
        minLength: minLength(5)
      },
      info: {
        required,
        minLength: minLength(5)
      }
    }
  },
  methods: {
    sendImage(event, type) {
      var input = null
      var reader = null
      if (type === 'create') {
        input = event.target;
        if (input.files && input.files[0]) {
          reader = new FileReader();
          reader.onload = (e) => {
            this.imageData = e.target.result;
          };
          this.dataCard.image = input.files[0];
          reader.readAsDataURL(input.files[0]);
        }
      } else if (type === 'edit') {
        input = event.target;
        if (input.files && input.files[0]) {
          reader = new FileReader();
          reader.onload = (e) => {
            this.editCard.imageData = e.target.result;
          };
          this.editCard.image = event.target.files[0];
          reader.readAsDataURL(event.target.files[0]);
          this.editCard.imageEdited = true
        }
      }
    },

    getDataToEdit(item) {
      this.$v.editCard.$reset()
      this.editCard = {
        title: item.title,
        subtitle: item.caption,
        info: item.text,
        imageEdited: false,
        image: null,
        id: item.id,
        date: item.created_at,
        imageData: 'https://edrafox.com.br/assets/edralivery-img/' + item.imgMaterias,
        type: item.type,
      }
      if (item.imgMaterias === '' || item.imgMaterias === null || item.imgMaterias === undefined) {
        this.editCard.imageData = "https://static.thenounproject.com/png/104062-200.png"
      }
    },

    getDataToSeeRecipe(item) {
      this.seeCard = item
    },

    listingRecipeToday() {
      axios.post(`${this.url}revenues/today`, '',
        {
          headers: {
            Authorization: `Bearer${this.token}`,
          },
        }
      )
        .then((res) => {
          this.currentRecipe = []
          res.data.forEach(element => {
            if (element.type === '1') {
              this.currentRecipe.push(element)
            }
          });
          this.listingAllRecipe()
        })
        .catch((erro) => {
          console.log(erro);
        })
    },

    listingAllRecipe() {
      axios.post(`${this.url}revenues`, '',
        {
          headers: {
            Authorization: `Bearer${this.token}`,
          },
        }
      )
        .then((res) => {
          this.listRecipe = res.data
          this.fileRecipe = []

          res.data.forEach(element => {
            if (element.type === '2') {
              this.fileRecipe.push(element)
            }
          });
        })
        .catch((erro) => {
          console.log(erro);
        })
    },

    changeStatusRecipe(id) {
      axios.post(`${this.url}arquive/${id}`, '', {
        headers: {
          Authorization: `Bearer${this.token}`,
        },
      })
        .then(() => { this.listingRecipeToday() })
        .catch(() => { })
    },

    createRecipe() {
      if (this.$v.dataCard.title.$invalid === false && this.$v.dataCard.info.$invalid === false) {
        const formData = new FormData();
        formData.append("title", this.dataCard.title);
        formData.append("caption", this.dataCard.subtitle);
        formData.append("text", this.dataCard.info);
        formData.append("imgMaterias", this.dataCard.image);
        formData.append("type", '1');
        axios.post(`${this.url}add/materias`, formData,
          {
            headers: {
              Authorization: `Bearer${this.token}`,
            },
          }
        )
          .then(() => {
            this.listingRecipeToday()
            this.menssagem("Receita criada com sucesso!", true);
          })
          .catch((erro) => {
            console.log(erro);
            this.menssagem("Erro ao criar receita!", false);
          })
      } else {
        this.menssagem("Campos inválidos!", false);
      }
    },

    editRecipe() {
      if (this.$v.editCard.title.$invalid === false && this.$v.editCard.info.$invalid === false) {
        const img = this.editCard.imageEdited ? this.editCard.image : this.editCard.imageData
        const formData = new FormData();
        formData.append("title", this.editCard.title);
        formData.append("caption", this.editCard.subtitle);
        formData.append("text", this.editCard.info);
        formData.append("imgMaterias", img);
        formData.append("type", '0');

        axios.post(`${this.url}update/materias/${this.editCard.id}`, formData,
          {
            headers: {
              Authorization: `Bearer${this.token}`,
            },
          }
        )
          .then(() => {
            this.listingRecipeToday()
            this.menssagem("Receita editada com sucesso!", true);

          })
          .catch((erro) => {
            console.log(erro);
            this.menssagem("Erro ao editar receita!", false);
          })
      } else {
        this.menssagem("Campos inválidos!", false);
      }
    },

    deleteRecipe(id) {
      axios.post(`${this.url}delete/materias/${id}`, '',
        {
          headers: {
            Authorization: `Bearer${this.token}`,
          },
        }
      )
        .then(() => {
          this.listingRecipeToday()
          this.menssagem("Receita apagada com sucesso!", true);
        })
        .catch((erro) => {
          console.log(erro);
          this.menssagem("Erro ao apagar receita!", false);
        })
    },

    formatDate(dateString) {
      const date = new Date(dateString);
      return new Intl.DateTimeFormat('default', { dateStyle: 'long' }).format(date);
    },

    clearInput() {
      this.dataCard = {
        title: '',
        subtitle: '',
        info: '',
        image: null
      }
      this.imageData = "https://static.thenounproject.com/png/104062-200.png"
      this.$v.dataCard.$reset()
    },

    menssagem(m, r) {
      this.msg = m;
      this.success = r;
      this.showModal = true;
      setTimeout(() => {
        this.showModal = false;
      }, 2000);
    },
  },
  mounted() {
    this.listingRecipeToday()
  },
};
</script>
<style>
td {
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>