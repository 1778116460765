<template>
  <div>
    <aside
      id="sidebar"
      class="sidebar collapse show"
      v-bind:class="{ 'd-none': isMobile && sidebarCollapsed }"
      v-if="!forgotPassword"
    >
      <button class="openStore" v-on:click="aberto_fechado" :disabled="buttonDisabled">
        <span v-if="isLoading">
          <i class="spinner-border custom-spinner" role="status"></i
        ></span>
        <span v-else>
          {{
            dataC.aberto_fechado == 0
              ? "Abrir Loja"
              : dataC.aberto_fechado == 1
              ? "Fechar Loja"
              : ""
          }}
        </span>
      </button>

      <ul class="sidebar-nav" id="sidebar-nav">
        <li
          v-if="dataColab[0] && dataColab[0].nivel_acesso == 1"
          class="nav-item"
          v-on:click="dashboard"
        >
          <a class="nav-link" style="cursor: pointer">
            <i class="bi bi-bar-chart"></i>
            <span>Dashboard</span>
          </a>
        </li>
        <li class="nav-item" v-on:click="pedidos_recebidos">
          <a class="nav-link" style="cursor: pointer">
            <i class="bi bi-hourglass-split"></i>
            <span>Pedidos recebidos</span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" style="cursor: pointer" v-on:click="pedidos_aceitos">
            <i class="bi bi-bag-check-fill"></i>
            <span>Pedidos aceitos</span>
          </a>
        </li>
        <li class="nav-item" v-on:click="cadastrar_produtos">
          <a class="nav-link" style="cursor: pointer">
            <i class="bi bi-plus-lg"></i>
            <span>Cadastrar produtos</span>
          </a>
        </li>
        <li class="nav-item" v-on:click="fidelidade">
          <a class="nav-link" style="cursor: pointer">
            <i class="bi bi-heart"></i>
            <span>Fidelize seus clientes</span>
          </a>
        </li>
        <li class="nav-item" v-on:click="clientes">
          <a class="nav-link" style="cursor: pointer">
            <i class="bi bi-people"></i>
            <span>Clientes</span>
          </a>
        </li>
        <li style="display: none" class="nav-item" v-on:click="blog">
          <a class="nav-link" style="cursor: pointer">
            <i class="bi bi-pencil"></i>
            <span>Matérias</span>
          </a>
        </li>
        <li style="display: none" class="nav-item" v-on:click="receitas">
          <a class="nav-link" style="cursor: pointer">
            <i class="bi bi-book"></i>
            <span>Receitas</span>
          </a>
        </li>
        <li
          class="nav-item"
          v-if="dataColab[0].nivel_acesso == 1"
          v-on:click="configuracoes"
        >
          <a class="nav-link" style="cursor: pointer">
            <i class="bi bi-gear-fill"></i>
            <span>Configurações</span>
          </a>
        </li>
        <li class="nav-item" v-on:click="logout">
          <a class="nav-link" style="cursor: pointer">
            <i class="bi bi-door-open-fill"></i>
            <span>Sair</span>
          </a>
        </li>
      </ul>
    </aside>
  </div>
</template>
<script>
export default {
  name: "Navbar",
  components: {},
  props: {
    dataColab: {
      type: Array,
      required: true,
    },
    dataC: {
      type: Object,
      required: true,
    },
    forgotPassword: {
      type: Boolean,
      required: true,
    },
    isMobile: {
      type: Boolean,
      required: true,
    },
    sidebarCollapsed: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    dataCAtualizada() {
      return this.dataC;
    },
  },
  data() {
    return {
      email: "",
      password: "",
      showModal: false,
      msg: "",
      success: "",
      url: "https://api-delivery.fly.dev/api",
      isLoading: false,
      buttonDisabled: false,
    };
  },
  methods: {
    logout() {
      this.$emit("logout");
    },
    configuracoes() {
      this.$emit("configuracoes");
    },
    receitas() {
      this.$emit("receitas");
    },
    blog() {
      this.$emit("blog");
    },
    clientes() {
      this.$emit("clientes");
    },
    fidelidade() {
      this.$emit("fidelidade");
    },
    cadastrar_produtos() {
      this.$emit("cadastrar_produtos");
    },
    pedidos_aceitos() {
      this.$emit("pedidos_aceitos");
    },
    pedidos_recebidos() {
      this.$emit("pedidos_recebidos");
    },
    dashboard() {
      this.$emit("dashboard");
    },
    aberto_fechado() {
      this.isLoading = true;
      this.buttonDisabled = true;
      const value = this.dataC.aberto_fechado;
      let contador = 0;
      const interval = setInterval(() => {
        contador += 2;
        if (value !== this.dataC.aberto_fechado || contador > 10) {
          this.isLoading = false;
          this.buttonDisabled = false;
          clearInterval(interval);
        }
      }, 2000);
      this.$emit("aberto_fechado");
    },

    modalMessage(m, r) {
      this.msg = m;
      this.success = r;
      this.showModal = true;
      setTimeout(() => {
        this.showModal = false;
      }, 2000);
    },

    forgetPassword() {
      this.$emit("forgetPassword", true);
      this.dataC = {};
    },
  },
  mounted() {
    
  },
};
</script>
<style>
.openStore {
  min-width: 126px;
  padding: 9px 22px;
  gap: 12px;
  border-radius: 8px;
  border: 2px solid #fe8a01;
  background-color: none;
  color: #903813;
  text-align: center;
  font-size: 13px;
  font-family: Poppins;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.25px;
  margin-bottom: 22px;
}

.custom-spinner {
  width: 1rem;
  height: 1rem;
}

.openStore:hover {
  color: #fe8a01;
  background-color: white;
}

.sidebar {
  position: fixed;
  top: 83px;
  left: 0;
  bottom: 0;
  width: 300px;
  z-index: 996;
  transition: all 0.3s;
  padding: 20px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #aab7cf transparent;
  box-shadow: 0px 0px 20px rgba(1, 41, 112, 0.1);
  background-color: #ffcba5;
}

.sidebar::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  background-color: #fff;
}

.sidebar::-webkit-scrollbar-thumb {
  background-color: #aab7cf;
}

@media (max-width: 1199px) {
  .sidebar {
    /* left: -300px; */
  }
}

@media (min-width: 1200px) {
  .main,
  .footer {
    margin-left: 300px;
  }

  .sidebar.collapsed ~ .main,
  .sidebar.collapsed ~ #footer {
    margin-left: 0;
  }

  .sidebar.collapsed ~ .main.margin-reset,
  .sidebar.collapsed ~ .footer.margin-reset {
    margin-left: 0 !important;
  }

  .toggle-sidebar .sidebar {
    left: -300px;
  }
}

.sidebar-nav {
  padding: 0;
  margin: 0;
  list-style: none;
}

.sidebar-nav li {
  padding: 0;
  margin: 0;
  list-style: none;
}

.sidebar-nav .nav-item {
  margin-bottom: 5px;
}

.sidebar-nav .nav-heading {
  font-size: 11px;
  text-transform: uppercase;
  color: #899bbd;
  font-weight: 600;
  margin: 10px 0 5px 15px;
}

.sidebar-nav .nav-link {
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 600;
  transition: 0.3s;
  padding: 10px 15px;
  border-radius: 4px;
  color: #903813;
  border-radius: 14px;
  background: #e9e9e9;
  height: 52px;
  /* margin-bottom: 22px; */
}

.sidebar-nav .nav-link i {
  font-size: 16px;
  margin-right: 10px;
  color: #903813;
}

.sidebar-nav .nav-link.collapsed {
  color: #012970;
  background: #fff;
}

.sidebar-nav .nav-link.collapsed i {
  color: #899bbd;
}

.sidebar-nav .nav-link:hover {
  color: #fe8a01;
  background: #f7fff6;
}

.sidebar-nav .nav-link:hover i {
  color: #fe8a01;
}

.sidebar-nav .nav-link .bi-chevron-down {
  margin-right: 0;
  transition: transform 0.2s ease-in-out;
}

.sidebar-nav .nav-link:not(.collapsed) .bi-chevron-down {
  transform: rotate(180deg);
}

.sidebar-nav .nav-content {
  padding: 5px 0 0 0;
  margin: 0;
  list-style: none;
}

.sidebar-nav .nav-content a {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  color: #012970;
  transition: 0.3s;
  padding: 10px 0 10px 40px;
  transition: 0.3s;
}

.sidebar-nav .nav-content a i {
  font-size: 6px;
  margin-right: 8px;
  line-height: 0;
  border-radius: 50%;
}

.sidebar-nav .nav-content a:hover,
.sidebar-nav .nav-content a.active {
  color: #4154f1;
}

.sidebar-nav .nav-content a.active i {
  background-color: #4154f1;
}
</style>
