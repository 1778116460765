<template>
  <div>
    <Message :msg="msg" :res="success" v-if="showModal"> </Message>

    <div class="container">
      <div class="row justify-content-center">
        <div
          class="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center"
        >
          <div class="d-flex justify-content-center py-4">
            <a href="index.html" class="logo d-flex align-items-center w-auto" v-if="dataC.logo">
              <img
                v-bind:src="'https://edrafox.com.br/assets/edralivery-img/' + dataC.logo"
                alt=""
              />
              <span class="d-none d-lg-block">{{ dataC.nome }}</span>
            </a>
          </div>
          <div class="card-login mb-3">
            <div class="card-body">
              <div class="pt-4 pb-2">
                <h5 class="card-title text-center pb-0 fs-4">Faça login!</h5>
                <p class="text-center small">
                  Insira sua credencial & senha para fazer login
                </p>
              </div>

              <div class="row g-3 needs-validation" novalidate>
                <div class="col-12">
                  <label for="credencial" class="form-label">Credencial</label>
                  <div class="input-group has-validation">
                    <input
                      type="text"
                      name="username"
                      class="form-control"
                      v-model="email"
                      required
                      @keyup.enter="logar"
                    />
                    <div class="invalid-feedback">Por favor insira sua credencial.</div>
                  </div>
                </div>

                <div class="col-12">
                  <label for="senha" class="form-label">Senha</label>
                  <input
                    type="password"
                    name="passwword"
                    class="form-control"
                    v-model="password"
                    required
                    @keyup.enter="logar"
                  />
                  <div class="invalid-feedback">Por favor insira sua senha!</div>
                </div>
                <div class="col-12">
                  <button
                    :disabled="!storeSelected"
                    class="button w-100"
                    v-on:click="logar"
                  >
                    Entrar
                  </button>
                </div>

                <div>
                  <span style="cursor: pointer" v-on:click="forgetPassword"
                    >Esqueci a senha</span
                  >
                </div>
              </div>
            </div>
          </div>

          <div class="credits">
            Criado por
            <a href="https://edrafox.com" style="font-weight: bolder" target="_blank"
              >EDRAFOX</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Message from "../pages/Message.vue";

export default {
  name: "Login",
  components: {
    Message,
  },
  props: {
    dataC: {
      type: Object,
      required: true,
    },
    storeSelected: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      email: "",
      password: "",
      showModal: false,
      msg: "",
      success: "",
      dataColab: [],
      url: "https://api-delivery.fly.dev/api",
    };
  },
  methods: {
    logar() {
      this.$emit("spinner", true);

      let params = {
        email: this.email,
        password: this.password,
        id_empresa: this.dataC.id,
      };
      axios
        .post(`${this.url}/adm/login/colab`, params)
        .then((response) => {
          this.dataColab = response.data;
          console.log(this.dataColab);
          this.$emit("spinner", false);
          localStorage.setItem("token", this.dataColab[0].Token.original.access_token);
          this.$emit("logar", this.dataColab);
        })
        .catch((err) => {
          this.modalMessage("Credencial ou senha erradas!", false);
          this.$emit("spinner", false);
          console.log(err);
        });
    },

    modalMessage(m, r) {
      this.msg = m;
      this.success = r;
      this.showModal = true;
      setTimeout(() => {
        this.showModal = false;
      }, 2000);
    },

    forgetPassword() {
      this.$emit("forgetPassword", true);
    },
  },
  mounted() {
    console.log(this.dataC);
  },
};
</script>
<style>
.card-login {
  border-radius: 19px;
  background: #f5f5f5;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
</style>