<template>
  <div>
    <Message :msg="msg" :res="success" v-if="showModal"> </Message>
    <Spinner v-if="showSpinner" :text="''"></Spinner>

    <h1>Pedidos Recebidos ✅</h1>
    <div class="row">
      <div v-if="pedidos.length > 0">
        <div class="row">
          <ReceivedOrderCard
            class="mb-3 col-12 col-lg-4 col-md-6 col-sm-12"
            v-for="pedido in pedidos"
            :key="pedido.id"
            :pedido="pedido"
            @cancelOrder="recusar"
            @acceptOrder="aceitar"
          ></ReceivedOrderCard>
        </div>
      </div>
      <div v-else>
        <p>Aguardando Pedidos! ❤️⏳</p>
      </div>
    </div>
    <div></div>
    <div id="cupom" style="display: none">
      <Cupom
        :infoPedido="dataPedido"
        :dadosPedido="pedidos_info"
        :nomeComercio="nomeComercio"
      ></Cupom>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import ReceivedOrderCard from "../components/ReceivedOrderCard.vue";
import Spinner from "../components/Spinner.vue";
import Cupom from "./Cupom.vue";
import Message from "./Message.vue";

export default {
  name: "Pedidos",
  components: {
    Message,
    ReceivedOrderCard,
    Spinner,
    Cupom,
  },
  props: {
    tipoPedido: String,
    token: String,
  },
  data() {
    return {
      nomeComercio: localStorage.getItem("nome"),
      rotaComercio: localStorage.getItem("nomeComercio"),
      url: "https://api-delivery.fly.dev/api",
      pedidos: [],
      pedidos_info: [],
      showModal: false,
      showSpinner: false,
      dataPedido: null,
    };
  },
  methods: {
    aceitar(data) {
      this.showSpinner = true;
      const i = data.id;
      console.log(i);

      if (data.imprimir) {
        this.printerPedido(i);
      }
      const status = new FormData();
      status.append("status", 2);
      axios
        .post(`${this.url}/adm/atualiza/pedido/${i}`, status, {
          headers: {
            Authorization: `Bearer${this.token}`,
          },
        })
        .then(() => {
          this.search_pedidos(0);
        })
        .catch((err) => {
          console.log(err);
          this.showSpinner = false;
        })
        .finally(() => {
          this.showSpinner = false;
        });
    },
    finalizar(i) {
      this.showSpinner = true;
      const status = new FormData();
      status.append("status", 3);
      axios
        .post(`${this.url}/adm/atualiza/pedido/${i}`, status, {
          headers: {
            Authorization: `Bearer${this.token}`,
          },
        })
        .then(() => {
          this.message("Pedido aceito com sucesso!", true);
          this.search_pedidos(0);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.showSpinner = false;
        });
    },
    recusar(i) {
      this.showSpinner = true;
      console.log("recusar" + i);
      const status = new FormData();
      status.append("status", 4);
      axios
        .post(`${this.url}/adm/atualiza/pedido/${i}`, status, {
          headers: {
            Authorization: `Bearer${this.token}`,
          },
        })
        .then(() => {
          this.message("Pedido recusado com sucesso!", true);
          this.search_pedidos(0);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.showSpinner = false;
        });
    },
    search_pedidos() {
      axios
        .post(`${this.url}/adm/loja/${localStorage.getItem("id")}/pedidos/0`, "", {
          headers: {
            Authorization: `Bearer${this.token}`,
          },
        })
        .then((res) => {
          console.log(res.data);
          console.log(this.token);

          this.pedidos = res["data"];
        })
        .catch((err) => {
          console.log(err);
        });
    },
    printerPedido(i) {
      this.showSpinner = true;
      axios
        .post(`${this.url}/adm/pedido/${i}`, "", {
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        })
        .then((ret) => {
          this.dataPedido = ret.data;

          return axios.post(`${this.url}/adm/relatorio/pedido/${i}`, "", {
            headers: {
              Authorization: `Bearer ${this.token}`,
            },
          });
        })
        .then((res) => {
          if (res.data.length > 0) {
            this.pedidos_info = res.data;
          }
          setTimeout(() => {
            console.log([this.pedidos_info, this.dataPedido]);
            this.imprimir_notinha();
          }, 500);
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.showSpinner = false;
        });
    },
    imprimir_notinha() {
      var conteudo = document.getElementById("cupom").innerHTML;
      try {
        var iframe = document.createElement("iframe");
        document.body.appendChild(iframe);
        iframe.contentDocument.open();
        iframe.contentDocument.write(conteudo);
        iframe.contentDocument.close();
        iframe.contentWindow.print();
        document.body.removeChild(iframe);
      } catch (e) {
        console.error("Erro ao imprimir:", e);
      }
    },
    loop() {
      console.log(this.pedidos);
      setInterval(() => {
        // this.search_pedidos(0)
        //console.log(JSON.parse(localStorage.getItem('pedidos-bell')))
        this.pedidos = JSON.parse(localStorage.getItem("pedidos-bell"));
      }, 2000);
    },
    message(m, r) {
      this.msg = m;
      this.success = r;
      this.showModal = true;
      setTimeout(() => {
        this.showModal = false;
      }, 2000);
    },
  },
  mounted() {
    console.log(JSON.parse(localStorage.getItem("pedidos-bell")));
    this.search_pedidos();
    this.loop();
  },
};
</script>
