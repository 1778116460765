<template>
  <div>
    <Message :msg="msg" :res="success" v-if="showModal"> </Message>
    <Spinner v-if="showSpinner" :text="''"></Spinner>
    <div>
      <div class="row mb-3">
        <label for="fullName" class="col-md-4 col-lg-3 col-form-label"
          >Escolha as opções</label
        >
        <div class="col-md-8 col-lg-9" style="text-align: left">
          <div class="form-check" style="display: none">
            <input class="form-check-input" type="checkbox" id="changesMade" checked />
            <label class="form-check-label" for="changesMade">
              Direcionar cliente para notificar o pedido no WhatsApp
            </label>
          </div>
          <div class="form-check">
            <input
              class="form-check-input pointer"
              type="checkbox"
              v-on:click="atualiza_atendimento"
              v-bind:checked="deliveryAtendimento"
            />
            <label class="form-check-label" for="newProducts">
              Atendimento apenas Delivery
            </label>
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <label for="activePoints" class="col-md-4 col-lg-3 col-form-label"
          >Fidelidade</label
        >
        <div class="col col-sm-12 col-md-5 col-lg-4" style="text-align: left">
          <select
            class="form-select pointer"
            v-model="infoConfig.sistema_fidelidade"
            id="activePoints"
            @change="changeFidelity(infoConfig.sistema_fidelidade)"
          >
            <option disabled value="">Selecione a fidelidade</option>
            <option value="0">Desativado</option>
            <option value="1">Ativado</option>
          </select>
        </div>
      </div>
      <div class="row mb-3">
        <label for="fullName" class="col-md-4 col-lg-3 col-form-label"
          >Formas de pagamento</label
        >
        <div class="col" style="text-align: left">
          <select
            class="form-select pointer"
            v-model="selectedOption"
            @change="changeOptionsPayment(selectedOption)"
          >
            <option disabled value="">Selecione um método de pagamento</option>
            <option disabled value="1">Pix, Pagamento Online</option>
            <option value="2">Pix, Cartão, Dinheiro</option>
          </select>
        </div>
        <div class="col" style="text-align: left">
          <div class="form-check">
            <input
              class="form-check-input pointer"
              type="checkbox"
              v-on:click="atualiza_Meios_Pagamentos('pix')"
              v-bind:checked="pix_mode"
            />
            <label class="form-check-label" for="changesMade"> Pix </label>
          </div>
          <div class="form-check" v-if="secondOptionPayment">
            <input
              class="form-check-input pointer"
              type="checkbox"
              v-on:click="atualiza_Meios_Pagamentos('dinheiro')"
              v-bind:checked="dinheiro_mode"
            />
            <label class="form-check-label" for="changesMade"> Dinheiro </label>
          </div>
          <div class="form-check" v-if="secondOptionPayment">
            <input
              class="form-check-input pointer"
              type="checkbox"
              v-on:click="atualiza_Meios_Pagamentos('cartao')"
              v-bind:checked="card_mode"
            />
            <label class="form-check-label" for="changesMade"> Cartão </label>
          </div>
          <div class="form-check" v-if="firstOptionPayment">
            <input
              class="form-check-input pointer"
              type="checkbox"
              v-on:click="changeAcceptOnlinePayment()"
              v-bind:checked="mercadoPago_mode"
            />
            <label class="form-check-label" for="changesMade"> Pagamento Online </label>
          </div>
        </div>
      </div>
      <div class="row mb-3 align-items-center">
        <div class="row col-md-12 col-lg-12 align-items-center">
          <label
            for="fullName"
            class="col-md-4 col-lg-3 col-form-label d-flex align-center"
            >Como é cobrado o frete?</label
          >
          <div class="d-flex align-center col-md-4 col-lg-5 col-sm-12">
            <select
              class="form-select pointer"
              v-model="mode_km"
              @change="editTypeDeliveryLocation()"
            >
              <option value="1">Por Bairro</option>
              <option value="2">Por KM</option>
            </select>
          </div>

          <button
            data-bs-toggle="modal"
            data-bs-target="#FreteModal"
            class="btn btn-secondary m-2"
            style="width: 10%; float: right"
          >
            <i class="bi bi-gear"></i>
          </button>
        </div>
      </div>
      <div class="row mb-3">
        <div class="row mb-3" v-for="(item, index) in time" :key="index">
          <label for="Linkedin" class="col-md-4 col-lg-3 col-form-label">{{
            item.dia
          }}</label>
          <div class="col-md-8 col-lg-9 d-flex align-items-center">
            <input
              type="time"
              class="form-control col-3 col-lg-3 pointer"
              style="margin: 1%; width: 40%"
              id="Linkedin"
              v-model="item.inicio"
            />
            ás
            <input
              type="time"
              class="form-control col-3 col-lg-3 pointer"
              style="margin: 1%; width: 40%"
              id="Linkedin"
              v-model="item.fim"
            />
          </div>
        </div>
      </div>

      <div class="text-center">
        <button @click="atualiza_horarios" class="button">Salvar horários</button>
      </div>
    </div>

    <div class="modal fade" ref="modal" id="FreteModal" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Configurações</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              id="closeModal"
            ></button>
          </div>
          <div class="modal-body">
            <div v-if="mode_km == 2">
              <h4 class="subtitle">Configurar por KM</h4>
              <label for="validationDefault01" style="float: left" class="form-label"
                >Valor de saída</label
              >

              <input
                class="form-control col-lg-12 col-12 mb-2"
                v-model="valorFreteSaida"
                type="text"
                placeholder="Valor de saída"
                v-money="configVmoney"
              />

              <label for="validationDefault01" style="float: left" class="form-label"
                >Valor por KM</label
              >
              <input
                class="form-control col-lg-12 col-12 mb-2"
                v-model="valorKM"
                placeholder="valor por KM"
                v-money="configVmoney"
              />
              <label for="validationDefault01" style="float: left" class="form-label"
                >Distância máxima de entrega em KM</label
              >
              <input
                class="form-control col-lg-12 col-12 mb-2"
                v-model="maxDistancia"
                type="number"
                placeholder="Distância máxima de entrega"
              />
            </div>
            <div v-if="mode_km != 2">
              <h4 class="subtitle">Configurar Bairros</h4>
              <label for="validationDefault01" style="float: left" class="form-label"
                >CEP</label
              >
              <input
                class="form-control col-lg-12 col-12 mb-2 d-none"
                type="text"
                v-model="bairroCep"
                placeholder="CEP"
              />
              <input
                class="form-control col-lg-12 col-12 mb-2"
                type="text"
                v-model="bairroCep"
                placeholder="CEP"
                maxlength="8"
              />
              <label for="validationDefault01" style="float: left" class="form-label"
                >Nome do bairro</label
              >
              <input
                class="form-control col-lg-12 col-12 mb-2"
                type="text"
                v-model="bairroNome"
                placeholder="Nome do Bairro"
              />
              <label for="validationDefault01" style="float: left" class="form-label"
                >Valor do frete</label
              >
              <input
                class="form-control col-lg-12 col-12 mb-2"
                v-model="bairroValor"
                placeholder="Valor frete"
                v-money="configVmoney"
              />

              <button type="button" class="button" @click="atualiza_frete(0)">
                Salvar
              </button>
              <div>
                <hr />
                <p>Bairros cadastrados</p>
                <div style="overflow-y: scroll; height: 180px">
                  <ol class="list-group list-group-numbered">
                    <li
                      v-for="(i, id) in listaBairros"
                      :key="id"
                      class="list-group-item d-flex justify-content-between align-items-start"
                    >
                      <div class="ms-2 me-auto">
                        <div class="fw-bold">
                          {{ i.nome_bairro }} R${{ parseFloat(i.valor).toFixed(2) }}
                        </div>
                      </div>
                      <span
                        class="badge bg-danger rounded-pill"
                        style="cursor: pointer"
                        @click="excluir_bairro(i.id)"
                        ><i class="bi bi-trash"></i
                      ></span>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              v-if="mode_km == 2"
              type="button"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="atualiza_frete(1)"
              class="button"
            >
              Salvar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Message from "../pages/Message.vue";
import Spinner from "./Spinner.vue";

export default {
  name: "ConfigProfileSettings",
  components: {
    Message,
    Spinner,
  },
  props: {},
  data() {
    return {
      infoConfig: {},
      insta: "",
      face: "",
      mode_km: "",
      listaBairros: [],
      deliveryAtendimento: false,
      pix_mode: false,
      dinheiro_mode: false,
      card_mode: false,
      onlinePayment: 0,
      mercadoPago_mode: false,
      time: [],
      url: "https://api-delivery.fly.dev/api",
      valorFreteSaida: 0,
      valorKM: 0,
      maxDistancia: 0,
      valorPorKM: 0,
      bairroNome: "",
      bairroCep: "",
      bairroValor: "",
      searchPaymentType: null,
      firstOptionPayment: false,
      secondOptionPayment: true,
      selectedOption: "",
      configFidelity: "",
      configVmoney: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
      },
      showSpinner: false,
      showModal: false,
      msg: "",
      success: null,
    };
  },
  methods: {
    overviewData() {
      return new Promise((resolve, reject) => {
     let name = localStorage.getItem("nomeComercio")
      axios
        .post(`${this.url}/adm/${name.replace(/\s/g, '')}`)
          .then((ret) => {
            localStorage.setItem("id", ret.data.id);
            this.infoConfig = ret.data;
            console.log(this.infoConfig);
            resolve();
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },
    async dados_info() {
      axios
        .post(`${this.url}/adm/redes_sociais/${localStorage.getItem("id")}`, "", {
          headers: {
            Authorization: `Bearer${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          this.insta = res["data"][0].instagram;
          this.face = res["data"][0].facebook;
          axios
            .post(`${this.url}/adm/horarios/${this.infoConfig.id}`, "", {
              headers: {
                Authorization: `Bearer${localStorage.getItem("token")}`,
              },
            })
            .then((res) => {
              this.time = res["data"];
              axios
                .post(`${this.url}/adm/meios_pagamento/${this.infoConfig.id}`, "", {
                  headers: {
                    Authorization: `Bearer${localStorage.getItem("token")}`,
                  },
                })
                .then((res) => {
                  this.searchPaymentType = res.data;
                  let r = res["data"][0];
                  this.card_mode = parseInt(r.cartao);
                  this.dinheiro_mode = parseInt(r.dinheiro);
                  this.pix_mode = parseInt(r.pix);
                  // this.selectedOption = parseInt(r.mercado_pago);
                  this.selectedOption = "2";
                  this.deliveryAtendimento = this.infoConfig.atende_local;
                  this.mode_km = this.infoConfig.tipo_frete;
                  this.valorKM = this.infoConfig.valor_KM * 100;
                  this.valorFreteSaida = this.infoConfig.valor_saida * 100;
                  this.maxDistancia = this.infoConfig.maximo_KM;
                })
                .catch((err) => {
                  console.log(err);
                });
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });

      axios
        .post(`${this.url}/adm/bairros/${localStorage.getItem("id")}`, "", {
          headers: {
            Authorization: `Bearer${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          this.listaBairros = res["data"];
        })
        .catch((err) => {
          console.log(err);
        });
    },

    atualiza_atendimento() {
      const info = new FormData();
      if (this.deliveryAtendimento == 0) {
        this.deliveryAtendimento = 1;
        info.append("atende_local", 1);
        axios
          .post(`${this.url}/comercio/${this.infoConfig.id}/atualiza`, info, {
            headers: {
              Authorization: `Bearer${localStorage.getItem("token")}`,
            },
          })
          .then(() => {})
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.deliveryAtendimento = 0;
        info.append("atende_local", 0);
        axios
          .post(`${this.url}/comercio/${this.infoConfig.id}/atualiza`, info, {
            headers: {
              Authorization: `Bearer${localStorage.getItem("token")}`,
            },
          })
          .then(() => {})
          .catch((err) => {
            console.log(err);
          });
      }
    },

    changeOptionsPayment(option) {
      if (option === "1") {
        this.firstOptionPayment = true;
        this.secondOptionPayment = false;
        this.atualiza_Meios_Pagamentos("cartao", false);
        this.atualiza_Meios_Pagamentos("dinheiro", false);
      } else {
        this.firstOptionPayment = false;
        this.secondOptionPayment = true;
        this.changeAcceptOnlinePayment(false);
      }
      console.log(option);
      console.log(this.firstOptionPayment, this.secondOptionPayment);
    },

    atualiza_Meios_Pagamentos(i, deactivate = true) {
      const pagamento = new FormData();
      if (i == "pix") {
        if (this.pix_mode == 0) {
          this.pix_mode = 1;
        } else {
          this.pix_mode = 0;
        }
        pagamento.append(i, this.pix_mode);
      }
      if (i == "dinheiro") {
        if (this.dinheiro_mode == 0) {
          this.dinheiro_mode = 1;
        } else {
          this.dinheiro_mode = 0;
        }
        if (!deactivate) this.dinheiro_mode = 0;
        pagamento.append(i, this.dinheiro_mode);
      }
      if (i == "cartao") {
        if (this.card_mode == 0) {
          this.card_mode = 1;
        } else {
          this.card_mode = 0;
        }
        if (!deactivate) this.card_mode = 0;
        pagamento.append(i, this.card_mode);
      }
      axios
        .post(
          `${this.url}/adm/atualizar/meios_pg/${this.searchPaymentType[0].id}`,
          pagamento,
          {
            headers: {
              Authorization: `Bearer${localStorage.getItem("token")}`,
            },
          }
        )
        .then(() => {})
        .catch((err) => {
          console.log(err);
        });
    },

    changeFidelity(fidelity) {
      console.log(fidelity);
      const info = { novo_valor: fidelity };
      axios
        .post(`${this.url}/adm/atualizar/fidelidade/${this.infoConfig.id}`, info, {
          headers: {
            Authorization: `Bearer${localStorage.getItem("token")}`,
          },
        })
        .then(() => {
          // this.verifyAcceptOnlinePayment();
          // this.dados_info();
        })
        .catch((err) => {
          console.log(err);
          this.changeFidelity(fidelity);
        });
    },
    changeAcceptOnlinePayment(deactivate = true) {
      const info = { online_payment: this.onlinePayment == 1 ? 0 : 1 };
      // this.mercadoPago_mode = this.onlinePayment == 1 ? true : false;
      if (!deactivate) info.online_payment = 0;
      console.log(info);
      axios
        .post(`${this.url}/adm/update/payment/info/${this.infoConfig.id}`, info, {
          headers: {
            Authorization: `Bearer${localStorage.getItem("token")}`,
          },
        })
        .then(() => {
          this.verifyAcceptOnlinePayment();
          this.dados_info();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    atualiza_horarios() {
      this.showSpinner = true;
      const requests = [];

      for (let i = 0; i < this.time.length; i++) {
        const tempo = new FormData();
        tempo.append("dia", this.time[i].dia);
        tempo.append("inicio", this.time[i].inicio);
        tempo.append("fim", this.time[i].fim);
        tempo.append("id_comercio", localStorage.getItem("id"));

        const request = axios.post(
          `${this.url}/adm/atualizar/horario/${this.time[i].id}`,
          tempo,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        requests.push(request);
      }
      Promise.all(requests)
        .then(() => {
          this.modalMessage("Atualizado com sucesso!", true);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.showSpinner = false;
        });
    },

    async verifyAcceptOnlinePayment() {
      axios
        .post(`${this.url}/mp/list/empresas/${localStorage.getItem("id")}`, "", {
          headers: {
            Authorization: `Bearer${localStorage.getItem("token")}`,
          },
        })
        .then((resp) => {
          // this.onlinePayment = resp.data.online_payment;
          this.onlinePayment = 2;
          this.mercadoPago_mode = this.onlinePayment == 1 ? true : false;
          this.selectedOption = this.onlinePayment == 1 ? 1 : 2;
          this.firstOptionPayment = this.onlinePayment == 1;
          this.secondOptionPayment = this.onlinePayment != 1;

          console.log(this.onlinePayment);
          console.log(this.mercadoPago_mode);
          console.log(resp.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    formatMoneyClear(valor) {
      const valorNumerico = valor.toString().replace(/[^0-9,.]/g, "");
      const numero = parseFloat(valorNumerico.replace(",", "."));
      console.log(numero);
      return numero;
    },

    atualiza_frete(i) {
      console.log(i);
      console.log(this.mode_km);

      if (i == 1) {
        const km = new FormData();
        const valorPorKM = this.formatMoneyClear(this.valorPorKM);
        const valor_saida = this.formatMoneyClear(this.valorFreteSaida);
        const valorKM = this.formatMoneyClear(this.valorKM);

        km.append("valor_frete", valorPorKM);
        km.append("valor_saida", valor_saida);
        km.append("valor_KM", valorKM);
        km.append("maximo_KM", this.maxDistancia);
        km.append("tipo_frete", this.mode_km);

        axios
          .post(`${this.url}/comercio/${localStorage.getItem("id")}/atualiza`, km, {
            headers: {
              Authorization: `Bearer ${this.token}`,
            },
          })
          .then((res) => {
            console.log(res.data);
            this.modalMessage("Atualizado com sucesso", true);
            const closeModal = document.getElementById("closeModal");
            closeModal.click();
          })
          .catch((err) => {
            this.modalMessage("Houve um erro ao atualizar", false);
            console.log(err);
          });
      } else {
        const tipo = new FormData();
        tipo.append("tipo_frete", this.mode_km);

        axios
          .post(`${this.url}/comercio/${localStorage.getItem("id")}/atualiza`, tipo, {
            headers: {
              Authorization: `Bearer ${this.token}`,
            },
          })
          .then(() => {
            const bairro = new FormData();
            bairro.append("nome_bairro", this.bairroNome);
            bairro.append("cep", this.bairroCep);
            bairro.append("id_empresa", localStorage.getItem("id"));
            bairro.append("valor", this.formatMoneyClear(this.bairroValor));

            axios
              .post(`${this.url}/adm/novo/bairro/${localStorage.getItem("id")}`, bairro, {
                headers: {
                  Authorization: `Bearer ${this.token}`,
                },
              })
              .then(() => {
                this.modalMessage("Atualizado com sucesso", true);
                this.dados_bairros();
                const closeModal = document.getElementById("closeModal");
                closeModal.click();
              })
              .catch((err) => {
                this.modalMessage("Houve um erro ao atualizar", false);
                console.log(err);
              });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },

    editTypeDeliveryLocation() {
      const data = new FormData();
      data.append("tipo_frete", this.mode_km);
      axios
        .post(`${this.url}/comercio/${localStorage.getItem("id")}/atualiza`, data, {
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        })
        .then((res) => {
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    dados_bairros() {
      axios
        .post(`${this.url}/adm/bairros/${localStorage.getItem("id")}`, "", {
          headers: {
            Authorization: `Bearer${this.token}`,
          },
        })
        .then((res) => {
          this.listaBairros = res["data"];
        })
        .catch((err) => {
          console.log(err);
        });
    },
    excluir_bairro(i) {
      axios
        .post(`${this.url}/adm/delete/bairro/${i}`, "", {
          headers: {
            Authorization: `Bearer${this.token}`,
          },
        })
        .then(() => {
          this.dados_bairros();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    modalMessage(m, r) {
      this.msg = m;
      this.success = r;
      this.showModal = true;
      setTimeout(() => {
        this.showModal = false;
      }, 2000);
    },
  },
  async mounted() {
    this.showSpinner = true;
    try {
      await this.overviewData();
      await Promise.all([this.dados_info(), this.verifyAcceptOnlinePayment()]);
      setTimeout(() => {
        this.showSpinner = false;
      }, 2000);
    } catch (error) {
      console.error(error);
    }
  },
};
</script>
<style scoped>
select {
  height: 35px;
}

.row {
  align-items: center;
}
</style>
