<template>
  <div class="fullScreen">
    <div class="modal-personalize">
      <div class="title">{{ titleModal }}</div>

      <div class="buttons-personalize">
        <button class="button-outline mx-1" @click="firstButton">
          {{ buttonTexts[0] }}
        </button>
        <button class="button mx-1" @click="secondButton">
          {{ buttonTexts[1] }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalPersonalized",
  props: {
    buttonTexts: {
      type: Array,
      required: true,
    },
    titleModal: {
      type: String,
      required: true,
    },
    idModal: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    firstButton() {
      this.$emit("response", [false, this.idModal]);
    },
    secondButton() {
      this.$emit("response", [true, this.idModal]);
    },
  },
  computed: {
    // Propriedades calculadas do componente
  },
};
</script>

<style scoped>
div {
  padding: 0;
  margin: 0;
}
.fullScreen {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
  z-index: 997;
}
.modal-personalize {
  width: 319px;
  height: 183px;
  background: #f7f7f7;
  border: 1.5px solid #fe2929;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  position: relative;
  margin: 0 auto;
  padding: 33px 20px 20px 20px;
  z-index: 999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.modal-personalize .title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.25px;
  color: #14142b;
}

.modal-mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 998;
}
</style>
