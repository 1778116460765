<template>
  <div>
    <Message :msg="msg" :res="success" v-if="showModal"> </Message>
    <Spinner v-if="showSpinner" :text="''"></Spinner>
    <div class="d-flex justify-content-start mb-4">
      <button
        class="button"
        @click="userCreated = false"
        data-bs-toggle="modal"
        data-bs-target="#newCollaborator"
      >
        Novo Colaborador
      </button>
    </div>
    <div class="filter mb-4">
      <label for="find_collaborators">Buscar Colaborador</label>
      <div class="col-12 col-lg-3 col-md-4 col-sm-12">
        <input
          @input="findClients($event)"
          class="form-control"
          placeholder="Pesquisar"
          id="find_collaborators"
        />
      </div>
    </div>
    <div class="custom-table" v-if="newListCollaborators.length > 0">
      <b-table
        id="my-table"
        :items="newListCollaborators"
        :fields="fields"
        label-sort-asc=""
        label-sort-desc=""
        label-sort-clear=""
        :per-page="perPage"
        :current-page="currentPage"
      >
        <!-- eslint-disable-next-line vue/no-unused-vars -->
        <template #cell(created_at)="row">
          {{ formatDate(row.item.created_at) }}
        </template>
        <template #cell(nivel_acesso)="row">
          {{ row.item.nivel_acesso == "1" ? "Administrador" : "Operacional" }}
        </template>
        <!-- <template #cell(phone_number)="row">
                        {{ formatPhone(row.item.phone_number) }}
                      </template> -->
      </b-table>
      <div class="custom-pagination">
        <h4>Mostrando 1 até 10 de 134 registros</h4>
        <b-pagination
          v-model="currentPage"
          :total-rows="newListCollaborators.length"
          :per-page="perPage"
          aria-controls="my-table"
        ></b-pagination>
      </div>
    </div>
    <div v-else>Nenhum Colaborador cadastrado</div>

    <div
      class="modal fade newCollaborator"
      ref="newCollaborator"
      id="newCollaborator"
      tabindex="-1"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Cadastrar novo colaborador</h5>
            <button
              type="button"
              class="btn-close"
              id="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div>
              <label for="name" style="float: left" class="">Nome colaborador</label>
              <input
                class="form-control col-lg-12 col-12 mb-2"
                type="text"
                v-model="newCollaboratorRegister.nome_colaborador"
                placeholder="Ex : João Silva"
                id="name"
              />
              <div
                v-if="showErros && newCollaboratorRegister.nome_colaborador.length < 3"
                class="error-message"
              >
                Campo inválido
              </div>

              <label for="credential" style="float: left" class="form-label"
                >Credencial</label
              >
              <input
                class="form-control col-lg-12 col-12 mb-2"
                type="text"
                v-model="newCollaboratorRegister.credencial"
                placeholder="Ex : joao1"
                id="credential"
              />
              <div
                v-if="showErros && newCollaboratorRegister.credencial.length < 3"
                class="error-message"
              >
                Campo inválido
              </div>

              <label for="contact" style="float: left" class="form-label">Contato</label>
              <input
                class="form-control col-lg-12 col-12 mb-2"
                type="text"
                v-model="newCollaboratorRegister.contato"
                placeholder="Ex: (99) 9 9999-9999 "
                id="contact"
                v-mask="'(##) # ####-####'"
              />
              <div
                v-if="showErros && newCollaboratorRegister.contato.length < 16"
                class="error-message"
              >
                Campo inválido
              </div>

              <label for="password" style="float: left" class="form-label">Senha</label>
              <input
                class="form-control col-lg-12 col-12 mb-2"
                type="password"
                v-model="newCollaboratorRegister.senha"
                placeholder="125789"
                id="password"
                maxlength="8"
                autocomplete="off"
              />
              <div
                v-if="showErros && newCollaboratorRegister.senha.length < 6"
                class="error-message"
              >
                Campo inválido
              </div>

              <label for="accessLevel" style="float: left" class="form-label"
                >Nível de acesso</label
              >

              <select
                v-model="newCollaboratorRegister.nivel"
                class="form-control col-lg-12 col-12 mb-2"
                name="accessLevel"
                id="accessLevel"
              >
                <option value="">Selecione um nível</option>
                <option value="1">ADM</option>
                <option value="2">Operacional</option>
              </select>
              <div
                v-if="showErros && newCollaboratorRegister.nivel == ''"
                class="error-message"
              >
                Campo inválido
              </div>
            </div>
          </div>
          <div class="modal-footer d-flex justify-content-center">
            <button
              type="button"
              data-bs-dismiss="modal"
              aria-label="Close"
              class="button-outline"
              :disabled="creatingUser"
              v-if="!userCreated"
            >
              Cancelar
            </button>
            <button
              v-if="!userCreated"
              :disabled="creatingUser"
              type="button"
              class="button"
              @click="salvar_colaborador()"
            >
              Salvar
            </button>
            <button
              data-bs-dismiss="modal"
              aria-label="Close"
              v-if="userCreated"
              type="button"
              class="button"
            >
              Fechar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { BPagination, BTable } from "bootstrap-vue";
import Spinner from "../components/Spinner.vue";
import Message from "../pages/Message.vue";
export default {
  name: "ConfigCollaborator",
  components: {
    BTable,
    BPagination,
    Message,
    Spinner,
  },
  props: {},
  data() {
    return {
      url: "https://api-delivery.fly.dev/api",
      perPage: 10,
      currentPage: 9,
      showModal: false,
      userCreated: false,
      creatingUser: false,
      showSpinner: false,
      newCollaboratorRegister: {
        nome_colaborador: "",
        credencial: "",
        contato: "",
        senha: "",
        nivel: "",
      },
      showErros: false,
      list_collaborators: [],
      newListCollaborators: [],
      fields: [
        {
          label: "Nome",
          key: "nome_colaborador",
          sortable: true,
        },
        {
          label: "Data de Início",
          key: "created_at",
          sortable: true,
        },
        {
          label: "Nível",
          key: "nivel_acesso",
          sortable: true,
        },
        {
          label: "Contato",
          key: "email",
          sortable: false,
        },
      ],
    };
  },
  methods: {
    listaColaboradores() {
      axios
        .post(
          `${this.url}/adm/colabs/${localStorage.getItem("id")}`,
          {},
          {
            headers: {
              Authorization: `Bearer${localStorage.getItem("token")}`,
            },
          }
        )
        .then((res) => {
          this.list_collaborators = res["data"];
          this.newListCollaborators = res["data"];
          console.log(this.list_collaborators);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    formatDate(data) {
      const dataObj = new Date(data);
      const dia = dataObj.getDate().toString().padStart(2, "0");
      const mes = (dataObj.getMonth() + 1).toString().padStart(2, "0");
      const ano = dataObj.getFullYear().toString();
      // const hora = dataObj.getHours().toString().padStart(2, "0");
      // const minuto = dataObj.getMinutes().toString().padStart(2, "0");
      // const segundo = dataObj.getSeconds().toString().padStart(2, "0");
      // return `${dia}/${mes}/${ano} ${hora}:${minuto}:${segundo}`;
      return `${dia}/${mes}/${ano}`;
    },

    formatPhone(numero) {
      const ddd = numero.substring(0, 2);
      const primeiraParte = numero.substring(2, 7);
      const segundaParte = numero.substring(7, 11);
      return `(${ddd}) 9 ${primeiraParte}-${segundaParte}`;
    },

    salvar_colaborador() {
      console.log(this.newCollaboratorRegister.contato);
      if (
        this.newCollaboratorRegister.nome_colaborador.length < 3 ||
        this.newCollaboratorRegister.credencial.length < 3 ||
        this.newCollaboratorRegister.contato.length < 16 ||
        this.newCollaboratorRegister.senha.length < 6 ||
        !this.newCollaboratorRegister.nivel
      ) {
        this.showErros = true;
        return;
      }
      this.showErros = false;
      this.creatingUser = true;

      this.newCollaboratorRegister.contato = this.newCollaboratorRegister.contato.replace(
        /\D/g,
        ""
      );
      this.newCollaboratorRegister.id_empresa = localStorage.getItem("id");

      this.showSpinner = true;
      axios
        .post(`${this.url}/adm/novo/colab`, this.newCollaboratorRegister, {
          headers: {
            Authorization: `Bearer${localStorage.getItem("token")}`,
          },
        })
        .then(() => {
          const closeButton = document.getElementById("btn-close");
          closeButton.click();
          this.modalMessage("Cadastrado com sucesso!", true);
          this.listaColaboradores();
          this.creatingUser = false;
          this.userCreated = true;
          this.newCollaboratorRegister = {
            nome_colaborador: "",
            credencial: "",
            contato: "",
            senha: "",
            nivel: "",
          };
          this.showSpinner = false;
        })
        .catch((err) => {
          console.log(err);
          this.modalMessage("Erro ao cadastrar!", false);
        });
    },

    deleteColab(i) {
      axios
        .post(
          `${this.url}/adm/deletar/colab/${i}`,
          {},
          {
            headers: {
              Authorization: `Bearer${localStorage.getItem("token")}`,
            },
          }
        )
        .then(() => {
          this.modalMessage("Deletado com sucesso!", true);
          this.listaColaboradores();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    modalMessage(m, r) {
      this.msg = m;
      this.success = r;
      this.showModal = true;
      setTimeout(() => {
        this.showModal = false;
      }, 2000);
    },

    findClients(event) {
      const value = event.target.value;
      let newArr = [];
      if (value !== "") {
        this.list_collaborators.forEach((element) => {
          if (element.nome_colaborador.toLowerCase().includes(value.toLowerCase())) {
            newArr.push(element);
          }
        });
        this.newListCollaborators = newArr;
      } else {
        this.newListCollaborators = this.list_collaborators;
      }
    },
  },
  mounted() {
    this.listaColaboradores();
  },
};
</script>
<style>
.error-message {
  color: red;
}
.modal-title {
  color: var(--grayscale-off-black, #14142b);
  font-size: 20px;
  font-family: Poppins;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0.25px;
}

.newCollaborator button {
  width: 82px;
}

.newCollaborator label {
  color: var(--grayscale-body, #4e4b66);
  font-size: 13px;
  font-family: Poppins;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.25px;
}
.newCollaborator .form-control {
  padding: 11px 15px 15.162px 17px;
  justify-content: center;
  align-items: center;
  gap: 259px;
  border-radius: 4px;
  border: 1px solid var(--grayscale-body, #4e4b66);
  background: var(--grayscale-off-white, #fcfcfc);
}
</style>
