<template>
  <div>
    <div class="card" :style="{ 'background-image': 'url(' + image + ')' }">
      <div class="order-header d-flex justify-content-between">
        <div class="order-title">{{ segment.nome_segmento }}</div>
        <i 
          v-on:click="seeSegment(segment.id, segment.nome_segmento)"
          class="bi bi-eye h5"
          style="cursor: pointer;background-color: black;border-radius:50%;padding:2%"
        ></i>
      </div>

      <div class="order-buttons">
        <button
          class="button-outline mx-1"
          v-on:click="confirmationDeleteSegment(segment.id)"
        >
          EXCLUIR
        </button>
        <button class="button mx-1" v-on:click="editSegment(segment.id)">EDITAR</button>
      </div>
    </div>
    <ModalPersonalized
      v-if="showModal"
      @response="handleModalResponse"
      :buttonTexts="buttonsNameModal"
      :titleModal="titleModal"
      :idModal="idModal"
    ></ModalPersonalized>
  </div>
</template>
<script>
import ModalPersonalized from "./ModalPersonalized.vue";
export default {
  name: "SegmentsProducts",
  components: {
    ModalPersonalized,
  },
  props: {
    segment: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      image: "",
      showModal: false,
      buttonsNameModal: [],
      titleModal: "",
      idModal: "",
    };
  },
  methods: {
    confirmationDeleteSegment(id) {
      this.showModal = true;
      this.buttonsNameModal = ["CANCELAR", "EXCLUIR"];
      this.titleModal = "Deseja excluir o segmento " + this.segment.nome_segmento + " ?";
      this.idModal = id;
    },

    handleModalResponse(response) {
      if (this.showModal) {
        this.showModal = false;
        if (response[0]) {
          this.deleteSegment(response[1]);
        }
      }
    },
    deleteSegment(response) {
      this.$emit("deleteSegment", response);
    },
    editSegment(response) {
      this.$emit("editSegment", response);
    },
    seeSegment(id, segment_name) {
      this.$emit("seeSegment", id, segment_name);
    },
  },
  computed: {
    // Propriedades calculadas do componente
  },
  watch: {
    segment: {
      handler(newSegment) {
        this.image = `https://edrafox.com.br/assets/edralivery-img/${newSegment.imagem_segmento}`;
      },
      immediate: true 
    }
  },
  mounted() {
    console.log(this.segment);
    this.image = `https://edrafox.com.br/assets/edralivery-img/${this.segment.imagem_segmento}`;
  },
};
</script>

<style scoped>
div {
  padding: 0 10px;
  margin: 0;
}
.card {
  padding: 32px 10px 13px 10px;
  background: #ffffff;
  border-radius: 20px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 288px;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.order-header {
}
.order-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 28px;
  display: flex;
  align-items: center;
  letter-spacing: 0.25px;
  color: #fcfcff;
  width: 176px;
  height: 30px;
  background: #303030;
  border-radius: 7px;
  padding-left: 10px;
}
.order-header i {
  font-size: 30px;
  color: #f6f6fb;
  display: flex;
  align-items: center;
}
.order-infos {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 22px;
  letter-spacing: 0.25px;
  color: #14142b;
  text-align: left;
  margin: 20px 0;
}

.order-itens {
  margin-bottom: 20px;
}
.order-itens table {
  width: 100%;
  margin-top: 7px;
}
.order-buttons {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.25px;
  color: #fcfcfc;
  padding: 10px 0;
  background: #d9d9d9;
  border-radius: 14px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

button{
  width: 100px;
}
</style>
